import React, { useState, useEffect } from "react";
import "../styles/Ticker.css";
import axiosInstance from "../config";
import { useHistory } from "react-router-dom";
import lifecycle from "react-pure-lifecycle";
import {logout} from './Utils';

// Lifecycle methods
const methods = {};

const TickerNews = () => {
  const [tickerData, setTickerData] = useState([]);
  const history = useHistory();

  const calculatingTicker = (defaultSecond) => {
    let offset = (15 / defaultSecond) * 100;
    offset = Math.round(offset);

    document.getElementById("tickerNews").style.animationDuration =
      defaultSecond + "s";
    document.getElementById("tickerNews").style.webkitAnimationDuration =
      defaultSecond + "s";
    var stylesheet = document.styleSheets[2],
      rules = stylesheet.rules,
      i = rules.length,
      keyframes,
      keyframe;

    while (i--) {
      keyframes = rules.item(i);
      if (
        (keyframes.type === keyframes.KEYFRAMES_RULE ||
          keyframes.type === keyframes.WEBKIT_KEYFRAMES_RULE) &&
        keyframes.name === "ticker"
      ) {
        rules = keyframes.cssRules;
        i = rules.length;
        while (i--) {
          keyframe = rules.item(i);
          if (
            (keyframe.type === keyframe.KEYFRAME_RULE ||
              keyframe.type === keyframe.WEBKIT_KEYFRAME_RULE) &&
            keyframe.keyText === "0%"
          ) {
            keyframe.style.webkitTransform = keyframe.style.transform =
              "translate3d(" + offset + "%,0,0)";
            break;
          }
        }
        break;
      }
    }
  };

  useEffect(() => {
    let defaultSecond = 20;
    let callMessage = () => {
      const token = localStorage.getItem('token');
      if (token) {
        return axiosInstance
          .get("portal/messages")
          .then((res) => {
            setTickerData(res.data.content);
            var newSecond = 0;
            res.data &&
              res.data.content &&
              res.data.content.length > 0 &&
              res.data.content.forEach((c) => {
                newSecond += c.content.length + 5;
              });
            newSecond = Math.round(newSecond / 10); // Assume 10 char per second scrolling
            defaultSecond = newSecond > defaultSecond ? newSecond : defaultSecond;
            //console.log("defaultSecond", defaultSecond);

            calculatingTicker(defaultSecond);
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.data.status === 401) {
              logout(history);
            }
          });
      }
    };

    callMessage().then(() => {
      const timer = setInterval(() => {
        callMessage();
      }, (defaultSecond > 180? defaultSecond: 180) * 1000);
      return () => clearInterval(timer);
    });
  }, [history]);

  return (
    <div className="Ticker">
      <div className="marquee">
        <div className="ticker-wrap">
          <div className="ticker" id="tickerNews">
            {tickerData.map((t, index) => (
              <span key={index}>
                <a className="ticker__item" href="/messages">
                  {t.content}
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default lifecycle(methods)(TickerNews);

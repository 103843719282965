import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import ViewDetailModal from "../components/ViewDetailModal";
import axiosInstance, { publicAxiosInstance } from "../config";
import { logout } from "../components/Utils";
import "../styles/CorrectScore.css";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  none: {
    display: "none",
  },
  highlight: {
    background: "#ffdf50",
    display: "inline-block",
    padding: "2px",
  },
  link: {
    cursor: "pointer",
    color: "#1452CC",
  },
  timer: {
    fontWeight: "bold",
    color: "#DC252F",
    fontSize: "12px",
    paddingLeft: "4px",
  },
  reject: {
    textDecoration: "line-through",
  },
  score: {
    fontSize: "13px",
    color: "#323030",
    textAlign: "center",
    fontWeight: "bold",
  },
  result: {
    fontSize: "13px",
    color: "#323030",
    textAlign: "center",
  },
  strong: {
    color: "#DC252F",
  },
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  table: {
    width: "100%",
  },
  index: {
    fontSize: "10px",
    color: "#323030",
    textAlign: "center",
    fontWeight: "bold",
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    maxHeight: "18px",
  },
  flex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: "18px",
    width: "18px",
    marginRight: "0 0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    marginLeft: "0.5rem",
    paddingRight: "5px",
  },
  Modal: {
    margin: "0 auto",
    width: "800px",
    backgroundColor: "#fff",
    // overflowY: 'scroll',
    // maxHeight: '80%',
    borderRadius: "4px",
  },
  modalStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blue: {
    color: "#1452CC",
    fontWeight: "bold",
    fontSize: "13px",
  },
  view: {
    backgroundColor: "#fff",
    color: "#86898C",
    lineHeight: "12px",
    fontWeight: "bold",
    fontSize: "12px",
    border: "1px solid #86898C",
    borderRadius: "2px",
    cursor: "pointer",
    padding: "0.25rem",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0 !important",
  },
  running: {
    color: "#E91F1F",
    fontSize: "11px",
    fontWeight: "bold",
    margin: "0.15rem 0 !important",
  },
  teams: {
    fontSize: "13px",
    color: "#323030",
    margin: "0.15rem 0 !important",
  },
  compName: {
    lineHeight: "16px",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#323030",
    backgroundColor: "#DEDEDE",
    borderBottom: "1px solid #86898C",
    borderTop: "1px solid #86898C",
    borderLeft: "1px solid #979d9d",
  },
  spacing: {
    padding: "0.35rem 0.5rem",
  },
  blank: {
    backgroundColor: "#86898C",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px",
  },
  betType: {
    color: "#18334C",
  },
  time: {
    color: "#18334C",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.3rem 0",
  },
  font14: {
    fontSize: "14px",
    fontWeight: "normal",
  },
  titleStyle: {
    fontWeight: "700",
    fontSize: "14px",
    position: "relative",
    padding: "0.1rem !important",
  },
}));
const CorrectScore = ({ User }, props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  const history = useHistory();
  const [seconds, setSeconds] = useState(0);
  const [matches, setMatches] = useState([]);
  const [preventDoubleClick, setPreventDoubleClick] = useState(false);
  const [oddError, setOddError] = useState(false);
  const [oddUpdating, setOddUpdating] = useState(false);
  const [quoteIdTimeout, setQuoteIdTimeout] = useState('');

  useEffect(() => {
    const refreshSecond = User ? 60 : 60;
    let callOddCorrectScore = () => {
      let url = "public/oddCs";
      let axiosIns = User ? axiosInstance : publicAxiosInstance;
      return axiosIns
        .post(url)
        .then((res) => {
          setMatches((matches) => {
            return res.data.map((newMatch) => {
              let oldMatch = matches.find((m) => {
                return m.p === newMatch.p;
              });
              if (oldMatch) {
                newMatch["old"] = oldMatch;
              }
              return newMatch;
            });
          });
        })
        .then(() => {
          setSeconds(refreshSecond);
        })
        .catch((err) => {
          setSeconds(refreshSecond);
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    };

    if (!seconds) {
      callOddCorrectScore();
    }
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const handleOdd = (Id, e) => {
    if (!User) {
      toast.dark("Please login to continue !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if(preventDoubleClick) {
      return;
    }

    setPreventDoubleClick(true);
    

    setQuote(null);
    setOddError(false);
    setOddUpdating(false);
    
    const odd = Id;
    const pick = e.target.id;
    //fetching quote to render left side
    const requestBody = {
      odd: odd,
      pick: pick,
    };
    axiosInstance
      .post("restricted/quote", qs.stringify(requestBody))
      .then((res) => {
        //console.log(res.data);
        setQuote(res.data);
        document.querySelector("#betMoney").focus();
        // setbetSuccess(false);
        if(quoteIdTimeout) {
          clearTimeout(quoteIdTimeout);
        }
        var timeout = setTimeout(() => {
          setQuoteIdTimeout('');
          if (document.querySelector("#quoteId") != null) {
            // only close the popup if quote id is still the same
            if (document.querySelector("#quoteId").value === res.data.quote) {
              setQuote(null);
            }
          }
        }, 120000);
        setQuoteIdTimeout(timeout);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        } else {
          setOddError(true);
          setQuote("");
        }
      })
      .finally(() => {
        setPreventDoubleClick(false);
      })
    
  };

  const getHighlightClass = (match, key, index) => {
    return match && match.old && match.old[key] 
      && match[key] && match[key][index] 
      && match[key][index] !== match.old[key][index] ? classes.highlight : "";
  }

  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} setOddError={setOddError} setOddUpdating={setOddUpdating} oddError={oddError} oddUpdating={oddUpdating} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div
              className={classes.flex}
              style={{ justifyContent: "space-between" }}
            >
              <div className={classes.flex2}>
                <img
                  src={require("../img/Icons/soccer-1.svg")}
                  alt=""
                  className={classes.img}
                />
                <span className={classes.header}>
                  <span className={`lang-${localStorage.getItem("language")}`}>
                    {t('Correct Score')}
                  </span>
                </span>
                <img
                  onClick={() => setSeconds(0)}
                  src={require("../img/Icons/refresh-3.svg")}
                  alt=""
                  width="16px"
                  height="16px"
                  className={`${classes.link}`}
                />
                <span
                  onClick={() => setSeconds(0)}
                  id="timer"
                  className={`${classes.timer} ${classes.link}`}
                >
                  {seconds}
                </span>
              </div>
            </div>

            {/* table for wager */}
            <div className="RightSide">
              <div className="CorrectScore">
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th style={{ borderRadius: "4px 0 0 0" }}>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          Time
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          Event
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          1:0
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          2:0
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          2:1
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          3:0
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          3:1
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          3:2
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          4:0
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          4:1
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          4:2
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          4:3
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          0:0
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          1:1
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          2:2
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          3:3
                        </span>
                      </th>
                      <th>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          4:4
                        </span>
                      </th>

                      <th style={{ borderRadius: "0 4px  0 0" }}>
                        <span
                          className={`lang-${localStorage.getItem("language")}`}
                        >
                          AOS
                        </span>
                      </th>
                    </tr>
                  </thead>
                  
                    {matches && matches.length === 0 ? (
                      <tbody>
                      <tr>
                        <td colSpan="18">
                          <span
                            className={`lang-${localStorage.getItem(
                              "language"
                            )}`}
                          >
                            {t("No matches at the moment")}
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    ) : null}

                    {matches &&
                      matches.map((match, index) => (
                        <React.Fragment key={index}>
                          <tbody className="changeBG">
                          {match.l && match.l != "" ? (
                            <tr className={classes.compName}>
                              <th className={classes.titleStyle} colSpan="18">
                                <span>{match.l}</span>
                              </th>
                            </tr>
                          ) : null}

                          <tr>
                            <td rowSpan="2">
                              <div className={`fontNormal`}>
                                {(match.d && match.d[0]) || ""}
                              </div>
                              <div className="pt5 fontNormal">
                                {(match.d && match.d[1]) || ""}
                              </div>
                            </td>
                            <td rowSpan="2" align="left">
                              <span className="redColor fontNormal">
                                {(match.tm && match.tm[0]) || ""}
                              </span>
                              <div className="pt5 fontNormal">
                                {(match.tm && match.tm[1]) || ""}
                              </div>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_10"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 0)}`}
                              >
                                {(match.o1 && match.o1[0]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_20"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 1)}`}
                              >
                                {(match.o1 && match.o1[1]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_21"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 2)}`}
                              >
                                {(match.o1 && match.o1[2]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_30"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 3)}`}
                              >
                                {(match.o1 && match.o1[3]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_31"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 4)}`}
                              >
                                {(match.o1 && match.o1[4]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_32"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 5)}`}
                              >
                                {(match.o1 && match.o1[5]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_40"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 6)}`}
                              >
                                {(match.o1 && match.o1[6]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_41"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 7)}`}
                              >
                                {(match.o1 && match.o1[7]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_42"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 8)}`}
                              >
                                {(match.o1 && match.o1[8]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_43"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 9)}`}
                              >
                                {(match.o1 && match.o1[9]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_00"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 10)}`}
                              >
                                {(match.o1 && match.o1[10]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_11"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 11)}`}
                              >
                                {(match.o1 && match.o1[11]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_22"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 12)}`}
                              >
                                {(match.o1 && match.o1[12]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_33"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 13)}`}
                              >
                                {(match.o1 && match.o1[13]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_44"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 14)}`}
                              >
                                {(match.o1 && match.o1[14]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`} rowSpan="2">
                              <span
                                id="CS_AOS"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o1', 15)}`}
                              >
                                {(match.o1 && match.o1[15]) || ""}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_01"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 0)}`}
                              >
                                {(match.o2 && match.o2[0]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_02"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 1)}`}
                              >
                                {(match.o2 && match.o2[1]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_12"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 2)}`}
                              >
                                {(match.o2 && match.o2[2]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_03"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 3)}`}
                              >
                                {(match.o2 && match.o2[3]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_13"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 4)}`}
                              >
                                {(match.o2 && match.o2[4]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_23"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 5)}`}
                              >
                                {(match.o2 && match.o2[5]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_04"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 6)}`}
                              >
                                {(match.o2 && match.o2[6]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_14"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 7)}`}
                              >
                                {(match.o2 && match.o2[7]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_24"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 8)}`}
                              >
                                {(match.o2 && match.o2[8]) || ""}
                              </span>
                            </td>
                            <td className={`${classes.link}`}>
                              <span
                                id="CS_34"
                                onClick={(e) => {
                                  handleOdd(match.p, e);
                                }}
                                className={`${getHighlightClass(match, 'o2', 9)}`}
                              >
                                {(match.o2 && match.o2[9]) || ""}
                              </span>
                            </td>
                          </tr>
                          </tbody>
                        </React.Fragment>
                      ))}
                </table>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CorrectScore;

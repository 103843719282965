import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/SwitchToMobile.css";
import { isMobileOnly } from 'react-device-detect';
import axiosInstance from "../config";

const REACT_APP_MOBILE_URL = process.env.REACT_APP_MOBILE_URL;

const SwitchToMobile = () => {
  const { t } = useTranslation();

  const handleSwitch = () => {
    let url = REACT_APP_MOBILE_URL;
    axiosInstance
      .post('token/desktop/switch')
      .then((res) => {
        url += '?token='+res.data;
      })
      .catch((err) => {
      })
      .finally(()=>{
        var lang = localStorage.getItem('language') || 'en';
        localStorage.clear();
        localStorage.setItem('language',lang);
        window.location.href = url + '&lang='+lang;
      });
  }

  return (
    <>
    {isMobileOnly?(
      <div className="footer">
        <button
          onClick={() => {
            handleSwitch();
            // localStorage.setItem("forceDesktop", "");
            // window.location.href = REACT_APP_MOBILE_URL;
          }}
          className="mobileswitch"
        >
          <img className="icon" src={require("../img/Icons/mobile.svg")} />
          <span className={`lang-${localStorage.getItem('language')}`}>{t("Switch to Mobile")}</span>
        </button>
      </div>
    ): null}
    </>
  );
};

export default SwitchToMobile;

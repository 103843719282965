import Messages from './pages/Messages';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Transaction from './pages/Transaction';
import Cash from './pages/Cash';
import History from './pages/History';
import HistoryDetail from './pages/HistoryDetail';
import Home from './pages/public/Home';
import Error from './pages/public/Error';
import OddsPage from './pages/public/OddsPage';
import ResultsPage from './pages/public/ResultsPage';
import VideoPage from './pages/public/VideoPage';
import Odds from './pages/Odds';
import Terms from './pages/Terms';
import Parley from './pages/Parley';
import Password from './pages/Password';
import Results from './pages/Results';
import Wager from './pages/Wager';
import CorrectScore from './pages/CorrectScore';
import OETG from './pages/OETG';
import Withdraw from './pages/Withdraw';
import Maintenance from './pages/Maintenance';
import NotFound from './pages/NotFound';
import PwdExpiry from './pages/PwdExpiry';
import LegacyHistory from './pages/LegacyHistory';
import LegacyHistoryDetail from './pages/LegacyHistoryDetail';
import { useTranslation } from 'react-i18next';
// import { isMobileOnly } from 'react-device-detect';
// import {ErrorBoundary} from 'react-error-boundary'
import * as Sentry from '@sentry/react';
import Analytics from 'react-router-ga';
import Slots from './pages/Slots';
// const REACT_APP_MOBILE_URL = process.env.REACT_APP_MOBILE_URL;
const REACT_APP_GA_CODE = process.env.REACT_APP_GA_CODE;

function App() {
  /*
  const { i18n } = useTranslation();

  const getParameterByName = (name, url = window.location.href) => {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const lang = getParameterByName('lang');
  if(lang) {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  }*/

  // const isForce = getParameterByName('desktop');

  // if (isMobileOnly) {
    // if(!isForce) {
    //   if(!localStorage.getItem('forceDesktop')) {
    //     window.location.href = REACT_APP_MOBILE_URL;
    //   }
    // } else {
    //   localStorage.setItem('forceDesktop', 'yes');
    // }
  // }

  return (
    <>
    {/* <Sentry.ErrorBoundary
      fallback={Error}
    > */}
      <Router>
      <Analytics id={REACT_APP_GA_CODE}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/oddspage" component={OddsPage} />
          <Route exact path="/pwdexpiry" component={PwdExpiry} />
          <Route exact path="/resultspage" component={ResultsPage} />
          <Route exact path="/video/:id" component={VideoPage} />
          <Route exact path="/video" component={NotFound} />
          <ProtectedRoute exact path="/terms" component={Terms} />
          <ProtectedRoute exact path="/odds" component={Odds} />
          <ProtectedRoute exact path="/parley" component={Parley} />
          <ProtectedRoute exact path="/wager" component={Wager} />
          <ProtectedRoute exact path="/correctscore" component={CorrectScore} />
          <ProtectedRoute exact path="/oe-tg" component={OETG} />
          <ProtectedRoute exact path="/history" component={History} />
          <ProtectedRoute
            exact
            path="/legacy/history"
            component={LegacyHistory}
          />
          <ProtectedRoute exact path="/slots" component={Slots} />
          <ProtectedRoute exact path="/password" component={Password} />
          <ProtectedRoute exact path="/results" component={Results} />
          <ProtectedRoute path="/history/:date" component={HistoryDetail} />
          <ProtectedRoute
            path="/legacy/history/:date"
            component={LegacyHistoryDetail}
          />
          <ProtectedRoute path="/withdraw" component={Withdraw} />
          <ProtectedRoute exact path="/cash" component={Transaction} />
          <ProtectedRoute path="/cash/:date" component={Cash} />
          <ProtectedRoute path="/messages" component={Messages} />
          <Route>
            <Redirect to="/"/>
          </Route>
        </Switch>
      </Analytics>
      </Router>
    {/* </Sentry.ErrorBoundary> */}
    </>
  );
}

export default App;

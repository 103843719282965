import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import axiosInstance from "../config";
import SelectLanguage from "./SelectLanguage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  upper: {
    backgroundColor: "#DC252F",
  },
  nomr: {
    marginRight: "0px !important",
  },
  lower: {
    backgroundColor: "#0E2336",
    height: "40px",
  },
  wrapper: {
    maxWidth: "1180px",
    margin: "0 auto !important",
    float: "none !important",
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  helper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: 'absolute',
    right: '-1.75rem',
  },
  navBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    margin: "0 0.25rem",
    padding: "0.25rem 0.5rem",
    color: "rgba(255,255,255,0.7)",
    backgroundColor: "transparent",
    border: "1px solid rgba(255,255,255,0.7)",
  },
  activeBtn: {
    backgroundColor: "rgba(255,255,255,0.4)",
    color: "#fff",
    margin: "0 0.25rem",
  },
  yellow: {
    backgroundColor: "#FAB81D",
  },
  blue: {
    backgroundColor: "#2A10FF",
  },
  purple: {
    backgroundColor: "#DD01E9",
  },
}));

const Navbar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleLogout = () => {
    axiosInstance
      .post("token/invalidate")
      .then((res) => {
        var lang = localStorage.getItem('language') || 'en';
        localStorage.clear();
        localStorage.setItem('language',lang);
      })
      .catch((err) => console.log(err));
  };

  return (
    <nav className="Navbar">
      <div className={classes.upper}>
        <div className={classes.wrapper}>
          {/* absolutely positioned logo */}
          <img
            src={require("../img/Icons/logo2.png")}
            alt=""
            className="logo"
          />
          <ul className="navbar-upper"></ul>
          <div className={classes.helper}>
            <ul className="navbar-upper">
              <li className={classes.nomr}>
                <Link to="/" onClick={handleLogout}>
                  <img src={require("../img/Icons/log-out-3.svg")} alt="" />
                  <span className={`lang-${localStorage.getItem('language')}`}>{t("Logout")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* bottom blueish area */}
      <div className={classes.lower}>
        <div className={classes.wrapper}>
          <div className="menu-wrapper">
            <nav id="primary_nav_wrap"></nav>
          </div>
          <SelectLanguage style={{ height: 40 }} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

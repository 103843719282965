import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import LeftSide from '../components/LeftSide';
import Navbar from '../components/Navbar';
import TickerNews from '../components/TickerNews';
import ViewDetailModal from '../components/ViewDetailModal';
import axiosInstance from '../config';
import {logout} from '../components/Utils';
import '../styles/Wager.css';

const useStyles = makeStyles((theme) => ({
  none: {
    display: 'none',
  },
  reject: {
    textDecoration: 'line-through',
  },
  score: {
    fontSize: '13px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  result: {
    fontSize: '13px',
    color: '#323030',
    textAlign: 'center',
  },
  strong: {
    color: '#DC252F',
  },
  MainArea: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '86px auto auto !important',
    float: 'none !important',
  },
  MainArea2: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '10px auto auto !important',
    float: 'none !important',
  },
  table: {
    width: '100%',
  },
  index: {
    fontSize: '10px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  money: {
    fontSize: '13px',
    color: '#1452CC',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: '18px',
    width: '18px',
    marginRight: '0 0.5rem',
  },
  header: {
    color: '#18334C',
    fontSize: '14px',
    marginLeft: '0.5rem',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    // overflowY: 'scroll',
    // maxHeight: '80%',
    borderRadius: '4px',
  },
  modalStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blue: {
    color: '#1452CC',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  view: {
    backgroundColor: '#fff',
    color: '#86898C',
    lineHeight: '12px',
    fontWeight: 'bold',
    fontSize: '12px',
    border: '1px solid #86898C',
    borderRadius: '2px',
    cursor: 'pointer',
    padding: '0.25rem',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  teams: {
    fontSize: '13px',
    color: '#323030',
    margin: '0.15rem 0 !important',
  },
  compName: {
    color: '#0E2336',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  spacing: {
    padding: '0.35rem 0.5rem',
  },
  blank: {
    backgroundColor: '#86898C',
  },
  LeftPanel: {
    width: '20%',
  },
  RightPanel: {
    width: '80%',
    marginTop: "10px"
  },
  betType: {
    color: '#18334C',
  },
  time: {
    color: '#18334C',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
  font14: {
    fontSize: '14px',
  },
}));
const Wager = ({ User }, props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  //   wager
  const [wager, setWager] = useState(null);
  // modal
  const [open, setOpen] = useState(false);
  // passing data to modal
  const [data, setData] = useState(null);
  const printRef = useRef();
  const history = useHistory();
  const [totalPage, setTotalPage] = useState(null);

  const [betAmountTotal, setBetAmountTotal] = useState(0);
  const [betPayoutTotal, setBetPayoutTotal] = useState(0);

  useEffect(() => {
    axiosInstance
      .get('portal/wager')
      .then((res) => {
        setWager(res.data.content);
        setTotalPage(res.data.totalPages);
        //console.log(res.data.content);
        calculateTotal(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [history]);

  const handleChange = (event, value) => {
    //console.log(value);
    axiosInstance
      .get(`portal/wager`, {
        params: {
          pageNo: value - 1,
        },
      })
      .then((res) => {
        //console.log(res.data.content);
        setWager(res.data.content);
        setTotalPage(res.data.totalPages);
        calculateTotal(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          logout(history);
        }
      });
  };

  const handleView = (betId) => {
    axiosInstance
      .get(`portal/mix?id=${betId}`)
      .then((res) => {
        setData(res.data);
        //console.log(res.data);
        setOpen(true);
      })
      .catch((err) => {
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const calculateTotal = (wager) => {
    console.log(wager.length);
    if (wager !== null && wager.length > 0) {
      let betAmountTotalTemp = 0;
      let betPayoutTotalTemp = 0;
      wager.forEach((bet) => {
        betAmountTotalTemp += bet.amount;
        betPayoutTotalTemp += bet.payout;
      });
      setBetAmountTotal(betAmountTotalTemp);
      setBetPayoutTotal(betPayoutTotalTemp);
    }
  };

  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div
              className={classes.flex}
              style={{ justifyContent: 'space-between' }}
            >
              <div className={classes.flex2}>
                <img
                  src={require('../img/Icons/coin-9.svg')}
                  alt=""
                  className={classes.img}
                />
                <span className={classes.header}>
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Wager')}
                  </span>
                </span>
              </div>
              <Link to="#" className={classes.handiCap} onClick={handlePrint}>
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Print')}
                </span>
              </Link>
            </div>

            {/* table for wager */}
            <div className="Wager">
              <table className={classes.table} ref={printRef}>
                <thead>
                  <tr>
                    <th style={{ borderRadius: '4px 0 0 0' }}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('No.')}
                      </span>
                    </th>
                    <th>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Time')}
                      </span>
                    </th>
                    <th>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Bet Types')}
                      </span>
                    </th>
                    <th>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Details')}
                      </span>
                    </th>
                    <th>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Stake')}
                      </span>
                    </th>
                    <th style={{ borderRadius: '0 4px  0 0' }}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Ease Payout')}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* render info about different bets placed */}
                  {wager !== null ? (
                    <>
                      {wager.map((bet, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <p className={classes.index}>{index + 1}</p>
                            </td>
                            <td
                              style={{ textAlign: 'center' }}
                              className={classes.time}
                            >
                              <p style={{ margin: '0.15rem 0' }}>
                                {moment(bet.created).format('DD/MM/YY')}
                              </p>
                              <p>{moment(bet.created).format('hh:mm a')}</p>
                            </td>
                            <td className={classes.betType}>
                              {bet.market === 'PARLAY' ? (
                                <div className={classes.spacing}>
                                  <p className={classes.teams}>
                                    <span
                                      className={`lang-${localStorage.getItem(
                                        'language'
                                      )}`}
                                    >
                                      {t('Mix Parlay')}
                                    </span>
                                  </p>
                                  <p className={classes.teams}>{bet.type}</p>
                                  <p className={classes.teams}>
                                    {bet.reference}
                                  </p>
                                </div>
                              ) : (
                                <div className={classes.spacing}>
                                  <p className={classes.teams}>
                                    {bet.sport} {bet.market}
                                  </p>
                                  <p className={classes.teams}>{bet.game}</p>
                                  <p className={classes.teams}>
                                    {bet.reference}
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              {bet.market === 'PARLAY' ? (
                                <div
                                  className={classes.flex}
                                  style={{
                                    justifyContent: 'space-between',
                                    padding: '0 0.5rem',
                                  }}
                                >
                                  <p className={classes.compName}>
                                    <span
                                      className={`lang-${localStorage.getItem(
                                        'language'
                                      )}`}
                                    >
                                      {t('Mix Parlay__')}
                                    </span>
                                    {bet.odds.toFixed(2)}
                                  </p>
                                  {bet.market === 'LIVE' ? (
                                    <div className={classes.flex}>
                                      <p className={classes.running}>
                                        (Running {bet.homeGoal}-{bet.awayGoal})
                                      </p>
                                    </div>
                                  ) : null}
                                  <button
                                    className={classes.view}
                                    onClick={() => {
                                      handleView(bet.bet);
                                    }}
                                    // id={bet.bet}
                                  >
                                    <span
                                      className={`lang-${localStorage.getItem(
                                        'language'
                                      )}`}
                                    >
                                      {t('View Detail')}
                                    </span>
                                  </button>
                                </div>
                              ) : (
                                <div className={classes.spacing}>
                                  <p className={classes.compName}>
                                    {bet.comp} {bet.compType}
                                  </p>
                                  <p className={classes.teams}>
                                    <span
                                      className={
                                        bet.high === 1 ? classes.strong : null
                                      }
                                    >
                                      {bet.home}
                                    </span>{' '}
                                    vs{' '}
                                    <span
                                      className={
                                        bet.high === 2 ? classes.strong : null
                                      }
                                    >
                                      {bet.away}
                                    </span>
                                  </p>
                                  {bet.market === 'LIVE' ? (
                                    <p className={classes.running}>
                                      (Running {bet.homeGoal}-{bet.awayGoal})
                                    </p>
                                  ) : null}
                                  <p className={classes.compName}>
                                    {bet.desc}{' '}
                                    {bet.handicap !== '' ? (
                                      <span className={classes.handiCap}>
                                        ({bet.handicap}){' '}
                                      </span>
                                    ) : null}
                                    <span>
                                      @ {bet.odds.toFixed(2)}
                                      {bet.status === 'PENDING' ? (
                                        <span className={classes.running}>
                                          {' '}
                                          ({bet.status})
                                        </span>
                                      ) : null}
                                    </span>
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              <p className={classes.money}>
                                <CurrencyFormat
                                  value={bet.amount}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </p>
                            </td>
                            <td>
                              <p className={classes.money}>
                                <CurrencyFormat
                                  value={bet.market === 'PARLAY' ? bet.amount / parseInt(bet.type.split(" ")[2]) * bet.odds : bet.payout}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </p>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      {wager != null && wager.length > 0 ? (
                        <React.Fragment key={'total'}>
                          <tr style={{ borderTop: 'outset' }}>
                            <td colSpan="4">
                              <center className={classes.font14}>
                                <b>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Total')}
                                  </span>
                                </b>
                              </center>
                            </td>
                            <td>
                              <p className={`${classes.money} ${classes.font14}`}>
                                <CurrencyFormat
                                  value={betAmountTotal}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </p>
                            </td>
                            <td></td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        <tr>
                            <td colSpan="6" align="center">
                              {t('No outstanding wager')}
                            </td>
                          </tr>
                      ) }
                    </>
                  ) : null}
                </tbody>
              </table>
              <Pagination
                count={totalPage}
                onChange={handleChange}
                className={classes.pagination}
              />
            </div>
          </Grid>
        </Grid>
        {/* modal  */}
        <ViewDetailModal data={data} open={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default Wager;

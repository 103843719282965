import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Scrollbars from 'react-custom-scrollbars';
import GifPlayer from 'react-gif-player';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../config';
import BetInfo from './BetInfo';
import ErrorToast from './ErrorToast';
import SwitchToMobile from './SwitchToMobile';
import { ToastContainer, toast } from 'react-toastify';
import {logout} from './Utils';
import { useReactToPrint } from 'react-to-print';
import '../styles/Left.css';

const useStyles = makeStyles((theme) => ({
  lastBtn: {
    fontSize: '12px',
    color: '#ffffff',
    textTransform: 'lowercase',
    lineHeight: '1',
    padding: '0px 8px !important',
    minHeight: '0',
    minWidth: '0',
  },
}));
const ParlayLeft = ({
  User,
  // quote,
  // setQuote,
  ParlayInfo,
  setParlayInfo,
  isBetParlay,
  setBetParlay,
  setbetSuccess,
  betSuccess,
  oddError,
  setOddError,
  oddUpdating,
  setOddUpdating,
  addParlay,
  setAddParlay,
}) => {
  const { t } = useTranslation();
  const [betFinal, setBetFinal] = useState({});
  const [money, setMoney] = useState(0);
  const [payoutValue, setPayoutValue] = useState('');
  const [balance, setBalance] = useState(User.user.balance);
  const [currency] = useState(User.user.currency);
  const [lastBet, setLastbet] = useState({});
  const [isLastBet, setisLastBet] = useState(false);
  const [amountM, setAmountM] = useState('');
  const [total, setTotal] = useState(0);
  const [hideLast, setHideLast] = useState(localStorage.getItem('hide'));
  const yourbets = useRef();
  const [updateLastbet, setUpdateLastbet] = useState(new Date());
  const [refreshOdds, setRefreshOdds] = useState(new Date());
  const [isBetInProgress, setBetInProgress] = useState(false);
  const [betSuccessTimeout, setBetSuccessTimeout] = useState('');
  const printRef = useRef();

  const history = useHistory();
  const classes = useStyles();
  let displayQuote = 'block';

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // handle remove
  const handleRemove = (quote, e) => {
    //console.log(quote);
    //console.log(ParlayInfo.quote);
    const requestBody = {
      id: quote,
      quote: ParlayInfo.quote,
    };
    axiosInstance
      .post('restricted/parlay/remove', qs.stringify(requestBody))
      .then((res) => {
        if (res.data.parlays.length === 0) {
          setBetParlay(false);
          setParlayInfo(null);
        }
        setParlayInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };

  const handleMoney = (e) => {
    setMoney(e.target.value);
  };
  //   handle payout value
  const handlePayoutValue = (e) => {
    setPayoutValue(e.target.value);
    calculateParlayLimit();
  };

  const calculateParlayLimit = () => {
    if (document.getElementById('type')) {
      var val =
        document.getElementById('type').options[
          document.getElementById('type').selectedIndex
        ].innerHTML;
      var multiplier = val.split(' ')[2];
      if (parseInt(multiplier) > 1 && ParlayInfo.allUpLimit != null) {
        ParlayInfo.maxBet = Math.floor(ParlayInfo.allUpLimit / multiplier);
      } else if (parseInt(multiplier) === 1 && ParlayInfo.parlayLimit != null) {
        ParlayInfo.maxBet = ParlayInfo.parlayLimit;
      }
      // setType(document.getElementById('type').value);
      setAmountM(multiplier);
    } else {
      setAmountM(null);
    }
  };
  // handling the bet after clicking on betnow
  const handleBet = (e) => {
    e.preventDefault();
    if (!isBetInProgress) {
      const betForm = document.querySelector('#betForm');
      const betMoney = betForm['betMoney'].value;
      const typePick = betForm['type'];
      const requestBody = {
        quote: ParlayInfo.quote,
        type: typePick.options[typePick.selectedIndex].id,
        amount: betMoney,
      };
      displayQuote = 'none';

      setBetInProgress(true);
      axiosInstance
        .post('restricted/bet', qs.stringify(requestBody))
        .then((res) => {
          if (res.data.bet !== undefined) {
            setParlayInfo(null);
            // Scroll to bottom after set data
            localStorage.removeItem('parlayQuote');
            setBetFinal(res.data);
            setbetSuccess(true);
            setAmountM('');
            setMoney(0);
            setAddParlay(false);
            setOddUpdating(false);
            if(betSuccessTimeout) {
              clearTimeout(betSuccessTimeout);
            }
            var timeout = setTimeout(() => {
              setBetSuccessTimeout('');
              setbetSuccess(false);
            }, 60000);
            setBetSuccessTimeout(timeout);
          } else {
            displayQuote = 'block';
            setOddUpdating(true);
            //console.log('setting quote data because odd is updating');
            setParlayInfo(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
          else if (
            err.response &&
            err.response.data &&
            (err.response.data.message === 'AAS001' ||
              err.response.data.message === 'AAS002')
          ) {
            let toastId = toast(
              <ErrorToast
                message={t(
                  'Submit failed. Wait for your upline to finish settlement'
                )}
                onAction={() => {
                  toast.dismiss(toastId);
                }}
              />,
              {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                type: toast.TYPE.ERROR,
                closeButton: false,
              }
            );
          }
          else {
            setOddError(true);
          }
        })
        .finally(() => {
          setBetInProgress(false);
          setUpdateLastbet(new Date());
        });

      setBetParlay(false);
    }
  };


  // close the bet successfull
  const handleClose = () => {
    setbetSuccess(false);
    setUpdateLastbet(new Date());
  };
  // handling refresh
  const handleRefresh = () => {
    setUpdateLastbet(new Date());
  };

  const handleLastBet = (flag) => {
    localStorage.setItem('hide', flag);
    setHideLast(flag);
  };

  useEffect(() => {
    if (yourbets && yourbets.current) {
      yourbets.current.scrollToBottom();
    }
  }, [ParlayInfo]);

  // useEffect to make the last bet load everytime
  useEffect(() => {
    const loadLastBet = () => {
      axiosInstance
        .get('portal/ping')
        .then((res) => {
          // portal/last - portion
          let bets = [];
          if (res && res.data && res.data.bets) {
            bets = res.data.bets;
          }
          setLastbet(bets);
          let sum = 0;
          for (let i = 0; i < bets.length; i++) {
            sum = sum + bets[i].amount;
          }
          //console.log(sum);
          setTotal(sum);
          if (bets.length !== 0) {
            setisLastBet(true);
          }

          // portal/me - portion
          setBalance(res.data.availableBalance);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    };

    loadLastBet();
  }, [history, updateLastbet]);

  useEffect(() => {
    // refresh last bets
    const timer = setInterval(() => {
      setUpdateLastbet(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (document.getElementById('type')) {
      document.getElementById('type').selectedIndex = '0';
      calculateParlayLimit();
    }
    // do not set new timer when adding new parlay
    if (!addParlay && ParlayInfo !== null) {
      // do not set new timer when odd is updated
      if (document.querySelector('#updateOdd') != null) {
        const updateOdd = document.querySelector('#updateOdd').value;
        //console.log('updateOdd = ' + updateOdd);
        if (updateOdd === 'Y') {
          // prevent double call
          return;
        }
      }
      // first call from tableinfo - trigger refreshOdd
      // refreshOdd();
      setRefreshOdds(new Date());
    }
  }, [ParlayInfo, history]);

  useEffect(() => {
    if (document.querySelector("#quoteId") != null) {
      // always true
      let qid = document.querySelector("#quoteId").value;
      if (qid === "" && addParlay === true) {
        return;
      }
      if (qid !== "" && qid !== ParlayInfo.quote) {
        // previous run, do not spawn another timer
        //console.log('previod run, do not spawn another timer');
        return;
      }
      axiosInstance
        .get("restricted/parlay/" + ParlayInfo.quote + "/update")
        .then((res) => {
          if (res.data.odds !== undefined) {
            setParlayInfo(res.data);
            document.querySelector("#updateOdd").value = "Y";
            setOddUpdating(true);
            //console.log('setting quote data');
          } else {
            document.querySelector("#updateOdd").value = "";
            setOddUpdating(false);
          }
          // document.querySelector('#quoteId').value = qid;
        })
        .catch((err) => {
          setOddError(true);
          if (err.response && err.response.data.status === 401) {
            history.push("/");
          }
        });
    }
  }, [refreshOdds]);


  useEffect(() => {
  // refresh odds
    const timer = setInterval(() => {
      setRefreshOdds(new Date());
    }, 10000);
    return () => clearInterval(timer);
  }, []);
  // const refreshOdd = () => {
  //   if (document.querySelector('#quoteId') != null) {
  //     // always true
  //     let qid = document.querySelector('#quoteId').value;
  //     if (qid === '' && addParlay === true) {
  //       return;
  //     }
  //     if (qid !== '' && qid !== ParlayInfo.quote) {
  //       // previous run, do not spawn another timer
  //       //console.log('previod run, do not spawn another timer');
  //       return;
  //     }
  //     axiosInstance
  //       .get('restricted/parlay/' + ParlayInfo.quote + '/update')
  //       .then((res) => {
  //         if (res.data.odds !== undefined) {
  //           setParlayInfo(res.data);
  //           document.querySelector('#updateOdd').value = 'Y';
  //           setOddUpdating(true);
  //           //console.log('setting quote data');
  //         } else {
  //           document.querySelector('#updateOdd').value = '';
  //           setOddUpdating(false);
  //         }
  //         // document.querySelector('#quoteId').value = qid;
  //         setTimeout(() => {
  //           refreshOdd();
  //         }, 10000);
  //         // console.log('refreshTimer start = '+refreshTimer);
  //       })
  //       .catch((err) => {
  //         setOddError(true);
  //         if (err.response && err.response.data.status === 401) {
  //           localStorage.removeItem('token');
  //           history.push('/');
  //         }
  //       });
  //   }
  // };

  return (
    <>
      <ToastContainer />

      <div className={`leftside-sticky`}>
        <Scrollbars
          ref={yourbets}
          autoHeight
          autoHeightMax={'calc(100vh - 94px)'}
          autoHide
          className={'leftside-thinScroll'}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}
          >
            <img
              src={require('../img/Icons/user-20.svg')}
              className={'leftside-img'}
              alt=""
            />
            <span className={'leftside-name'}>
              <span
                className={`lang-${localStorage.getItem(
                  'language'
                )} lang-${localStorage.getItem('language')}-fs-10`}
              >
                {t('Welcome,')}
              </span>{' '}
              <span>
                <strong>{User.user.username}</strong>
              </span>
            </span>
          </div>
          <SwitchToMobile />
          {/* last login card */}
          <div className={`leftside-card`}>
            <div className={'leftside-cardUpper'}>
              <p className={'leftside-heading'}>
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Bet Settings')}
                </span>
              </p>
              <GifPlayer
                gif={require('../img/Icons/preloader.gif')}
                still={require('../img/Icons/refresh.svg')}
                className={'leftside-refresh'}
                pauseRef={(pause2) => (ParlayLeft.pauseGif2 = pause2)}
                onClick={() => {
                  handleRefresh();
                  setTimeout(() => {
                    ParlayLeft.pauseGif2();
                  }, 1500);
                }}
              />
            </div>
            <div className={'leftside-cardLower'}>
              <div className={'leftside-flex'}>
                <p className={'leftside-betSetting'}>
                  <span
                    className={`lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-10`}
                  >
                    {t('Balance')}
                  </span>
                </p>
                <p className={'leftside-money'}>
                  {currency}{' '}
                  <NumberFormat
                    value={balance}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
              <div className={'leftside-flex'}>
                <p className={'leftside-betSetting'}>
                  <span
                    className={`lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-10`}
                  >
                    {t('Last Login')}
                  </span>
                </p>
                <p className={'leftside-money'}>
                  {moment(User.user.lastLogin).format('DD MMM HH:mm')}
                </p>
              </div>
            </div>
          </div>
          {/* sticky div */}

          <div>
            {/* <div className="Left-sticky"> */}
            {/* mapping parley */}
            {ParlayInfo !== null ? (
              <>
                <div className={'leftside-card'} display={displayQuote}>
                  <div className={'leftside-cardUpper'}>
                    <p className={'leftside-heading'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Your Bets (Mix Parlay)')}
                      </span>
                    </p>
                  </div>
                  {oddError === false && ParlayInfo.parlays.length > 0 ? (
                    <div className={'leftside-cardLower2'}>
                      {/* logic for getting all the matched */}

                      {ParlayInfo.parlays.map((match, index) => (
                        <div
                          key={index}
                          className={`leftside-matchWrapper ${
                            index + 1 < ParlayInfo.parlays.length ||
                            ParlayInfo.types.length !== 0
                              ? 'leftside-matchDivider'
                              : null
                          }`}
                        >
                          <p className="leftside-compName">{match.comp} {match.compType}</p>
                          <p className="leftside-teams">
                            <span
                              className={
                                match.high === 1 ? 'leftside-strong' : null
                              }
                            >
                              {match.home}
                            </span>{' '}
                            vs{' '}
                            <span
                              className={
                                match.high === 2 ? 'leftside-strong' : null
                              }
                            >
                              {match.away}
                            </span>
                          </p>

                          {match.market === 'LIVE' ? (
                            <p className={'leftside-running'}>
                              (Running {match.homeGoal}-{match.awayGoal})
                            </p>
                          ) : null}
                          <p className={'leftside-desc'}>
                            {match.desc}
                            {match.handicap !== '' ? (
                              <span className={'leftside-handiCap'}>
                                {' '}
                                ({match.handicap})
                              </span>
                            ) : null}
                          </p>
                          <div className={'leftside-flex'}>
                            <p className={'leftside-desc'}>@ {match.odds}</p>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              // data-parley={match.quote}
                              className={'leftside-remove'}
                              onClick={(e) => {
                                handleRemove(match.quote, e);
                              }}
                            >
                              <path
                                d="M6 1C8.757 1 11 3.243 11 6C11 8.757 8.757 11 6 11C3.243 11 1 8.757 1 6C1 3.243 3.243 1 6 1ZM6 0C2.6865 0 0 2.6865 0 6C0 9.3135 2.6865 12 6 12C9.3135 12 12 9.3135 12 6C12 2.6865 9.3135 0 6 0ZM9 8.269L6.704 5.995L8.977 3.7015L8.269 3L5.9965 5.2945L3.7025 3.023L3 3.7255L5.2965 6.0015L3.023 8.2975L3.7255 9L6.003 6.702L8.2985 8.977L9 8.269Z"
                                fill="#86898C"
                              />
                            </svg>
                          </div>
                        </div>
                      ))}

                      {oddUpdating === true ? (
                        <div className={'leftside-cardLower'}>
                          <p className={'leftside-warning'}>
                            <span
                              className={`lang-${localStorage.getItem(
                                'language'
                              )}`}
                            >
                              {t('Odd is updated')}
                            </span>
                          </p>
                        </div>
                      ) : null}
                      {/* logic for selecting which type */}
                      <form onSubmit={handleBet} id="betForm">
                        <input
                          type="hidden"
                          id="quoteId"
                          value={ParlayInfo.quote}
                        />
                        <input type="hidden" id="updateOdd" value="" />
                        {ParlayInfo.types.length !== 0 ? (
                          <>
                            <div className={'leftside-amountWrapper'}>
                              {/* amount area */}
                              <div className={'leftside-flex2'}>
                                <p className={'leftside-subHeading'}>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Types')}
                                  </span>
                                </p>
                                <select
                                  name=""
                                  id="type"
                                  required
                                  onChange={handlePayoutValue}
                                  className={'leftside-types'}
                                >
                                  {ParlayInfo.types.map((type, index) => (
                                    <option
                                      value={type.oddM}
                                      key={index}
                                      id={type.key}
                                      amount={type.amountM}
                                    >
                                      {type.val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className={'leftside-flex2'}>
                                <p className={'leftside-subHeading'}>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Amount')}
                                  </span>
                                </p>
                                <input
                                  type="number"
                                  className={'leftside-moneyInput'}
                                  min={ParlayInfo.minBet}
                                  max={ParlayInfo.maxBet}
                                  required
                                  id="betMoney"
                                  onChange={handleMoney}
                                />
                              </div>
                              <p className={'leftside-maxMin'}>
                                <span
                                  className={`lang-${localStorage.getItem(
                                    'language'
                                  )}`}
                                >
                                  {t('Min_')}
                                </span>
                                <strong>{ParlayInfo.minBet}</strong>
                                <span
                                  className={`lang-${localStorage.getItem(
                                    'language'
                                  )}`}
                                >
                                  {t('Max Bet_')}
                                </span>{' '}
                                <strong>{ParlayInfo.maxBet}</strong>
                              </p>
                              <div className={'leftside-flex2'}>
                                <p className={'leftside-subHeading'}>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Payout')}
                                  </span>
                                </p>
                                <p className={'leftside-money'}>
                                  {payoutValue !== '' ? (
                                    <CurrencyFormat
                                      value={
                                        payoutValue * money <= 50000
                                          ? payoutValue * money
                                          : 50000
                                      }
                                      decimalScale={2}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  ) : (
                                    <CurrencyFormat
                                      value={
                                        ParlayInfo.types[0].oddM * money <=
                                        50000
                                          ? ParlayInfo.types[0].oddM * money
                                          : 50000
                                      }
                                      decimalScale={2}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  )}
                                </p>
                              </div>
                              <div className={'leftside-flex2'}>
                                <p className={'leftside-subHeading'}>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Price')}
                                  </span>
                                </p>
                                <p className={'leftside-money'}>
                                  {amountM !== '' ? (
                                    <CurrencyFormat
                                      value={amountM * money}
                                      decimalScale={2}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  ) : (
                                    <CurrencyFormat
                                      value={
                                        ParlayInfo.types[0].amountM * money
                                      }
                                      decimalScale={2}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={'leftside-amountWrapper'}>
                              <div className={'leftside-flex2'}>
                                <input
                                  type="submit"
                                  value={t('Bet Now')}
                                  className={'leftside-betBtn'}
                                  disabled={
                                    ParlayInfo.types.length !== 0 ? false : true
                                  }
                                />
                                <button
                                  className={'leftside-cancelBtn'}
                                  onClick={() => {
                                    setParlayInfo(null);
                                    localStorage.removeItem('parlayQuote');
                                  }}
                                >
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Cancel')}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </form>
                    </div>
                  ) : (
                    /// odd Error
                    <div className={'leftside-cardLower'}>
                      <div className={'leftside-flex2'}>
                        <p className={'leftside-warning'}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Odd is updating')}
                          </span>
                        </p>
                      </div>
                      <div>
                        <center>
                          <button
                            className={'leftside-betBtn'}
                            onClick={() => setParlayInfo(null)}
                          >
                            <span
                              className={`lang-${localStorage.getItem(
                                'language'
                              )}`}
                            >
                              {t('Close')}
                            </span>
                          </button>
                        </center>
                        <p></p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}

            {/* bet succesful screen */}
            {betSuccess === true ? (
              <div className={'leftside-card'} ref={printRef}>
                <div className={'leftside-betUpper'}>
                  <p className={'leftside-betContent'}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Bet Placed Successfully')}
                    </span>
                  </p>
                </div>
                <div className={'leftside-cardLower'}>
                  {betFinal.parlays.map((match, index) => (
                    <React.Fragment key={index}>
                      <div className={'leftside-betWrapper'}>
                        <p className={'leftside-compName'}>{match.comp} {match.compType}</p>
                        <p className={'leftside-teams'}>
                          <span className={match.high === 1 ? 'strong' : null}>
                            {match.home}
                          </span>{' '}
                          vs{' '}
                          <span className={match.high === 2 ? 'strong' : null}>
                            {match.away}
                          </span>
                        </p>

                        {match.market === 'LIVE' ? (
                          <p className={'leftside-running'}>
                            (Running {match.homeGoal}-{match.awayGoal})
                          </p>
                        ) : null}
                        <p className={'leftside-desc'}>
                          {match.desc}
                          {match.handicap !== '' ? (
                            <span className={'leftside-handiCap'}>
                              {' '}
                              ({match.handicap})
                            </span>
                          ) : null}
                        </p>
                        <p className={'leftside-desc'}>@ {match.odds}</p>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Parlay')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>
                      {betFinal.type}
                      {' @ '}
                      {betFinal.odds}
                    </p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Total')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>
                      {' '}
                      <CurrencyFormat
                        value={betFinal.amount}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Payout')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>
                      <CurrencyFormat
                        value={betFinal.odds * betFinal.amount / parseInt(betFinal.type.split(" ")[2])}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Bet no.')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>{betFinal.reference}</p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Account')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>{User.user.username}</p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <button className={'leftside-betBtn'} onClick={handlePrint}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Print')}
                      </span>
                    </button>
                    <button
                      onClick={handleClose}
                      className={'leftside-doneBtn'}
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Done')}
                      </span>
                    </button>
                  </div>
                </div>
                {/* buttons */}
              </div>
            ) : null}

            {/* --------------last ten bets card ------------------*/}

            {isLastBet === true &&
            betSuccess !== true &&
            ParlayInfo == null &&
            lastBet.length !== 0 ? (
              <>
                <div className={'leftside-card'}>
                  <div className={'leftside-cardUpper'}>
                    <p className={'leftside-heading'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Last bets')}
                      </span>{' '}
                      {hideLast === 'true' ? (
                        <Button
                          onClick={() => handleLastBet('false')}
                          className={classes.lastBtn}
                        >
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('[ Show ]')}
                          </span>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleLastBet('true')}
                          className={classes.lastBtn}
                        >
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('[ Hide ]')}
                          </span>
                        </Button>
                      )}
                    </p>
                    <GifPlayer
                      gif={require('../img/Icons/preloader.gif')}
                      still={require('../img/Icons/refresh.svg')}
                      className={'leftside-refresh'}
                      pauseRef={(pause) => (ParlayLeft.pauseGif = pause)}
                      onClick={() => {
                        handleRefresh();
                        setTimeout(() => {
                          ParlayLeft.pauseGif();
                        }, 1500);
                      }}
                    />
                  </div>
                  <div
                    style={{ display: 'true' === hideLast ? 'none' : 'block' }}
                  >
                    <div>
                      <BetInfo lastBet={lastBet} total={total} User={User} />
                    </div>
                  </div>
                  <div className={`leftside-flex3 total`}>
                    <p className={'leftside-desc'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Total')}
                      </span>{' '}
                    </p>
                    <p className={'leftside-amount'}>
                      {' '}
                      <CurrencyFormat
                        value={total}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </div>
                </div>
                <div>&nbsp;</div>
              </>
            ) : null}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default ParlayLeft;

import { Grid } from "@material-ui/core";
import { makeStyles, Card, CardMedia,  Button } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/Messages.css";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 283,
        maxHeight: 500,
        margin: 10,
        position: 'relative', // Added to position buttons within card
        cursor: 'pointer', // Change cursor on hover
      },
      media: {
        height: 140,
        position: 'relative',
      },
      buttonsContainer: {
        textAlign: "center",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the buttons vertically and horizontally
        opacity: 0, // Initially hide the buttons
        transition: 'opacity 0.3s', // Smooth transition for opacity
      },
      cardHover: {
        '&:hover $media': {
          opacity: 0.7, // Reduce opacity on hover
        },
        '&:hover $buttonsContainer': {
          opacity: 1, // Show buttons on hover
        },
      },
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: "18px",
    width: "18px",
    marginRight: "0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "100%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  table: {
    width: "100%",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.3rem 0",
  },
}));

const Slots = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  
  //   single quote
  const [quote, setQuote] = useState(null);
  // messages
  const [games, setGames] = useState(null);
  const [totalPage, setTotalPage] = useState(null);

  const demoClick = (item) => {
    axiosInstance
      .get(`public/slot/games/demo/${item.game}`)
      .then((res) => {
        console.log(res);
        if(res.data && res.data.url) {
            var rurl = res.data.url;
            console.log("rurl:", rurl);
            window.location.href = rurl;
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });

    // process.env.REACT_APP_API_URL
    
  }

  const playNowClick = (item) => {
    axiosInstance
    .get(`restricted/slot/games/live/${item.game}`)
    .then((res) => {
        console.log(res);
        if(res.data && res.data.url) {
            var rurl = res.data.url;
            console.log("rurl:", rurl);
            window.location.href = rurl;
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });

  }


  useEffect(() => {
    axiosInstance
      .get("public/slot/games", {
        params: {
          // pageSize: 10,
        },
      })
      .then((res) => {
        //console.log(res);
        setGames(res.data.list);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [history]);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
         
          <Grid item className={classes.RightPanel}>
            <div className="Messages">
              <div className={classes.flex}>
                <div className={classes.flex2}>
                  <img
                    src={require("../img/Icons/game.svg")}
                    alt=""
                    className={classes.img}
                  />
                  <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Games")}</span></p>
                </div>
              </div>
              {games !== null ? (
                <>
            <div>
            <Grid container spacing={3}>
            { games && games.map((item) => (
                <Grid  item xs={12} sm={6} md={3}>
                 <Card className={`${classes.root} ${classes.cardHover}`}>
                    <CardMedia
                        className={classes.media}
                        image={item.image_url}
                        title={item.name}
                        alt={item.name}
                    />
                    {/* Buttons container */}
                    <div className={classes.buttonsContainer}>
                        <Button onClick={() => {
                            demoClick(item);
                        }} variant="contained" color="primary">
                        {t("DEMO")}
                        </Button>
                        <Button onClick={() => {
                            playNowClick(item);
                        }} variant="contained" color="secondary" style={{ marginTop: 10 }}>
                        {t("PLAY NOW")}
                        </Button>
                    </div>
                </Card>
                </Grid>
            ))}
               
            </Grid>
            </div>

                  
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Slots;

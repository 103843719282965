import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/Cash.css";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  wrapper: {
    display: "flex",
    margin: "1rem 0 0.5rem 0",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "13px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    maxHeight: "18px",
  },
  flex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: "18px",
    width: "18px",
    marginRight: "0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  moneyNeg: {
    fontSize: "13px",
    color: "#E91F1F",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  table: {
    width: "100%",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.3rem 0",
  },
}));

const Cash = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [detail, setDetail] = useState(null);
  const [quote, setQuote] = useState(null);
  // ref for printing
  const printRef = useRef();
  const [totalPage, setTotalPage] = useState(null);

  const handleChange = (event, value) => {
    //console.log(value);
    axiosInstance
      .get(`portal/transactions?date=${props.match.params.date}`, {
        params: {
          pageNo: value - 1,
        },
      })
      .then((res) => {
        //console.log(res.data.content);
        setDetail(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          logout(history);
        }
      });
  };

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    //console.log(props);
    axiosInstance
      .get(`portal/transactions?date=${props.match.params.date}`)
      .then((res) => {
        //console.log(res);
        //console.log(res.data.totalPages);
        setTotalPage(res.data.totalPages);
        setDetail(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 412) {
          history.push("/cash");
        } else if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [props.match.params.date, history]);

  return (
    <>
      <Navbar User={props.User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={props.User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="Cash">
              <div className={classes.flex}>
                <div className={classes.flex2}>
                  <img
                    src={require("../img/Icons/banknote-18.svg")}
                    alt=""
                    className={classes.img}
                  />
                  <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Cash")}</span></p>
                  <Link
                    to="/cash"
                    style={{ color: "#18334C", margin: "0 1rem" }}
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Back To Summary")}</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className={classes.handiCap}
                    onClick={handlePrint}
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Print")}</span>
                  </Link>
                </div>
              </div>
              {detail !== null ? (
                <>
                  <table ref={printRef} className={classes.table}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: "4px 0 0 0" }} width="5%">
                          <span className={`lang-${localStorage.getItem('language')}`}>{t("No.")}</span>
                        </th>
                        <th width="20%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Date & Time")}</span></th>
                        <th width="30%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Transaction")}</span></th>
                        <th width="15%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Beginning")}</span></th>
                        <th width="15%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Amount")}</span></th>
                        <th style={{ borderRadius: " 0 4px 0 0" }} width="15%">
                          <span className={`lang-${localStorage.getItem('language')}`}>{t("End Balance")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.map((info, index) => (
                        <tr key={index} className={classes.content}>
                          <td>
                            <p className={classes.index}>{index + 1}</p>
                          </td>
                          <td align="center">
                            <p>
                              {moment(info.createdAt).format("D MMM h:mm:ss a")}
                            </p>
                          </td>
                          <td align="left">
                            <p>
                              {info.transactionType === "BET"
                                ? `Bet ${info.remarks}`
                                : info.transactionType === "BET_RESULT"
                                ? `Bet Result ${info.remarks}`
                                : info.transactionType === 'CREATE_MEMBER_AVAILCREDIT' 
                                ? 'Account created'
                                : info.transactionType.charAt(0).toUpperCase() +
                                  info.transactionType.slice(1).toLowerCase()}
                            </p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {info.beginning != null ? (
                                <CurrencyFormat
                                  value={info.beginning}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : null}
                            </p>
                          </td>
                          <td align="right">
                            <p className={info.amount < 0 ? classes.moneyNeg : classes.money}>
                              <CurrencyFormat
                                value={info.amount}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {info.end != null ? (
                                <CurrencyFormat
                                  value={info.end}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : null}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    count={totalPage}
                    onChange={handleChange}
                    className={classes.pagination}
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Cash;

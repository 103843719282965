import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';


const useStyles = makeStyles((theme) => ({
  header: {
    color: '#18334C',
    fontSize: '14px',
    marginLeft: '0.5rem',
  },
  stickyoptions: {
    position: 'sticky',
    top: '88px',
    backgroundColor: '#f5f5f5',
    padding: '5px 10px',
    margin: '0 -10px',
    zIndex: '2',
  },
  hide: {
    display: 'none',
  },
  ml5: {
    marginLeft: '4px',
  },
  mr5: {
    marginRight: '4px',
  },
  pl5: {
    paddingLeft: '0.5rem',
  },
  pr5: {
    paddingRight: '0.5rem',
  },
  hk: {
    border: '1px solid #18334C',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#fff',
    color: '#323030',
    padding: '0.25rem 0.1rem',
    textAlign: 'left',
    textAlignLast: 'left',
    cursor: 'pointer',
  },
  noFav: {
    color: '#FAB81D',
    border: '1px solid #FAB81D',
    background: '#fff',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    padding: '0.25rem 0.75rem',
  },
  onlyFav: {
    color: '#ffffff',
    border: '1px solid #FAB81D',
    background: '#FAB81D',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    padding: '0.25rem 0.75rem',
  },
  selectleague: {
    background: '#fff',
    color: '#323030',
    border: '1px solid #18334C',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '1rem',
    padding: '0.25rem 0.75rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    cursor: 'pointer',
  },
  img: {
    height: '18px',
    width: '18px',
  },
  timer: {
    fontWeight: 'bold',
    color: '#DC252F',
    fontSize: '12px',
    paddingLeft: '4px',
    minWidth: '20px',
  },
  font12: {
    fontSize: '12px',
  },
}));


const Timer = ({ refresh, initialSeconds, timerCall }) => {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    setSeconds(initialSeconds);
  },[refresh])

  useEffect(() => {
    if (!seconds) {
      timerCall();
    }
    const timer =
    seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(timer);
  },[seconds])

  return <>
    <img
      onClick={() => timerCall()}
      src={require('../img/Icons/refresh-3.svg')}
      alt=""
      width="16px"
      height="16px"
      className={`${classes.link}`}
    />
    <span
      onClick={() => timerCall()}
      id="timer"
      className={`${classes.timer} ${classes.link}`}
    >
      {seconds < 1? 0: seconds}
    </span>
  </>;
};

export default Timer;

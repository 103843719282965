import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/Pages.css";
import { publicAxiosInstance } from "../../config";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  fullscreen: {
    height: "100vh",
    width: "100vw"
  },
  
}));

const ResultsPage = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const [url, setUrl] = useState('');

    useEffect(() => {
        var id = props.match.params.id;
        if (id) {
          publicAxiosInstance.get('public/video/'+id)
            .then((res)=> {
                var modifiedurl = res.data && res.data.replace('HOST', window.location.hostname) || '';
                setUrl(modifiedurl);
            })
            .catch((err) => { 
                console.log(err);
                history.replace("/404");
            })
            .finally(() => {

            })
        } else {
          history.replace("/404");
        }
      }, []); 

  return (
    <>
    {url?(
         <iframe className={classes.fullscreen} src={url}></iframe>
    ):null}
     
    </>
  );
};

export default ResultsPage;

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Scrollbars } from 'react-custom-scrollbars';
import GifPlayer from 'react-gif-player';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import axiosInstance from '../config';
import BetInfo from './BetInfo';
import SwitchToMobile from './SwitchToMobile';
import ErrorToast from './ErrorToast';
import { ToastContainer, toast } from 'react-toastify';
import {logout} from './Utils';
import '../styles/Left.css';
import Guide from "../pdf/A28i-Euro2020-guide.pdf";

const useStyles = makeStyles((theme) => ({
  lastBtn: {
    fontSize: '12px',
    color: '#ffffff',
    textTransform: 'lowercase',
    lineHeight: '1',
    padding: '0px 8px !important',

    minHeight: '0',
    minWidth: '0',
  },
}));
const LeftSide = ({
  User,
  quote,
  setQuote,
  setbetSuccess,
  betSuccess,
  oddError,
  setOddError,
  oddUpdating,
  setOddUpdating,
}) => {
  const { t } = useTranslation();
  const [betFinal, setBetFinal] = useState({});
  const [payout, setPayout] = useState(0);
  const [balance, setBalance] = useState(User.user.balance);
  const [currency] = useState(User.user.currency);
  const [lastBet, setLastbet] = useState({});
  const [isLastBet, setisLastBet] = useState(false);
  const [total, setTotal] = useState(0);
  // const [radio, setRadio] = useState("N");
  const [hideLast, setHideLast] = useState(localStorage.getItem('hide'));
  const [updateLastbet, setUpdateLastbet] = useState(new Date());
  const [isBetInProgress, setBetInProgress] = useState(false);

  const [betSuccessTimeout, setBetSuccessTimeout] = useState('');
  const [refreshOddTimeout, setRefreshOddTimeout] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const printRef = useRef();
  let displayQuote = 'block';
  let quoteId;
  // const [windowPortal, setWindowPortal] = useState(false);

  const [banner, setBanner] = useState([]);

  useEffect(() => {
    //DESKTOP_PUBLIC_CAROUSEL -  DESKTOP_LEFT
    axiosInstance.get('public/cms?mediaType=DESKTOP_LEFT')
      .then((res)=> {
        if(res.data && res.data.content && res.data.content.length > 0){
          setBanner(res.data.content[0]);
        }
      })
      .catch((err) => console.log(err))
  }, []);

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // handle radio
  // const handleRadio = (e) => {
  //   setRadio(e.target.value);
  // };

  // handle money
  const handleMoney = (e) => {
    setPayout(e.target.value);
  };
  // handling the bet after clicking on betnow
   
  const handleBet = (e) => {
    e.preventDefault();
    if (!isBetInProgress) {
      const betForm = document.querySelector('#betForm');
      const betMoney = betForm['betMoney'].value;
      const requestBody = {
        quote: quote.quote,
        amount: betMoney,
        accept: 'B',
      };
      displayQuote = 'none';
      //console.log('bet quote = ' + quote.quote);
      setBetInProgress(true);
      axiosInstance
        .post('restricted/bet', qs.stringify(requestBody))
        .then((res) => {
          if (res.data.bet !== undefined) {
            setQuote(null);
            console.log(res.data);
            setBetFinal(res.data);
            //console.log(res.data);
            if(res.data && res.data.status === 'REJECTED') {
              setbetSuccess(false);
            } else {
              setbetSuccess(true);
            }
            setPayout(0);
            if(betSuccessTimeout) {
              clearTimeout(betSuccessTimeout);
            }
            var timeout = setTimeout(() => {
              setBetSuccessTimeout('');
              setbetSuccess(false);
            }, 60000);
            setBetSuccessTimeout(timeout);
          } else {
            displayQuote = 'block';
            setOddUpdating(true);
            
            //console.log('setting quote data because odd is updating');
            setQuote(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }

          if (
            err.response &&
            err.response.data &&
            (err.response.data.message === 'AAS001' ||
              err.response.data.message === 'AAS002')
          ) {
            let toastId = toast(
              <ErrorToast
                message={t(
                  'Submit failed. Wait for your upline to finish settlement'
                )}
                onAction={() => {
                  toast.dismiss(toastId);
                }}
              />,
              {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                type: toast.TYPE.ERROR,
                closeButton: false,
              }
            );
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.message === 'AAS003'
          ) {
            let toastId = toast(
              <ErrorToast
                message={t('Submit failed. Your account is suspended')}
                onAction={() => {
                  toast.dismiss(toastId);
                }}
              />,
              {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                type: toast.TYPE.ERROR,
                closeButton: false,
              }
            );
          } else {
            setOddError(true);
          }
        })
        .finally(() => {
          setBetInProgress(false);
          setBetInProgress(false);
          setUpdateLastbet(new Date());
        });
    }
  };

  const refreshCurrentBet = () => {
    var reference = betFinal.reference;
      if (reference != null) {
        axiosInstance
          .get('portal/bets/' + reference)
          .then((res) => {
            setBetFinal(res.data);
            if(res.data && res.data.status === 'REJECTED') {
              setbetSuccess(false);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.data.status === 401) {
              logout(history);
            }
          });
      }
  }

  // close the bet successfull
  const handleClose = () => {
    setbetSuccess(false);
    setUpdateLastbet(new Date());
  };
  // handling refresh
  const handleRefresh = () => {
    setUpdateLastbet(new Date());
  };

  const handleLastBet = (flag) => {
    localStorage.setItem('hide', flag);
    setHideLast(flag);
  };
  // useEffect to make the last bet load everytime
  useEffect(() => {
    const loadLastBet = () => {
      axiosInstance
        .get('portal/ping')
        .then((res) => {
          // portal/last - portion
          let bets = [];
          if (res && res.data && res.data.bets) {
            bets = res.data.bets;
          }
          setLastbet(bets);
          let sum = 0;
          for (let i = 0; i < bets.length; i++) {
            sum = sum + bets[i].amount;
          }
          //console.log(sum);
          setTotal(sum);
          if (bets.length !== 0) {
            setisLastBet(true);
          }

          // portal/me - portion
          setBalance(res.data.availableBalance);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    };

    loadLastBet();
  }, [history, updateLastbet]);

  useEffect(() => {
    // refresh last bets
    const timer = setInterval(() => {
      setUpdateLastbet(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // refresh current bet
    const timer = setInterval(() => {
        if(betFinal && betFinal.status && betFinal.status === 'PENDING') {
            refreshCurrentBet();
        }
      }, 15000);
    return () => clearInterval(timer);
  }, [betFinal]);

  useEffect(() => {
    if (!oddUpdating && quote !== null) {
      if (document.querySelector('#updateOdd') != null) {
        const updateOdd = document.querySelector('#updateOdd').value;
        //console.log('updateOdd = ' + updateOdd);
        if (updateOdd === 'Y') {
          // prevent double call
          return;
        }
      }
      // first call from tableinfo - trigger refreshOdd
      refreshOdd();
    }
  }, [quote, history, oddUpdating]);

  const refreshOdd = () => {
    if (document.querySelector('#quoteId') != null)
      if (document.querySelector('#quoteId') != null) {
        // console.log(
        //   'refreshing - qid = ' + document.querySelector('#quoteId').value
        // );
        const qid = document.querySelector('#quoteId').value;
        if (qid === '') return;
        if (quoteId !== undefined && quoteId !== qid) {
          // previous run, do not spawn another timer
          //console.log('quoteId = ' + quoteId + ', qid = ' + qid);
          return;
        }
        quoteId = qid;
        axiosInstance
          .get('restricted/quote/' + qid + '/update')
          .then((res) => {
            if (res.data.odds !== undefined) {
              setQuote(res.data);
              document.querySelector('#updateOdd').value = 'Y';
              setOddUpdating(true);
              //console.log('setting quote data');
            } else {
              document.querySelector('#updateOdd').value = '';
              setOddUpdating(false);
            }
            //console.log('setting timeout');
            if(refreshOddTimeout) {
              clearTimeout(refreshOddTimeout);
            }
            var timeout = setTimeout(() => {
              setRefreshOddTimeout('');
              refreshOdd();
            }, 10000);
            setRefreshOddTimeout(timeout);
            // console.log('refreshTimer start = '+refreshTimer);
          })
          .catch((err) => {
            setOddError(true);
            if (err.response && err.response.data.status === 401) {
              logout(history);
            }
          });
      }
  };

  return (
    <>
      <ToastContainer />
      <div className={'leftside-sticky'}>
        <Scrollbars
          autoHeight
          autoHeightMax={'calc(100vh - 94px)'}
          autoHide
          className={'leftside-thinScroll'}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}
          >
            <img
              src={require('../img/Icons/user-20.svg')}
              className={'leftside-img'}
              alt=""
            />
            <span className={'leftside-name'}>
              <span
                className={`lang-${localStorage.getItem(
                  'language'
                )} lang-${localStorage.getItem('language')}-fs-10`}
              >
                {t('Welcome,')}
              </span>{' '}
              <span>
                <strong>{User.user.username}</strong>
              </span>
            </span>
          </div>
          <SwitchToMobile />
          {/* 1st card */}
          <div className={`leftside-card`}>
            <div className={'leftside-cardUpper'}>
              <p className={'leftside-heading'}>
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Bet Settings')}
                </span>
              </p>
              <GifPlayer
                gif={require('../img/Icons/preloader.gif')}
                still={require('../img/Icons/refresh.svg')}
                className={'leftside-refresh'}
                pauseRef={(pause2) => (LeftSide.pauseGif2 = pause2)}
                onClick={() => {
                  handleRefresh();
                  setTimeout(() => {
                    LeftSide.pauseGif2();
                  }, 1500);
                }}
              />
            </div>
            <div className={'leftside-cardLower'}>
              <div className={'leftside-flex'}>
                <p className={'leftside-betSetting'}>
                  <span
                    className={`lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-12`}
                  >
                    {t('Balance')}
                  </span>
                </p>
                <p className={'leftside-money'}>
                  {currency}{' '}
                  <NumberFormat
                    value={balance}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
              <div className={'leftside-flex'}>
                <p className={'leftside-betSetting'}>
                  <span
                    className={`lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-12`}
                  >
                    {t('Last Login')}
                  </span>
                </p>
                <p className={'leftside-money'}>
                  {moment(User.user.lastLogin).format('DD MMM HH:mm')}
                </p>
              </div>
            </div>
          </div>
          <div>
            {quote !== null ? (
              <div className={'leftside-card'} display={displayQuote}>
                <div className={'leftside-cardUpper'}>
                  <p className={'leftside-heading'}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Your Bets')}
                    </span>
                  </p>
                </div>
                {oddError === false ? (
                  <div className={'leftside-cardLower'}>
                    <p className={'leftside-compName'}>
                      {quote.comp} {quote.compType}
                    </p>
                    <p className={'leftside-teams'}>
                      <span
                        className={quote.high === 1 ? 'leftside-strong' : null}
                      >
                        {quote.home}
                      </span>{' '}
                      vs{' '}
                      <span
                        className={quote.high === 2 ? 'leftside-strong' : null}
                      >
                        {quote.away}
                      </span>
                    </p>
                    {quote.market === 'LIVE' ? (
                      <p className={'leftside-running'}>
                        (Running {quote.homeGoal}-{quote.awayGoal})
                      </p>
                    ) : null}
                    <p className={'leftside-desc'}>
                      {quote.desc}
                      {quote.handicap !== '' ? (
                        <span className={'leftside-handiCap'}>
                          {' '}
                          ({quote.handicap})
                        </span>
                      ) : null}{' '}
                      <span className={'leftside-desc'}>
                        @{' '}
                        <CurrencyFormat
                          value={quote.odds}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </span>
                    </p>
                    {oddUpdating === true ? (
                      <p className={'leftside-warning'}>
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Odd is updated')}
                        </span>
                      </p>
                    ) : null}
                    {/* <p className={'leftside-desc}>@ {quote.odds.toFixed(2)}</p> */}
                    {/* amount area */}
                    <form onSubmit={handleBet} id="betForm">
                      <div className={'leftside-flex2'}>
                        <p className={'leftside-subHeading'}>{t('Amount')}</p>
                        <input
                          type="number"
                          className={'leftside-moneyInput'}
                          onChange={handleMoney}
                          min={quote.minBet}
                          max={quote.maxBet}
                          required
                          id="betMoney"
                        />
                        <input type="hidden" id="quoteId" value={quote.quote} />
                        <input type="hidden" id="updateOdd" value="" />
                      </div>
                      <p className={'leftside-maxMin'}>
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Min_')}
                        </span>{' '}
                        <strong>{quote.minBet}</strong>
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Max Bet_')}
                        </span>{' '}
                        <strong>{quote.maxBet}</strong>
                      </p>
                      <div className={'leftside-flex2'}>
                        <p className={'leftside-subHeading'}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Payout')}
                          </span>
                        </p>
                        <p className={'leftside-money'}>
                          <CurrencyFormat
                            value={quote.payout * payout}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </p>
                      </div>
                      {/* radio buttons */}

                      {/* <div className={'leftside-radio}>
                      <input
                        type="radio"
                        value="N"
                        id="N"
                        name="odds"
                        defaultChecked
                        onChange={handleRadio}
                      />
                      <label className={`lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`} htmlFor="N">
                        {t("Accept this odd")}
                      </label>
                    </div>
                    <div className={'leftside-radio}>
                      <input
                        type="radio"
                        value="A"
                        id="A"
                        name="odds"
                        onChange={handleRadio}
                      />
                      <label className={`lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`} htmlFor="A">
                        {t("Accept any odd")}
                      </label>
                    </div>
                    <div className={'leftside-radio}>
                      <input
                        type="radio"
                        value="B"
                        id="B"
                        name="odds"
                        onChange={handleRadio}
                      />
                      <label className={`lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`} htmlFor="B">
                        {t("Accept better odd")}
                      </label>
                    </div> */}

                      <div className={'leftside-flex2'}>
                        <input
                          type="submit"
                          value={t('Bet Now')}
                          className={'leftside-betBtn'}
                        />
                        <button
                          className={'leftside-cancelBtn'}
                          onClick={() => setQuote(null)}
                        >
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Cancel')}
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  /// odd Error
                  <div className={'leftside-cardLower'}>
                    <div className={'leftside-flex2'}>
                      <p className={'leftside-warning'}>
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Odd is updating')}
                        </span>
                      </p>
                    </div>
                    <div>
                      <center>
                        <button
                          className={'leftside-betBtn'}
                          onClick={() => setQuote(null)}
                        >
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Close')}
                          </span>
                        </button>
                      </center>
                      <p></p>
                    </div>
                  </div>
                )}
                {/* </Scrollbars> */}
              </div>
            ) : null}

            {/* bet succesful screen */}
            {betSuccess === true ? (
              <div
                className={
                  betFinal.status === 'PENDING'
                    ? 'leftside-pending-card'
                    : 'leftside-card'
                }
                ref={printRef}
              >
                <div
                  className={
                    betFinal.status === 'PENDING'
                      ? 'leftside-betUpperPending'
                      : 'leftside-betUpper'
                  }
                >
                  <p className={'leftside-betContent'}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {betFinal.status === 'PENDING'
                        ? t('Your current bet (waiting)')
                        : t('Bet Placed Successfully')}
                    </span>
                  </p>
                  {betFinal.status === 'PENDING'? (
                  <GifPlayer
                    gif={require('../img/Icons/preloader.gif')}
                    still={require('../img/Icons/refresh.svg')}
                    className={'leftside-refresh'}
                    pauseRef={(pause) => (LeftSide.pauseGif = pause)}
                    onClick={() => {
                      refreshCurrentBet();
                      setTimeout(() => {
                        LeftSide.pauseGif();
                      }, 1500);
                    }}
                  />
                  ):null}
                </div>
                <div className={'leftside-betLower'}>
                  <p className={'leftside-compName'}>
                    {betFinal.comp} {betFinal.compType}
                  </p>
                  <p className={'leftside-teams'}>
                    <span
                      className={betFinal.high === 1 ? 'leftside-strong' : null}
                    >
                      {betFinal.home}
                    </span>{' '}
                    vs{' '}
                    <span
                      className={betFinal.high === 2 ? 'leftside-strong' : null}
                    >
                      {betFinal.away}
                    </span>
                  </p>

                  {betFinal.market === 'LIVE' ? (
                    <p className={'leftside-running'}>
                      (Running {betFinal.homeGoal}-{betFinal.awayGoal})
                    </p>
                  ) : null}
                  <p className={'leftside-desc'}>
                    {betFinal.desc}
                    {betFinal.handicap !== '' ? (
                      <span className={'leftside-handiCap'}>
                        {' '}
                        ({betFinal.handicap})
                      </span>
                    ) : null}
                    {betFinal.status === 'PENDING' ? (
                      <span className={'leftside-running'}>
                        {' '}
                        ({betFinal.status})
                      </span>
                    ) : null}
                  </p>
                  <p className={'leftside-desc'}>
                    @{' '}
                    <CurrencyFormat
                      value={betFinal.odds}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Amount')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>
                      {' '}
                      <CurrencyFormat
                        value={betFinal.amount}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Payout')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>
                      <CurrencyFormat
                        value={betFinal.payout}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Account')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>{User.user.username}</p>
                  </div>
                  <div className={'leftside-flex2'}>
                    <p className={'leftside-desc2'}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Bet no.')}
                      </span>
                    </p>
                    <p className={'leftside-desc'}>{betFinal.reference}</p>
                  </div>

                  <div className={'leftside-flex2'}>
                    <button
                      className={
                        betFinal.status === 'PENDING'
                          ? 'leftside-disabledBtn'
                          : 'leftside-betBtn'
                      }
                      onClick={handlePrint}
                      disabled={betFinal.status === 'PENDING' ? true : false}
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Print')}
                      </span>
                    </button>
                    <button
                      onClick={handleClose}
                      className={'leftside-doneBtn'}
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Done')}
                      </span>
                    </button>
                  </div>
                </div>
                {/* buttons */}
              </div>
            ) : null}
          </div>
          <div>
            {/* Last 10 */}
            {isLastBet === true &&
            betSuccess !== true &&
            quote == null &&
            lastBet.length !== 0 ? (
              <div className={'leftside-card'}>
                <div className={'leftside-cardUpper'}>
                  <p className={'leftside-heading'}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Last bets')}
                    </span>{' '}
                    {hideLast === 'true' ? (
                      <Button
                        onClick={() => handleLastBet('false')}
                        className={classes.lastBtn}
                      >
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('[ Show ]')}
                        </span>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleLastBet('true')}
                        className={classes.lastBtn}
                      >
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('[ Hide ]')}
                        </span>
                      </Button>
                    )}
                  </p>
                  <GifPlayer
                    gif={require('../img/Icons/preloader.gif')}
                    still={require('../img/Icons/refresh.svg')}
                    className={'leftside-refresh'}
                    pauseRef={(pause) => (LeftSide.pauseGif = pause)}
                    onClick={() => {
                      handleRefresh();
                      setTimeout(() => {
                        LeftSide.pauseGif();
                      }, 1500);
                    }}
                  />
                </div>
                <div
                  style={{ display: 'true' === hideLast ? 'none' : 'block' }}
                >
                  <div className={'leftside-betCardLower'}>
                    <BetInfo lastBet={lastBet} total={total} User={User} />
                  </div>
                </div>
                <div className={`${'leftside-flex3'} ${'leftside-total'}`}>
                  <p className={'leftside-desc'}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Total')}
                    </span>{' '}
                  </p>
                  <p className={'leftside-amount'}>
                    {' '}
                    <CurrencyFormat
                      value={total}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <div className={classes.card}>
            {banner?(
              <img
                className={`centerImage ${banner.hyperlinkUrl?classes.pointer:''}`}
                width="100%"
                alt={banner.name}
                src={banner.pictureUrl}
                onClick={() => {
                  if(banner.hyperlinkUrl) {
                    window.open(banner.hyperlinkUrl, '_blank');
                  }
                }}
              />
            ): null}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default LeftSide;

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import LeftSide from '../components/LeftSide';
import Navbar from '../components/Navbar';
import TickerNews from '../components/TickerNews';
import axiosInstance from '../config';
import {logout} from '../components/Utils';
import '../styles/History.css';

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '86px auto auto !important',
    float: 'none !important',
  },
  MainArea2: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '10px auto auto !important',
    float: 'none !important',
  },
  button: {
    margin: '0 0.5rem',
  },
  content: {
    color: '#323030',
    fontSize: '13px',
    textAlign: 'right',
    fontWeight: '500',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: '18px',
    width: '18px',
    marginRight: '0.5rem',
  },
  header: {
    color: '#18334C',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '0',
  },
  LeftPanel: {
    width: '20%',
  },
  RightPanel: {
    width: '80%',
    marginTop: "10px"
  },
  money: {
    fontSize: '13px',
    color: '#1452CC',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0.15rem 0.35rem !important',
  },
  table: {
    width: '100%',
  },
  negative: {
    color: '#E91F1F',
  },
  font14: {
    fontSize: '14px',
  },
}));

const History = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  // history
  const [history, setHistory] = useState(null);
  // set date
  // const [date, setDate] = useState(null);
  // ref for printing
  const printRef = useRef();
  // history replacement
  const navigate = useHistory();

  const [stakeTotal, setStakeTotal] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [winningTotal, setWinningTotal] = useState(0);
  const [commissionTotal, setCommissionTotal] = useState(0);
  const [netWinTotal, setNetWinTotal] = useState(0);

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const calculateTotal = (content) => {
    if (content !== undefined && content !== null && content.length > 0) {
      let stakeTotalTemp = 0;
      let amountTotalTemp = 0;
      let winningTotalTemp = 0;
      let commissionTotalTemp = 0;
      let netWinTotalTemp = 0;

      content.forEach((data) => {
        if (data.ct !== undefined) stakeTotalTemp += data.ct;

        if (data.amount !== undefined) amountTotalTemp += data.amount;

        if (data.winning !== undefined) winningTotalTemp += data.winning;

        if (data.commission !== undefined)
          commissionTotalTemp += data.commission;

        if (data.commission !== undefined && data.winning !== undefined)
          netWinTotalTemp += data.commission + data.winning;
      });
      setStakeTotal(stakeTotalTemp);
      setAmountTotal(amountTotalTemp);
      setWinningTotal(winningTotalTemp);
      setCommissionTotal(commissionTotalTemp);
      setNetWinTotal(netWinTotalTemp);
    }
  };

  useEffect(() => {
    axiosInstance
      .get('portal/history')
      .then((res) => {
        setHistory(res.data);
        if (res.data !== null) {
          calculateTotal(res.data.content);
        }
        //console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [navigate]);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="History">
              {history !== null ? (
                <>
                  <div className={classes.flex}>
                    <div className={classes.flex2}>
                      <img
                        src={require('../img/Icons/time-17.svg')}
                        alt=""
                        className={classes.img}
                      />
                      <span
                        className={`lang-${localStorage.getItem('language')} ${
                          classes.header
                        }`}
                      >
                        {t('History')}
                      </span>
                      <Link
                        to="#"
                        className={classes.handiCap}
                        onClick={handlePrint}
                      >
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Print')}
                        </span>
                      </Link>
                    </div>
                    {/* <div>
                      <button
                        className={classes.legacyBtn}
                        onClick={() => {
                          navigate.push('/legacy/history');
                        }}
                      >
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Legacy History')}
                        </span>
                      </button>
                    </div> */}
                  </div>
                  <table ref={printRef} className={classes.table}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: '4px 0 0 0' }}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Date')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Stake')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Amount')}
                          </span>
                        </th>
                        <th style={{ borderRadius: ' 0 4px 0 0' }}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Winning')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Commission')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Net Win')}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.content.map((history, index) => (
                        <tr key={index} className={classes.content}>
                          <td
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <Link
                              to={`/history/${history.date}`}
                              style={{
                                color: '#323030',
                              }}
                            >
                              {history.date.replace('-', '/').replace('-', '/')}
                            </Link>
                          </td>
                          <td className={classes.money}>
                            <p>{history.ct}</p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {' '}
                              <CurrencyFormat
                                value={history.amount}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                history.winning < 0 ? classes.negative : null
                              }`}
                            >
                              {' '}
                              <CurrencyFormat
                                value={history.winning}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {' '}
                              <CurrencyFormat
                                value={history.commission}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                history.winning + history.commission < 0
                                  ? classes.negative
                                  : null
                              }`}
                            >
                              {' '}
                              <CurrencyFormat
                                value={history.commission + history.winning}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                        </tr>
                      ))}
                      { history.content.length > 0 ? (
                        <tr
                          key={'total'}
                          className={classes.content}
                          style={{ borderTop: 'outset' }}
                        >
                          <td>
                            <center className={classes.font14}>
                              <b>
                                <span
                                  className={`lang-${localStorage.getItem(
                                    'language'
                                  )}`}
                                >
                                  {t('Total')}
                                </span>
                              </b>
                            </center>
                          </td>
                          <td className={`${classes.money} ${classes.font14}`}>
                            <p>{stakeTotal}</p>
                          </td>
                          <td className={`${classes.money} ${classes.font14}`}>
                            <p>
                              <CurrencyFormat
                                value={amountTotal}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                winningTotal < 0 ? classes.negative : null
                              } ${classes.font14}`}
                            >
                              {' '}
                              <CurrencyFormat
                                value={winningTotal}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td className={`${classes.money} ${classes.font14}`}>
                            <p>
                              {' '}
                              <CurrencyFormat
                                value={commissionTotal}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                netWinTotal < 0 ? classes.negative : null
                              } ${classes.font14}`}
                            >
                              {' '}
                              <CurrencyFormat
                                value={netWinTotal}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={6} align='center'>{t('No bet history')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default History;

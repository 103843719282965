import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import Navbar2 from "../../components/Navbar2.js";
import PublicLeftSide from "../../components/PublicLeftSide.js";
import axiosInstance, { publicAxiosInstance } from "../../config";
import {logout} from '../../components/Utils';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/Pages.css";

const REACT_APP_MOBILE_URL = process.env.REACT_APP_MOBILE_URL;
const REACT_APP_DESKTOP_URL = process.env.REACT_APP_DESKTOP_URL;
const REACT_APP_MOBILE_APK_URL = process.env.REACT_APP_MOBILE_APK_URL;

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
  pt0: {
    paddingTop: "0px !important",
  },
  paper: {
    padding: theme.spacing(9),
    textAlign: "center",
    backgroundColor: "#C4C4C4",
    color: theme.palette.text.secondary,
  },
  cardheader: {
    textAlign: "center",
    backgroundColor: "#FFF",
    padding: "12px 16px 4px 16px",
  },
  cardtitle: {
    fontSize: "16px",
    margin: 0,
    color: "#86898C",
    fontWeight: "bold",
  },
  card: {
    marginBottom: "16px",
    boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.08) !important",
  },
  tabContent: {
    padding: "16px",
    borderRadius: "0px 0px 4px 4px",
  },
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "90px auto auto !important",
    float: "none !important",
  },
  LeftPanel: {
    width: "25%",
  },
  RightPanel: {
    width: "25%",
  },
  CenterPanel: {
    width: "50%",
  },
  links: {
    textDecoration: "none",
    color: "black",
  },
  flex: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& img": {
      maxWidth: "30px",
      maxHeight: "30px",
    },
    marginBottom: "10px",
  },
  inputStyle: {
    margin: "0.5rem 0",
    width: "100%",
    border: "1px solid #86898C",
    borderRadius: "4px",
    height: "36px",
    "&::placeholder ": {
      color: "#86898C",
      fontWeight: 100,
    },
    fontSize: "12px",
    padding: "4px",
  },
  submitStyle: {
    margin: "1.25rem 0",
    backgroundColor: "#32A352",
    width: "100%",
    color: "#fff",
    borderRadius: "8px",
    border: "none",
    height: "36px",
    fontSize: "14px",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
  form: {
    borderBottom: "1px solid #86898C",
  },
  header: {
    fontSize: "16px",
    margin: "1.25rem 0",
    color: "#86898C",
    fontWeight: "bold",
    textAlign: "center",
  },
  blockImg: {
    display: "block",
    margin: "0 auto",
    maxWidth: "100%",
    maxHeight: "96px",
  },
}));

const Home = ({ User }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  let history = useHistory();

  const [loginLoading, setLoginLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [carousel, setCarousel] = useState([]);

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

  useEffect(() => {
    var fromMobile = false;
    const lang = getParameterByName('lang');
    if(lang) {
      localStorage.setItem('language', lang);
      i18n.changeLanguage(lang);
      fromMobile = true;
    }

    let token = getParameterByName('token');
    if (token && token.length > 0) {
      publicAxiosInstance.get('public/switch/validate?secret='+token)
        .then((res)=> {
          localStorage.setItem("token", res.data.jwttoken);
          localStorage.setItem("menu", "");
        })
        .catch((err) => console.log(err))
        .finally(() => {
          // Clear the parameter from mobile
          history.replace("/odds");
        })
    } else if(fromMobile) {
      history.replace("/odds");
    }
  }, []);  
  
  useEffect(() => {
    publicAxiosInstance.get('public/cms?mediaType=DESKTOP_PUBLIC_CAROUSEL')
      .then((res)=> {
        if(res.data && res.data.content && res.data.content.length > 0){
          setCarousel(res.data.content);
        } else {
          setCarousel(false);
        }
      })
      .catch((err) => console.log(err))
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginLoading === true) return;
    setLoginLoading(true);
    const login = document.querySelector("#login-form");
    const username = login["username"].value;
    const password = login["password"].value;
    const data = {
      username: username,
      password: password,
    };
    axiosInstance
      .post("authenticate", data)
      .then((res) => {
        // check pwd expiry
        if (res.data.role !== "PLAYER") {
          axiosInstance
            .post("/token/invalidate")
            .then((resp) => {
              //console.log(resp);
            })
            .catch((err) => console.log(err));
          return;
        } else {
          axiosInstance
            .get("portal/checkPwdExpiry")
            .then((response) => {
              // console.log(response);
              if (response.status !== 200) {
                history.push("/pwdexpiry");
              }
            })
            .catch((err) => {
              if (
                err.response.status === 403 &&
                err.response.data.message === "AP003"
              ) {
                history.push("/pwdexpiry");
              }
            });
          localStorage.setItem("token", res.data.jwttoken);
          localStorage.setItem("menu", "");
          history.push("/terms");
          setInterval(() => {
            axiosInstance
              .post("token/renew")
              .then((resp) => {
                localStorage.setItem("token", resp.data.jwttoken);
              })
              .catch((err) => console.log(err));
          }, 600000);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message === "AUTH005") {
          toast.dark("Your account is locked. Please contact your agent.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (err.response && err.response.status === 401) {
          toast.dark("Invalid credentials !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          logout(history);
        }
        console.log(err);
      })
      .finally(()=> {
        setLoginLoading(false);
      });
  };

  if (isLoading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40em",
        }}
      >
        <ClipLoader size={130} color={"#123abc"} loading={isLoading} />
      </div>
    );
  }

  return (
    <>
      <Navbar2 />
      <ToastContainer />
      <div id="background" />
      <div className={`${classes.MainArea2} Home`}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <PublicLeftSide />
          </Grid>
          <Grid item className={classes.CenterPanel}>
            <Grid container justify="center" alignItems="center">
              <Grid item className={classes.pt0} xs={12}>
                {carousel && carousel.length > 0? (
                  <Carousel 
                    onClickItem={(i) => {
                      if(carousel[i].hyperlinkUrl) {
                        window.open(carousel[i].hyperlinkUrl, '_blank');
                      }
                    }}
                  infiniteLoop={true} autoPlay={true} showStatus={false} showThumbs={false}>
                    {carousel.map((c, index) => (
                      <React.Fragment key={index}>
                            <div className={`${c.hyperlinkUrl?classes.pointer:''}`}>
                              <img
                                className={`centerImage`}
                                width="100%"
                                alt={c.name}
                                src={c.pictureUrl}
                              />
                            </div>
                      </React.Fragment>
                    ))}
                  </Carousel>
                ):(
                   
                    <div>
                      <img
                        className="centerImage"
                        width="100%"
                        alt="header"
                        src={require("../../img/header-4.png")}
                      />
                    </div>
                )}
               
                
              </Grid>
              {/* <Grid className="centerImageGrid" item xs={12} sm={12} md={6}>
                <img
                  className="centerImage"
                  width="100%"
                  alt="header"
                  src={require("../../img/image1.jpeg")}
                />
                <div className="centerImageText"><span className={`lang-${localStorage.getItem('language')}`}>{t("Fast Payout")}</span></div>
              </Grid>
              <Grid className="centerImageGrid" item xs={12} sm={12} md={6}>
                <img
                  className="centerImage"
                  width="100%"
                  alt="header"
                  src={require("../../img/image2.jpg")}
                />
                <div className="centerImageText"><span className={`lang-${localStorage.getItem('language')}`}>{t("Live Sports")}</span></div>
              </Grid> */}
              <Grid className="centerImageGrid" item xs={12} sm={12} md={6}>
                <div className="banner centerImage">
                  <div className="banner-title"><span className={`lang-${localStorage.getItem('language')}`}>{t("ANDROID")}</span></div>
                  <div className="banner-row">
                    <div>
                      <img
                        className="qrcode"
                        alt="header"
                        src={require("../../img/qrcode.png")}
                      />
                    </div>
                    <div>
                      <a href={REACT_APP_MOBILE_APK_URL}>
                        <img
                          alt="download"
                          src={require("../../img/android-logo.png")}
                          className={classes.blockImg}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="banner-footer">
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("SCAN CODE")}</span>
                    <span>
                      <a href={REACT_APP_MOBILE_APK_URL}>
                      <span className={`lang-${localStorage.getItem('language')}`}>{t("CLICK HERE TO DOWNLOAD")}</span>
                      </a>
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid className="centerImageGrid" item xs={12} sm={12} md={6}>
                <div className="banner centerImage">
                  <div className="banner-title"><span className={`lang-${localStorage.getItem('language')}`}>{t("OTHERS")}</span></div>
                  <div className="banner-row">
                    <div>
                      <img
                        className="qrcode"
                        alt="header"
                        src={require("../../img/qrcodemobile.jpg")}
                      />
                    </div>
                    <div>
                      <a
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          height: "100%",
                          width: "100%",
                        }}
                        href={`${REACT_APP_MOBILE_URL}?lang=${localStorage.getItem('language') || 'en'}`}
                      >
                        <img
                          alt="download"
                          src={require("../../img/ios-logo.svg")}
                          className={classes.blockImg}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="banner-footer">
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("SCAN CODE")}</span>
                    <span>
                      <a href={`${REACT_APP_MOBILE_URL}?lang=${localStorage.getItem('language') || 'en'}`}>
                      <span className={`lang-${localStorage.getItem('language')}`}>{t("CLICK HERE TO GO MOBILE SITE")}</span>
                      </a>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.RightPanel}>
            <Card className={classes.card}>
              <CardHeader
                className={classes.cardheader}
                title={
                  <Typography
                    className={classes.cardtitle}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Sign In")}</span>
                  </Typography>
                }
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent>
                {/* login Form */}
                <form
                  onSubmit={handleSubmit}
                  id="login-form"
                  className={classes.form}
                >
                  <input
                    type="text"
                    placeholder={t("Username")}
                    id="username"
                    className={classes.inputStyle}
                  />
                  <input
                    type="password"
                    placeholder={t("Password")}
                    id="password"
                    className={classes.inputStyle}
                  />
                  <div>
                    <input
                      type="submit"
                      value={t("Sign In")}
                      className={classes.submitStyle}
                    />
                  </div>
                </form>
                <Typography
                  className={`${classes.header}`}
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>{t("Suggested Browser")}</span>
                </Typography>
                <div className={classes.flex}>
                  <img src={require("../../img/chrome-logo.png")} alt="" />
                  <img src={require("../../img/firefox-logo.png")} alt="" />
                  <img src={require("../../img/safari-logo.png")} alt="" />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PureModal from "react-pure-modal";
import { useHistory } from "react-router-dom";
import axiosInstance from "../config";
import "../styles/LeagueFilter.css";
import qs from "qs";
import {logout} from './Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  successbutton: {
    width: "109px",
    height: "34px",
    color: "#fff",
    border: "none",
    marginRight: "5px",
    boxSizing: "border-box",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "6px",
    backgroundColor: "rgb(78, 173, 38)",
    "&:hover": {
      background: "rgb(78, 173, 38, 0.6)",
    },
  },
  cancelbutton: {
    width: "109px",
    height: "34px",
    color: "#fff",
    border: "none",
    boxSizing: "border-box",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "6px",
    backgroundColor: "rgb(237, 146, 151)",
    "&:hover": {
      background: "rgb(237, 146, 151, 0.6)",
    },
  },
}));

const LeagueFilter = ({
  openLeagueFilter,
  setOpenLeagueFilter,
  trigger,
  market,
  game,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [league, setLeague] = useState([]);
  const history = useHistory();
  window.HTMLElement.prototype.scrollIntoView = function () {};

  const changeAll = (a) => {
    for (var i = 0; i < league.length; i++) {
      document.getElementById(league[i].key).checked = a;
    }
  };

  const filterLeague = () => {
    setOpenLeagueFilter(false);
    // let selectedLeagues = league.filter((l) => !l.active);
    let url = "restricted/leagues";
    let compIds = '';
    for (var i = 0; i < league.length; i++) {
      if (!document.getElementById(league[i].key).checked){
        compIds += league[i].key+",";
      }
    }
    // if (selectedLeagues.length > 0) {
    //   selectedLeagues.forEach((l) => {
    //     compIds += l.key+",";
    //   });
    // }
    let requestBody = {
      market: market,
      compIds: compIds.length > 0 ? compIds.slice(0,-1) : '',
    };
    return axiosInstance
      .post(url, qs.stringify(requestBody))
      .then(() => {
        trigger();
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };

  useEffect(() => {
    if (openLeagueFilter) {
      var url = "restricted/leagues?market=" + market;
      if (game === "Basketball") {
        url += "&s=2";
      }

      axiosInstance
        .get(url)
        .then((res) => {
          setLeague(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    }
    else {
      setLeague([]);
    }
  }, [market, game, history, openLeagueFilter]);

  return (
    <PureModal
      header={
        <div className="header">
          <div><span className={`lang-${localStorage.getItem('language')}`}>{t("Select League")}</span></div>
          <div className="header-select">
            <span onClick={() => changeAll(true)} className="actionspan">
              <span className="checkbox-checked"></span>
              <span className={`lang-${localStorage.getItem('language')}`}>{t("Select All")}</span>
            </span>
            <span onClick={() => changeAll(false)} className="actionspan">
              <span className="checkbox-unchecked"></span>
              <span className={`lang-${localStorage.getItem('language')}`}>{t("Deselect All")}</span>
            </span>
          </div>
        </div>
      }
      footer={
        <div className="center">
          <button
            onClick={() => filterLeague()}
            className={classes.successbutton}
          >
            <span className={`lang-${localStorage.getItem('language')}`}>{t("Done")}</span>
          </button>
          <button
            onClick={() => setOpenLeagueFilter(false)}
            className={classes.cancelbutton}
          >
            <span className={`lang-${localStorage.getItem('language')}`}>{t("Cancel")}</span>
          </button>
        </div>
      }
      isOpen={openLeagueFilter}
      onClose={() => {
        setOpenLeagueFilter(false);
        return true;
      }}
    >
      <div className={classes.root}>
        <Grid container spacing={3}>
          {league.map((option, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <input
                  type="checkbox"
                  defaultChecked={option.active}
                  value={option.key}
                  id={option.key}
                />{" "}
                <label className="pointer" htmlFor={option.key}>
                  {option.val}
                </label>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </PureModal>
  );
};

export default LeagueFilter;

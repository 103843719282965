import Axios from 'axios';
import {logout} from './components/Utils';

const baseURL = process.env.REACT_APP_API_URL;
const axiosInstance2 = Axios.create({
  baseURL: baseURL,
});
axiosInstance2.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Add a response interceptor
axiosInstance2.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout(false);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance2;

import React, { useState } from "react";
import LeftSide from "../components/LeftSide";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";

import Navbar from "../components/Navbar";
import RightSide from "../components/RightSide";
import TickerNews from "../components/TickerNews";

const useStyles = makeStyles(theme => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important"
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important"
  },
  LeftPanel: {
    width: "20%"
  },
  RightPanel: {
    width: "80%",
  }
}));

const Odds = ({ User }) => {
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  //   parley quote
  const [ParlayInfo, setParlayInfo] = useState({});
  // state to activate bet setting screen on click
  const [isBetParlay, setBetParlay] = useState(false);
  // closing the bet success after quote clicked
  const [betSuccess, setbetSuccess] = useState(false);
  const [isParlayTable] = useState(false);
  // to display odd error
  const [oddError, setOddError] = useState(false);
  // to display odd is updating
  const [oddUpdating, setOddUpdating] = useState(false);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide
              User={User}
              quote={quote}
              setQuote={setQuote}
              setbetSuccess={setbetSuccess}
              betSuccess={betSuccess}
              oddError={oddError}
              setOddError={setOddError}
              oddUpdating={oddUpdating}
              setOddUpdating={setOddUpdating}
            />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <RightSide
              User={User}
              setQuote={setQuote}
              ParlayInfo={ParlayInfo}
              setParlayInfo={setParlayInfo}
              isBetParlay={isBetParlay}
              setBetParlay={setBetParlay}
              setbetSuccess={setbetSuccess}
              betSuccess={betSuccess}
              isParlayTable={isParlayTable}
              setOddError={setOddError}
              setOddUpdating={setOddUpdating}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Odds;

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import '../styles/Common.css';

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#32A352",
    color: "#fff",
    borderRadius: "8px",
    border: "none",
    height: "24px",
    fontSize: "12px",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    color: "#E91F1F",
  },
}));
const Password = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [error, setError] = useState([]);
  //   single quote
  const [quote, setQuote] = useState(null);
  //  const error messages
  // showing messsage
  const [success, setSuccess] = useState(false);
  // showing error
  const [issue, setIssue] = useState(false);

  // validating the form
  const validate = (newPass, confirmPass) => {
    let err = []
    if (newPass !== confirmPass) {
      err.push("Password does not match");
    }
    if (newPass.length < 5) {
      err.push("Min length should be 5");
    }
    return err;
  };

  const handlePass = (e) => {
    e.preventDefault();
    setError([]);
    const passForm = document.querySelector("#pass-form");
    const currentPass = passForm["current-pass"].value;
    const confirmPass = passForm["confirm-pass"].value;
    const newPass = passForm["new-pass"].value;

    const err = validate(newPass, confirmPass);
    setError(err);
    if (err.length === 0) {
      axiosInstance
        .post("portal/password", null, {
          params: {
            current: currentPass,
            newpwd: confirmPass,
          },
        })
        .then(() => {
          setSuccess(true);
          setIssue(false);
        })
        .catch(() => {
          setError(["Invalid current password"]);
          setIssue(true);
        });
    } else {
      setIssue(true);
    }
  };
  return (
    <>
      <Navbar User={User.user} />
      <div className={'Common-MainArea'}>
        <TickerNews />
      </div>
      <div className={'Common-MainArea2'}>
        <Grid container spacing={2}>
          <Grid item className={'Common-LeftPanel'}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={'Common-RightPanel'}>
            <div className="Common">
              <div className={'Common-flex'}>
                <div className={'Common-flex2'}>
                  <img
                    src={require("../img/Icons/key-1.svg")}
                    alt=""
                    className={'Common-img'}
                  />
                  <span
                    className={`lang-${localStorage.getItem('language')} Common-header`}
                  >
                    {t('Change Password')}
                  </span>
                </div>
              </div>
              <form action="" onSubmit={handlePass} id="pass-form">
                <table className={'Common-table'}>
                  <thead>
                    <th style={{ borderRadius: '4px 4px 0 0' }} colSpan={2}>
                      <span
                        className={`lang-${localStorage.getItem(
                          'language'
                        )}`}
                      >
                        {t('Change Password')}
                      </span>
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'right', }} width="50%">
                        <label htmlFor="current-pass">
                          <span className={`lang-${localStorage.getItem('language')}`}>{t("Current Password")}</span>
                        </label>
                      </td>
                      <td style={{ textAlign: 'left', }}>
                        <input
                          style={{ width: '200px', }}
                          type="password"
                          id="current-pass"
                          className={classes.input}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right', }} width="50%">
                        <label htmlFor="new-pass">
                          <span className={`lang-${localStorage.getItem('language')}`}>{t("New Password")}</span>
                        </label>
                      </td>
                      <td style={{ textAlign: 'left', }}>
                        <input
                          style={{ width: '200px', }}
                          type="password"
                          id="new-pass"
                          className={classes.input}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'right', }} width="50%">
                        <label htmlFor="confirm-pass">
                          <span className={`lang-${localStorage.getItem('language')}`}>{t("Confirm New Password")}</span>
                        </label>
                      </td>
                      <td style={{ textAlign: 'left', }}>
                        <input
                          style={{ width: '200px', }}
                          type="password"
                          id="confirm-pass"
                          className={classes.input}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: 'left', }}>
                        <input
                          type="submit"
                          value={t("Change Password")}
                          className={classes.btn}
                        />
                      </td>
                    </tr>
                    {success === true ? (
                      <tr>
                        <td colSpan={2} align="center">
                          <p style={{ color: "#4BB543 " }}>
                            <span className={`lang-${localStorage.getItem('language')}`}>{t("Your password has successfully changed")}</span>{" "}
                          </p>
                        </td>
                      </tr>
                    ) : null}
                    {issue === true ? (
                      <tr>
                        <td></td>
                        <td align="left">
                          <p>
                              {error.map((err) => (
                                <span className={`${classes.error} lang-${localStorage.getItem('language')}`}>
                                  {err}<br/>
                                </span>
                              ))}
                            {" "}
                          </p>
                        </td>
                      </tr>
                    ) : null}                    
                  </tbody>
                </table>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Password;

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  index: {
    fontSize: '10px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  teams: {
    fontSize: '13px',
    color: '#323030',
    margin: '0.15rem 0 !important',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  compName: {
    color: '#0E2336',
    fontSize: '13px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  spacing: {
    padding: '0.35rem 0.5rem',
  },
  strong: {
    color: '#DC252F',
  },
  score: {
    fontSize: '13px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  blank: {
    backgroundColor: '#86898C',
  },
  none: {
    display: 'none',
  },
  reject: {
    textDecoration: 'line-through',
  },
}));

const ViewDetailModal = ({ data, open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {data !== null ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modalStyle}
          // style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {/* <div className={classes.Modal}> */}
          <div className="Wager-modal">
            <table>
              <thead>
                <tr>
                  <th>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('No.')}
                    </span>
                  </th>
                  <th>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Bet type')}
                    </span>
                  </th>
                  <th>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Details')}
                    </span>
                  </th>
                  <th style={{ padding: '0' }}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('HT')}
                    </span>
                  </th>
                  <th style={{ padding: '0' }}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('FT')}
                    </span>
                  </th>
                  <th>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Win')}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.parlayDetails.map((match, i) => (
                  <React.Fragment key={i}>
                    {match.map((detail, index) => (
                      <tr key={index}>
                        <td>
                          <p className={classes.index}>{index + 1}</p>
                        </td>
                        <td>
                          <div className={classes.spacing}>
                            <p className={classes.teams}>
                              {detail.sport} {detail.market}
                            </p>
                            <p className={classes.teams}>{detail.game}</p>
                            <p className={classes.teams}>{detail.reference}</p>
                          </div>
                        </td>
                        <td>
                          <div className={classes.spacing}>
                            <p
                              className={`${classes.compName} ${
                                detail.status === 'REJECTED' ||
                                detail.status === 'CANCELLED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              {detail.comp} {detail.compType}
                            </p>
                            <p
                              className={`${classes.teams} ${
                                detail.status === 'REJECTED' ||
                                detail.status === 'CANCELLED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              <span
                                className={
                                  detail.high === 1 ? classes.strong : null
                                }
                              >
                                {detail.home}
                              </span>{' '}
                              vs{' '}
                              <span
                                className={
                                  detail.high === 2 ? classes.strong : null
                                }
                              >
                                {detail.away}
                              </span>
                            </p>
                            {detail.market === 'LIVE' ? (
                              <p className={classes.running}>
                                (Running {detail.homeGoal}-{detail.awayGoal})
                              </p>
                            ) : null}
                            <p
                              className={`${classes.compName} ${
                                detail.status === 'REJECTED' ||
                                detail.status === 'CANCELLED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              {detail.desc}
                              {detail.handicap !== '' ? (
                                <span className={classes.handiCap}>
                                  {' '}
                                  ({detail.handicap})
                                </span>
                              ) : null}
                              <span>
                                {' '}
                                @ {detail.odds.toFixed(2)}
                                {detail.status === 'PENDING' ? (
                                  <span className={classes.running}>
                                    {' '}
                                    ({detail.status})
                                  </span>
                                ) : null}
                              </span>
                            </p>
                            {detail.status === 'REJECTED' ||
                            detail.status === 'CANCELLED' ? (
                              <p className={classes.running}>
                                <span
                                  className={`lang-${localStorage.getItem(
                                    'language'
                                  )}`}
                                >
                                  {t('Reject')}
                                </span>{' '}
                                {detail.rejectReason
                                  ? '(' + detail.rejectReason + ')'
                                  : ''}
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td style={{ padding: '0', minWidth: '30px' }}>
                          <p className={classes.score}>
                            {detail.fhHome}-{detail.fhAway}
                          </p>
                        </td>
                        <td style={{ padding: '0', minWidth: '30px' }}>
                          <p className={classes.score}>
                            {detail.ftHome}-{detail.ftAway}
                          </p>
                        </td>
                        <td>
                          <p className={classes.result}>
                            {detail.result.charAt(0).toUpperCase() +
                              detail.result
                                .slice(1)
                                .toLowerCase()
                                .replace('_', ' ')}
                          </p>
                        </td>
                      </tr>
                    ))}
                    {match.length % 2 === 0 ? (
                      <>
                        <tr>
                          <td colSpan={6} className={classes.blank}></td>
                        </tr>
                        <tr className={classes.none}>
                          <td colSpan={6} className={classes.blank}></td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className={classes.none}>
                          <td colSpan={6} className={classes.blank}></td>
                        </tr>
                        <tr>
                          <td colSpan={6} className={classes.blank}></td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ViewDetailModal;

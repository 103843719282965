import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Navbar3 from "../components/Navbar3";
import axiosInstance from "../config";
import axiosInstance2 from "../config2";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "78px auto auto !important",
    float: "none !important",
  },
  flex: {
    display: "flex",

    alignItems: "center",
  },
  img: {
    height: "14px",
    width: "14px",
    margin: "0 0.35rem",
    filter: "invert(1)",
  },
  header: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
  },
  pass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "500px",
    margin: "1rem 0",
  },
  Label: {
    fontSize: "14px",
    fontWeight: "500",
    color: " #323030",
  },
  input: {
    border: "1px solid #323030",
  },
  changePass: {
    backgroundColor: "transparent",
    color: "#86898C",
    borderRadius: "6px",
    border: "1px solid #86898C",
    padding: "0.25rem 0.75rem",
    cursor: "pointer",
    fontWeight: "bold",
  },
  confirm: {
    backgroundColor: "transparent",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "3px",
    border: "1px solid #fff",
    padding: "0.15rem 0.75rem",
    cursor: "pointer",
    fontWeight: "normal",
    fontSize: "0.85rem",
  },
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 10px 20px 5px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px",
    margin: "0.35rem 0",
    marginTop: "0",
    minWidth: "600px",
  },
  cardUpper: {
    backgroundColor: "#86898C",
    padding: "0.0rem 0.75rem",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },
  cardLower: {
    padding: "0.15rem 0.75rem",
    borderRadius: "0 0 4px 4px ",
  },
  show: {
    display: "block",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "13px",
  },
  none: {
    display: "none",
  },

  error: {
    color: "#FF9494",
  },
  Modal: {
    margin: "0 auto",
    width: "800px",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflowY: "scroll",
    height: "80%",
    display: "block",
    margin: "auto",
  },
}));
const PwdExpiry = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  //  error messages
  var error = [];
  const [finalError, setFinalError] = useState([]);
  // showing messsage
  const [success, setSuccess] = useState(false);
  // showing error
  const [issue, setIssue] = useState(false);

  // validating the form
  const validate = (newPass, confirmPass) => {
    var letter = /[a-zA-Z]/;
    var number = /[0-9]/;
    const result = letter.test(newPass) && number.test(newPass);
    //console.log(result);

    if (!result) {
      error.push("Password should be Alphanumeric");
    }
    if (newPass !== confirmPass) {
      error.push("Password does not match");
    }
    if (newPass.length < 5) {
      error.push("min length should be 5");
    }
    if (error.length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    axiosInstance2
      .get("portal/checkPwdExpiry")
      .then((res) => {
        if (res.status === 200) {
          history.push("/odds");
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          //console.log('ok');
        }
      });
  }, [history]);

  const handlePass = (e) => {
    e.preventDefault();
    const passForm = document.querySelector("#pass-form");
    const currentPass = passForm["current-pass"].value;
    const confirmPass = passForm["confirm-pass"].value;
    const newPass = passForm["new-pass"].value;

    const isValidate = validate(newPass, confirmPass);

    if (isValidate === true) {
      axiosInstance
        .post("portal/password", null, {
          params: {
            current: currentPass,
            newpwd: confirmPass,
          },
        })
        .then((res) => {
          //console.log(res);
          setSuccess(true);
          setIssue(false);
          window.location.href = "/odds";
        })
        .catch((err) => {
          setIssue(true);
          error.push("Change password failed");
          console.log(error);
          console.log(err);
          setFinalError(error);
          error = [];
        });
    } else {
      setIssue(true);
      //console.log('validation failed');
      console.log(error);
      setFinalError(error);
      error = [];
    }
  };

  return (
    <>
      <Navbar3 />
      <div className={classes.MainArea}>
        <h4 className={classes.info}>
          <span className={`lang-${localStorage.getItem('language')}`}>{t(
            "Your password has expired or has been reset, please change your password to continue"
          )}</span>
        </h4>
        <div className={classes.card}>
          <div className={classes.cardUpper}>
            <div className={classes.flex}>
              <img
                src={require("../img/Icons/key-1.svg")}
                alt=""
                className={classes.img}
              />
              <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Change password")}</span></p>
            </div>
          </div>
          <div className={classes.cardLower}>
            {/* form for changing pass */}
            <div className={success === true ? classes.show : classes.none}>
              {success === true ? (
                <p style={{ color: "#4BB543 " }}>
                  <span className={`lang-${localStorage.getItem('language')}`}>{t("your password is successfully changed")}</span>{" "}
                </p>
              ) : null}
            </div>
            <div className={issue === true ? classes.show : classes.none}>
              {issue === true ? (
                <>
                  {finalError.map((err, index) => (
                    <p key={index} className={classes.error}>
                      {err}
                    </p>
                  ))}
                </>
              ) : null}
            </div>
            <form action="" onSubmit={handlePass} id="pass-form">
              <div className={classes.pass}>
                <label htmlFor="current-pass" className={classes.Label}>
                <span className={`lang-${localStorage.getItem('language')}`}>{t("Current Password")}</span>
                </label>
                <input
                  type="password"
                  id="current-pass"
                  className={classes.input}
                  required
                />
              </div>
              <div className={classes.pass}>
                <label htmlFor="new-pass" className={classes.Label}>
                <span className={`lang-${localStorage.getItem('language')}`}>{t("New Password")}</span>
                </label>
                <input
                  type="password"
                  id="new-pass"
                  className={classes.input}
                  required
                />
              </div>
              <div className={classes.pass}>
                <label htmlFor="confirm-pass" className={classes.Label}>
                <span className={`lang-${localStorage.getItem('language')}`}>{t("Confirm New Password")}</span>
                </label>
                <input
                  type="password"
                  id="confirm-pass"
                  className={classes.input}
                  required
                />
              </div>
              <div
                className={classes.pass}
                style={{ justifyContent: "flex-end" }}
              >
                <input
                  type="submit"
                  value={t("Change password")}
                  className={classes.changePass}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PwdExpiry;

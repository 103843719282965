import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Navbar2 from "../../components/Navbar2.js";
import RightSide from "../../components/RightSide";
import Paper from "@material-ui/core/Paper";
import PublicLeftSide from "../../components/PublicLeftSide.js";
import "../../styles/Pages.css";

const useStyles = makeStyles(theme => ({
  pt0: {
    paddingTop: "0px !important"
  },
  paper: {
    padding: theme.spacing(9),
    textAlign: "center",
    backgroundColor: "#C4C4C4",
    color: theme.palette.text.secondary
  },
  cardheader: {
    textAlign: "center",
    backgroundColor: "#FFF",
    padding: "12px 16px 4px 16px"
  },
  cardtitle: {
    fontSize: "16px",
    margin: 0,
    color: "#86898C",
    fontWeight: "bold"
  },
  card: {
    marginBottom: "16px",
    boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.08) !important"
  },
  tabContent: {
    padding: "15px"
  },
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important"
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "90px auto auto !important",
    float: "none !important"
  },
  LeftPanel: {
    width: "25%"
  },
  RightPanel: {
    width: "75%",
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper className={classes.tabContent} elevation={0}>
          {children}
        </Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const OddsPage = ({ User }) => {
  const classes = useStyles();

  //   single quote
  const [quote, setQuote] = useState(null);
  //   parley quote
  const [ParlayInfo, setParlayInfo] = useState({});
  // state to activate bet setting screen on click
  const [isBetParlay, setBetParlay] = useState(false);
  // closing the bet success after quote clicked
  const [betSuccess, setbetSuccess] = useState(false);
  const [isParlayTable] = useState(false);
  // to display odd error
  const [oddError, setOddError] = useState(false);
  // to display odd is updating
  const [oddUpdating, setOddUpdating] = useState(false);

  return (
    <>
      <Navbar2 showLogin="yes" />
      <div className={`${classes.MainArea2} Public`}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <PublicLeftSide />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <RightSide
              User={User}
              setQuote={setQuote}
              ParlayInfo={ParlayInfo}
              setParlayInfo={setParlayInfo}
              isBetParlay={isBetParlay}
              setBetParlay={setBetParlay}
              setbetSuccess={setbetSuccess}
              betSuccess={betSuccess}
              isParlayTable={isParlayTable}
              setOddError={setOddError}
              setOddUpdating={setOddUpdating}
              showToday={true}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OddsPage;

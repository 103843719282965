// import Modal from '@material-ui/core/Modal';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
// import { Link, useHistory } from 'react-router-dom';
// import moment from 'moment';
import "../styles/HistoryDetail.css";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  table: {
    width: "100%",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px rgb(81 81 81 / 40%)",
    borderRadius: "4px",
    margin: "1rem 0",
    marginTop: "0",
    "& p": {
      margin: "0 !important",
    },
  },
  cardUpper: {
    backgroundColor: "#86898C",
    padding: "0.85rem 0.75rem",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardLower: {
    padding: "0.75rem ",
    borderRadius: "0 0 4px 4px ",
  },
  heading: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    maxHeight: "18px",
  },
  img: {
    height: "20px",
    width: "20px",
    marginRight: "0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
  },
  pinBtn: {
    cursor: "pointer",
  },
}));

const Withdraw = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  // otp
  const [otp, setOtp] = useState(null);
  const history = useHistory();

  const handlePin = () => {
    axiosInstance
      .post("portal/otp")
      .then((res) => {
        //console.log(res.data);
        setOtp(res.data.otp);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className={classes.flex}>
              <img
                src={require("../img/Icons/banknote-15.svg")}
                alt=""
                className={classes.img}
              />
              <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Withdraw")}</span></p>
            </div>
            <div className={classes.card}>
              <div className={classes.cardUpper}>
                <p className={classes.heading}><span className={`lang-${localStorage.getItem('language')}`}>{t("Generate Cash Out PIN")}</span></p>
              </div>
              <div className={classes.cardLower}>
                <p
                  className={classes.header}
                  style={{ textAlign: "center", paddingBottom: "0.5rem" }}
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                  {t("generate pin expire")}
                  <strong>{t("10 minutes")}</strong>
                  </span>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  <button className={classes.pinBtn} onClick={handlePin}>
                  <span className={`lang-${localStorage.getItem('language')}`}>{t("Generate PIN")}</span>
                  </button>
                </div>
                {otp !== null ? (
                  <>
                    <p
                      className={classes.header}
                      style={{ textAlign: "center", padding: "0.5rem" }}
                    >
                      <span className={`lang-${localStorage.getItem('language')}`}>{t("Your PIN is_")}</span>
                      <strong>{otp}</strong>.
                    </p>
                    <p
                      className={classes.header}
                      style={{ textAlign: "center", padding: "0.5rem" }}
                    >
                      <span className={`lang-${localStorage.getItem('language')}`}>{t(
                        "Please cash out immediately as your PIN is effective for 10 minutes only."
                      )}</span>
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Withdraw;

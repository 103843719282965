import React, { useState } from 'react';
import LeftSide from '../components/LeftSide';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Navbar from '../components/Navbar';
import TickerNews from '../components/TickerNews';
import ResultsRightSide from '../components/ResultsRightSide';
import '../styles/Results.css';
const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '86px auto auto !important',
    float: 'none !important',
  },
  MainArea2: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '10px auto auto !important',
    float: 'none !important',
  },
  LeftPanel: {
    width: '20%',
  },
  RightPanel: {
    width: '80%',
    marginTop: "10px"
  },
  compName: {
    lineHeight: '16px',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#323030',
    backgroundColor: '#DEDEDE',
    borderBottom: '1px solid #86898C',
    borderTop: '1px solid #86898C',
    textAlign: 'center',
  },
  date: {
    color: '#18334C',
  },
  fh: {
    textAlign: 'center',
    color: '#18334C',
  },
  ft: {
    textAlign: 'center',
    color: '#18334C',
  },
  more: {
    textAlign: 'center',
    color: '#18334C',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    margin: '0.75rem 0',
  },
  header: {
    color: '#18334C',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '0 ',
    marginRight: '0.85rem !important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    height: '16px',
    width: '16px',
    margin: '0 0.5rem',
  },
  btn: {
    margin: '0 0.35rem',
    cursor: 'pointer',
  },
  datePicker: {
    maxWidth: '90px',
    cursor: 'pointer',
  },
  submit: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
}));

const Results = ({ User }) => {
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);


return (
    <>
      <Navbar User={User.user} />
        <div className={classes.MainArea}>
            <TickerNews />
        </div>
        <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <ResultsRightSide/>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Results;

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './i18n';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   autoSessionTracking: true,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.1,
//   tracesSampler: samplingContext => {
//     const logPages = [
//       '/oddspage',
//       '/odds',
//       '/parley',
//     ];
//     if(logPages.includes(samplingContext.transactionContext.name)) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// });

// Sentry.captureException(new Error('This is my fake error message'));

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

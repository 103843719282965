import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import LeftSide from '../components/LeftSide';
import Navbar from '../components/Navbar';
import TickerNews from '../components/TickerNews';
import ViewDetailModal from '../components/ViewDetailModal';
import axiosInstance from '../config';
import { logout } from '../components/Utils';
import '../styles/HistoryDetail.css';

const useStyles = makeStyles((theme) => ({
  none: {
    display: 'none',
  },
  reject: {
    textDecoration: 'line-through',
  },
  strong: {
    color: '#323030',
  },
  MainArea: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '86px auto auto !important',
    float: 'none !important',
  },
  MainArea2: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '10px auto auto !important',
    float: 'none !important',
  },
  teams: {
    fontSize: '12px',
    color: '#323030',
    margin: '0.15rem 0 !important',
  },
  compName: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#323030',
  },
  spacing: {
    padding: '0.35rem 0.5rem',
  },
  table: {
    width: '100%',
  },
  index: {
    fontSize: '12px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  score: {
    fontSize: '12px',
    color: '#323030',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  result: {
    fontSize: '12px',
    color: '#323030',
    textAlign: 'center',
  },
  money: {
    fontSize: '12px',
    color: '#1452CC',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: '18px',
    width: '18px',
    margin: '0 0.5rem',
  },
  header: {
    color: '#18334C',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  negative: {
    color: '#E91F1F',
  },
  blue: {
    color: '#1452CC',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  view: {
    backgroundColor: '#fff',
    color: '#86898C',
    lineHeight: '12px',
    fontWeight: 'bold',
    fontSize: '12px',
    border: '1px solid #86898C',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0.3rem',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0.15rem 0rem !important',
  },
  print: {
    color: '#1452CC ',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0.15rem 0.35rem !important',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
    margin: '0.15rem 0 !important',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blank: {
    backgroundColor: '#86898C',
  },
  LeftPanel: {
    width: '20%',
  },
  RightPanel: {
    width: '80%',
    marginTop: '10px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
  font14: {
    fontSize: '14px',
  },
}));
const HistoryDetail = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  //   single quote
  const [quote, setQuote] = useState(null);
  const printRef = useRef();
  const [totalPage, setTotalPage] = useState(null);

  const [stakeTotal, setStakeTotal] = useState(0);
  const [winningTotal, setWinningTotal] = useState(0);
  const [commissionTotal, setCommissionTotal] = useState(0);
  const [netWinTotal, setNetWinTotal] = useState(0);

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const calculateTotal = (content) => {
    if (content !== undefined && content !== null && content.length > 0) {
      let stakeTotalTemp = 0;
      let winningTotalTemp = 0;
      let commissionTotalTemp = 0;
      let netWinTotalTemp = 0;

      content.forEach((data) => {
        if (data.amount !== undefined) stakeTotalTemp += data.amount;

        if (data.winning !== undefined) winningTotalTemp += data.winning;

        if (data.commission !== undefined)
          commissionTotalTemp += data.commission;

        if (data.commission !== undefined && data.winning !== undefined)
          netWinTotalTemp += data.commission + data.winning;
      });
      setStakeTotal(stakeTotalTemp);
      setWinningTotal(winningTotalTemp);
      setCommissionTotal(commissionTotalTemp);
      setNetWinTotal(netWinTotalTemp);
    }
  };

  // render the details
  useEffect(() => {
    axiosInstance
      .get(`portal/detail?date=${props.match.params.date}`)
      .then((res) => {
        //console.log(res.data.content);
        setDetail(res.data.content);
        setTotalPage(res.data.totalPages);
        calculateTotal(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 412) {
          history.push('/history');
        } else if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [props.match.params.date, history]);

  const handleChange = (event, value) => {
    //console.log(value);
    axiosInstance
      .get(`portal/detail?date=${props.match.params.date}`, {
        params: {
          pageNo: value - 1,
        },
      })
      .then((res) => {
        //console.log(res.data.content);
        setDetail(res.data.content);
        setTotalPage(res.data.totalPages);
        calculateTotal(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          logout(history);
        }
      });
  };

  // modal handling
  const handleView = (betId) => {
    // const betId = e.target.id;
    axiosInstance
      .get(`portal/mix?id=${betId}`)
      .then((res) => {
        setData(res.data);
        //console.log(res.data);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Navbar User={props.User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={props.User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="HistoryDetail">
              <div className={classes.flex}>
                <div className={classes.flex2}>
                  <img
                    src={require('../img/Icons/time-17.svg')}
                    alt=""
                    className={classes.img}
                  />
                  <p className={classes.header}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('History Details')}
                    </span>
                  </p>
                  <Link to="#" className={classes.print} onClick={handlePrint}>
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Print')}
                    </span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/history"
                    style={{ color: '#18334C', margin: '0 1rem' }}
                  >
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Back To History')}
                    </span>
                  </Link>
                </div>
              </div>

              {detail !== null ? (
                <>
                  <table ref={printRef} style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: '4px 0 0 0' }}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('No.')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Date & Time')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Bet Type')}
                          </span>
                        </th>

                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Details')}
                          </span>
                        </th>
                        <th style={{ padding: '0' }}>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('FT/HT')}
                          </span>
                        </th>
                        {/* <th style={{ padding: "0" }}></th> */}
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Stake')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Winning')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Commission')}
                          </span>
                        </th>
                        <th>
                          <span
                            className={`lang-${localStorage.getItem(
                              'language'
                            )}`}
                          >
                            {t('Net Win')}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.map((info, index) => (
                        <tr key={index}>
                          <td>
                            <p className={classes.index}>{index + 1}</p>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <p style={{ margin: '0.15rem 0' }}>
                              {moment(info.created).format('DD/MM/YY')}
                            </p>
                            <p>{moment(info.created).format('hh:mm a')}</p>
                          </td>
                          <td>
                            {info.market === 'PARLAY' ? (
                              <div className={classes.spacing}>
                                <p className={classes.teams}>{info.type}</p>
                                <p className={classes.teams}>
                                  {info.reference}
                                </p>
                              </div>
                            ) : (
                              <div className={classes.spacing}>
                                <p className={classes.teams}>
                                  {info.sport} {info.market}
                                </p>
                                <p className={classes.teams}>{info.game}</p>
                                <p className={classes.teams}>
                                  {info.reference}
                                </p>
                              </div>
                            )}
                          </td>

                          <td>
                            {info.market === 'PARLAY' ? (
                              <div
                                className={classes.flex}
                                style={{
                                  justifyContent: 'space-between',
                                  padding: '0 0.5rem',
                                }}
                              >
                                <p className={classes.compName}>
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('Mix Parlay_')}
                                  </span>{' '}
                                  <CurrencyFormat
                                    value={info.odds}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </p>
                                <button
                                  className={classes.view}
                                  onClick={() => {
                                    handleView(info.bet);
                                  }}
                                >
                                  <span
                                    className={`lang-${localStorage.getItem(
                                      'language'
                                    )}`}
                                  >
                                    {t('View Detail')}
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <div className={classes.spacing}>
                                <p
                                  className={`${classes.compName} ${
                                    info.status === 'REJECTED' ||
                                    info.status === 'CANCELLED'
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  {info.comp} {info.compType}
                                </p>
                                <p
                                  className={`${classes.teams} ${
                                    info.status === 'REJECTED' ||
                                    info.status === 'CANCELLED'
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  <span
                                    className={
                                      info.high === 1 ? classes.strong : null
                                    }
                                  >
                                    {info.home} vs{' '}
                                  </span>
                                  <span
                                    className={
                                      info.high === 2 ? classes.strong : null
                                    }
                                  >
                                    {info.away}
                                  </span>
                                </p>
                                {info.market === 'LIVE' ? (
                                  <p className={classes.running}>
                                    (Running {info.homeGoal}-{info.awayGoal})
                                  </p>
                                ) : null}
                                <p
                                  className={`${classes.handiCap} ${
                                    info.status === 'REJECTED' ||
                                    info.status === 'CANCELLED'
                                      ? classes.reject
                                      : ''
                                  }`}
                                >
                                  <span className={classes.compName}>
                                    {info.desc}{' '}
                                    {info.handicap !== '' ? (
                                      <span className={classes.handiCap}>
                                        ({info.handicap}){' '}
                                      </span>
                                    ) : null}
                                    <span>
                                      @{' '}
                                      <CurrencyFormat
                                        value={info.odds}
                                        decimalScale={2}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                      />
                                    </span>
                                  </span>
                                </p>
                                {info.status === 'REJECTED' ||
                                info.status === 'CANCELLED' ? (
                                  // <div className={classes.flex}>
                                  <p className={classes.running}>
                                    <span
                                      className={`lang-${localStorage.getItem(
                                        'language'
                                      )}`}
                                    >
                                      {t('Reject')}
                                    </span>{' '}
                                    {info.rejectReason
                                      ? '(' + info.rejectReason + ')'
                                      : ''}
                                  </p>
                                ) : // </div>
                                null}
                              </div>
                            )}
                          </td>
                          <td style={{ padding: '0', minWidth: '30px' }}>
                            <p className={classes.score}>
                              {info.ftHome}-{info.ftAway}
                              <br />({info.fhHome}-{info.fhAway})
                            </p>
                          </td>
                          {/* <td style={{ padding: "0", minWidth: "30px" }}>
                            <p className={classes.score}>
                              {info.ftHome}-{info.ftAway}
                            </p>
                          </td> */}
                          <td>
                            <p
                              className={`${classes.money} ${
                                info.amount < 0 ? classes.negative : null
                              }`}
                            >
                              <CurrencyFormat
                                value={info.amount}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                info.winning < 0 ? classes.negative : null
                              }`}
                            >
                              <CurrencyFormat
                                value={info.winning}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                info.commission < 0 ? classes.negative : null
                              }`}
                            >
                              <CurrencyFormat
                                value={info.commission}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                info.winning + info.commission < 0
                                  ? classes.negative
                                  : null
                              }`}
                            >
                              <CurrencyFormat
                                value={info.winning + info.commission}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                        </tr>
                      ))}
                      <tr key={'total'} style={{ borderTop: 'outset' }}>
                        <td colSpan={5}>
                          <center className={classes.font14}>
                            <b>
                              <span
                                className={`lang-${localStorage.getItem(
                                  'language'
                                )}`}
                              >
                                {t('Total')}
                              </span>
                            </b>
                          </center>
                        </td>
                        <td>
                          <p
                            className={`${classes.money} ${
                              stakeTotal < 0 ? classes.negative : null
                            } ${classes.font14}`}
                          >
                            <CurrencyFormat
                              value={stakeTotal}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </p>
                        </td>
                        <td>
                          <p
                            className={`${classes.money} ${
                              winningTotal < 0 ? classes.negative : null
                            } ${classes.font14}`}
                          >
                            <CurrencyFormat
                              value={winningTotal}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </p>
                        </td>
                        <td>
                          <p
                            className={`${classes.money} ${
                              commissionTotal < 0 ? classes.negative : null
                            } ${classes.font14}`}
                          >
                            <CurrencyFormat
                              value={commissionTotal}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </p>
                        </td>
                        <td>
                          <p
                            className={`${classes.money} ${
                              netWinTotal < 0 ? classes.negative : null
                            } ${classes.font14}`}
                          >
                            <CurrencyFormat
                              value={netWinTotal}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    count={totalPage}
                    onChange={handleChange}
                    className={classes.pagination}
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>

        {/* modal  */}
        <ViewDetailModal data={data} open={open} handleClose={handleClose} />
      </div>
    </>
  );
};

export default HistoryDetail;

import { Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/HistoryDetail.css";

const useStyles = makeStyles((theme) => ({
  none: {
    display: "none",
  },
  reject: {
    textDecoration: "line-through",
  },
  strong: {
    color: "#DC252F",
  },
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  teams: {
    fontSize: "13px",
    color: "#323030",
    margin: "0.15rem 0 !important",
  },
  compName: {
    color: "#0E2336",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0 !important",
  },
  spacing: {
    padding: "0.35rem 0.5rem",
  },
  table: {
    width: "100%",
  },
  index: {
    fontSize: "13px",
    color: "#323030",
    textAlign: "center",
    fontWeight: "bold",
  },
  score: {
    fontSize: "13px",
    color: "#323030",
    textAlign: "center",
    fontWeight: "bold",
  },
  result: {
    fontSize: "13px",
    color: "#323030",
    textAlign: "center",
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    maxHeight: "18px",
  },
  flex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: "18px",
    width: "18px",
    margin: "0 0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  negative: {
    color: "#E91F1F",
  },
  blue: {
    color: "#1452CC",
    fontWeight: "bold",
    fontSize: "12px",
  },
  view: {
    backgroundColor: "#fff",
    color: "#86898C",
    lineHeight: "12px",
    fontWeight: "bold",
    fontSize: "12px",
    border: "1px solid #86898C",
    borderRadius: "2px",
    cursor: "pointer",
    padding: "0.25rem",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  running: {
    color: "#E91F1F",
    fontSize: "11px",
    fontWeight: "bold",
    margin: "0.15rem 0 !important",
  },
  Modal: {
    margin: "0 auto",
    width: "800px",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    display: "block",
    margin: "10vh auto",
  },
  blank: {
    backgroundColor: "#86898C",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.3rem 0",
  },
}));
const LegacyHistoryDetail = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  //   single quote
  const [quote, setQuote] = useState(null);
  const printRef = useRef();
  const [totalPage, setTotalPage] = useState(null);

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // render the details
  useEffect(() => {
    axiosInstance
      .get(`portal/legacy/detail?date=${props.match.params.date}`)
      .then((res) => {
        //console.log(res.data.content);
        setDetail(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 412) {
          history.push("/legacy/history");
        } else if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [props.match.params.date, history]);

  const handleChange = (event, value) => {
    //console.log(value);
    axiosInstance
      .get(`portal/legacy/detail?date=${props.match.params.date}`, {
        params: {
          pageNo: value - 1,
        },
      })
      .then((res) => {
        //console.log(res.data.content);
        setDetail(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          logout(history);
        }
      });
  };

  // modal handling
  const handleView = (e) => {
    const betId = e.target.id;
    axiosInstance
      .get(`portal/legacy/mix?id=${betId}`)
      .then((res) => {
        setData(res.data);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Navbar User={props.User.user} /> 
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={props.User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="HistoryDetail">
              <div className={classes.flex}>
                <div className={classes.flex2}>
                  <img
                    src={require("../img/Icons/time-17.svg")}
                    alt=""
                    className={classes.img}
                  />
                  <span className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Legacy Details")}</span></span>
                  <Link
                    to="#"
                    className={classes.handiCap}
                    onClick={handlePrint}
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Print")}</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/legacy/history"
                    style={{ color: "#18334C", margin: "0 1rem" }}
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Back To Legacy")}</span>
                  </Link>
                </div>
              </div>

              {detail !== null ? (
                <>
                  <table ref={printRef} style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: "4px 0 0 0" }}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("No.")}</span>
                        </th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Date & Time")}</span></th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Bet Type")}</span></th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Details")}</span></th>
                        <th style={{ padding: "0" }}><span className={`lang-${localStorage.getItem('language')}`}>{t("HT")}</span></th>
                        <th style={{ padding: "0" }}><span className={`lang-${localStorage.getItem('language')}`}>{t("FT")}</span></th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Stake")}</span></th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Winning")}</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.map((info, index) => (
                        <tr key={index}>
                          <td>
                            <p className={classes.index}>{index + 1}</p>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <p style={{ margin: "0.15rem 0" }}>
                              {moment(info.created).format("DD/MM/YY")}
                            </p>
                            <p>{moment(info.created).format("hh:mm a")}</p>
                          </td>
                          <td>
                            {info.market === "PARLAY" ? (
                              <div className={classes.spacing}>
                                <p className={classes.teams}>{info.mixType}</p>
                                <p className={classes.teams}>
                                  {info.reference}
                                </p>
                              </div>
                            ) : (
                              <div className={classes.spacing}>
                                <p className={classes.teams}>{info.market}</p>
                                {/* <p className={classes.teams}>{info.game}</p> */}
                                <p className={classes.teams}>
                                  {info.reference}
                                </p>
                              </div>
                            )}
                          </td>
                          <td>
                            {info.market === "PARLAY" ? (
                              <div
                                className={classes.flex}
                                style={{
                                  justifyContent: "space-between",
                                  padding: "0 0.5rem",
                                }}
                              >
                                <p className={classes.compName}>
                                <span className={`lang-${localStorage.getItem('language')}`}>{t("Mix Parlay_")}</span>{" "}
                                  <CurrencyFormat
                                    value={info.odds}
                                    decimalScale={2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </p>
                                <button
                                  className={classes.view}
                                  id={info.betId}
                                  onClick={handleView}
                                >
                                  <span className={`lang-${localStorage.getItem('language')}`}>{t("View Detail")}</span>
                                </button>
                              </div>
                            ) : (
                              <div className={classes.spacing}>
                                <p
                                  className={`${classes.compName} ${
                                    info.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  {info.leagueName}
                                </p>
                                <p
                                  className={`${classes.teams} ${
                                    info.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  <span
                                    className={
                                      info.high === 1 ? classes.strong : null
                                    }
                                  >
                                    {info.homeName} vs{" "}
                                  </span>
                                  <span
                                    className={
                                      info.high === 2 ? classes.strong : null
                                    }
                                  >
                                    {info.awayName}
                                  </span>
                                </p>
                                {info.market === "LIVE" ? (
                                  <div className={classes.flex}>
                                    <p className={classes.running}>
                                      (Running {info.score})
                                    </p>
                                  </div>
                                ) : null}
                                <p
                                  className={`${classes.handiCap} ${
                                    info.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  <span className={classes.compName}>
                                    {info.desc}{" "}
                                    {info.hdp != null ? (
                                      <span className={classes.handiCap}>
                                        ({info.hdp}){" "}
                                      </span>
                                    ) : null}
                                    <span>
                                      @{" "}
                                      <CurrencyFormat
                                        value={info.odds}
                                        decimalScale={2}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                      />
                                    </span>
                                  </span>
                                </p>
                                {info.flagCancel === true ? (
                                  <div className={classes.flex}>
                                    <p className={classes.running}>
                                    <span className={`lang-${localStorage.getItem('language')}`}>{t("Reject")}</span>{" "}
                                      {info.rejectReason
                                        ? "(" + info.rejectReason + ")"
                                        : ""}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </td>
                          <td style={{ padding: "0", minWidth: "30px" }}>
                            <p className={classes.score}>{info.ht}</p>
                          </td>
                          <td style={{ padding: "0", minWidth: "30px" }}>
                            <p className={classes.score}>{info.ft}</p>
                          </td>
                          <td>
                            <p className={`${classes.money}`}>
                              <CurrencyFormat
                                value={info.amount}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.result} ${
                                info.betResult === "LOSE" ||
                                info.betResult === "LOSE_HALF"
                                  ? classes.negative
                                  : null
                              }`}
                            >
                              {info.betResult != null && info.betResult !== ""
                                ? info.betResult.charAt(0).toUpperCase() +
                                  info.betResult
                                    .slice(1)
                                    .toLowerCase()
                                    .replace("_", " ")
                                : "-"}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    count={totalPage}
                    onChange={handleChange}
                    className={classes.pagination}
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>

        {/* modal  */}
        {data !== null ? (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalStyle}
          >
            <div className={classes.Modal}>
              <div className="Wager-modal">
                <table>
                  <thead>
                    <tr>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("No.")}</span></th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Bet type")}</span></th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Details")}</span></th>
                      <th style={{ padding: "0" }}><span className={`lang-${localStorage.getItem('language')}`}>{t("HT")}</span></th>
                      <th style={{ padding: "0" }}><span className={`lang-${localStorage.getItem('language')}`}>{t("FT")}</span></th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Win")}</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((match, index) => (
                      <React.Fragment key={index}>
                        {match.map((det, i) => (
                          <tr key={i}>
                            <td>
                              <p className={classes.index}>{i + 1}</p>
                            </td>
                            <td>
                              <div className={classes.spacing}>
                                <p className={classes.teams}>{det.market}</p>
                              </div>
                            </td>
                            <td>
                              <div className={classes.spacing}>
                                <p
                                  className={`${classes.compName} ${
                                    det.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  {det.leagueName}
                                </p>
                                <p
                                  className={`${classes.teams} ${
                                    det.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  <span>{det.homeName}</span> vs{" "}
                                  <span>{det.awayName}</span>
                                </p>
                                <p
                                  className={`${classes.teams} ${
                                    det.flagCancel === true
                                      ? classes.reject
                                      : null
                                  }`}
                                >
                                  {det.desc}{" "}
                                  {det.hdp !== "" ? (
                                    <span className={classes.handiCap}>
                                      ({det.hdp})
                                    </span>
                                  ) : null}
                                  <span>
                                    @{" "}
                                    <CurrencyFormat
                                      value={det.odds}
                                      decimalScale={2}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td style={{ padding: "0", minWidth: "30px" }}>
                              <p className={classes.score}>{det.ht}</p>
                            </td>
                            <td style={{ padding: "0", minWidth: "30px" }}>
                              <p className={classes.score}>{det.ft}</p>
                            </td>
                            <td>
                              <p className={classes.result}>
                                {det.betResult !== ""
                                  ? det.betResult.charAt(0).toUpperCase() +
                                    det.betResult
                                      .slice(1)
                                      .toLowerCase()
                                      .replace("_", " ")
                                  : "-"}
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr className={classes.none}>
                          <td colSpan={6} className={classes.blank} />
                        </tr>
                        <tr>
                          <td colSpan={6} className={classes.blank} />
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default LegacyHistoryDetail;

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import axiosInstance from '../config';
import {logout} from './Utils';

const useStyles = makeStyles((theme) => ({
  // styling for last ten bets might make a component
  reject: {
    textDecoration: 'line-through',
  },
  strong: {
    color: '#DC252F',
  },
  teams: {
    fontSize: '12px',
    color: '#18334C',
    // margin: '0',
  },
  desc: {
    color: '#0E2336',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0.05rem 0',
  },
  compName: {
    color: '#0E2336',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0.05rem 0',
    // margin: '0',
  },
  running: {
    color: '#E91F1F',
    fontSize: '12px',
    fontWeight: 'bold',
    // margin: ' 0',
  },
  handiCap: {
    color: '#1452CC ',
    fontSize: '12px',
    fontWeight: 'bold',
    // margin: ' 0',
  },
  money: {
    color: '#1452CC',
    fontSize: '14px',
    fontWeight: 'bold',
    // margin: '0 ',
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#1452CC',
    margin: '0',
  },
  refresh: {
    cursor: 'pointer',
    height: '12px',
    width: '12px',
  },
  spacing: {
    padding: '0.25rem 0.75rem',
    borderBottom: '1px solid #86898c',
    '& p': {
      margin: '0 ',
    },
  },
  waiting: {
    backgroundColor: '#f9eded',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex2: {
    display: 'flex',
    padding: '0.15rem 0.75rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  printWrapper: {
    display: 'flex',
    '& a': {
      fontSize: '12px',
      margin: '0 0.5rem',
    },
  },
  Modal: {
    margin: 'auto',
    backgroundColor: '#fff',
    borderRadius: '4px',
    width: '300px',
    maxHeight: '550px !important',
    overflowY: 'auto',
  },
  modalStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  division: {
    borderBottom: '1px solid #86898C',
  },
  card: {
    backgroundColor: '#fff',
    // boxShadow: "0px 10px 20px 5px rgba(0, 0, 0, 0.03)",
    borderRadius: '4px',
    margin: '1rem 0',
    marginTop: '0',
  },
  cardUpper: {
    backgroundColor: '#86898C',
    padding: '0.0rem 0.75rem',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardLower: {
    padding: '0.15rem 0.75rem',
    borderRadius: '0 0 4px 4px ',
    '& p': {
      margin: '0 !important',
    },
  },
  heading: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
  },
}));
const BetInfo = ({ lastBet, total, User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [print, setPrint] = useState(null);
  // modal
  const [open, setOpen] = useState(false);
  const [reference, setReference] = useState(null);
  const printRef = useRef();
  const printRef2 = useRef();
  const history = useHistory();

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handlePrint2 = useReactToPrint({
    content: () => printRef2.current,
  });

  const handleClose = () => {
    setOpen(false);
    setReference(null);
  };

  useEffect(() => {
    if (reference != null) {
      axiosInstance
        .get('portal/bets/' + reference)
        .then((res) => {
          setPrint(res.data);
          setOpen(true);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    }
  }, [reference, history]);

  return (
    <React.Fragment>
      {/*  mapping through bets and showing */}

      {lastBet.map((bet, index) => (
        <React.Fragment key={index}>
          {bet.market === 'PARLAY' ? (
            <>
              <div className={classes.spacing}>
                <p className={classes.desc}>
                  {moment(bet.created).format('DD/MM')} MIX PARLAY
                </p>
                <p className={classes.desc} style={{ fontWeight: 'normal' }}>
                  {bet.type}
                </p>

                <div className={classes.flex}>
                  <div className={classes.printWrapper}>
                    <p className={classes.desc}>
                      @{' '}
                      <CurrencyFormat
                        value={bet.odds}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </p>

                    <Link
                      to="#"
                      className={classes.handiCap}
                      onClick={() => {
                        setReference(bet.reference);
                      }}
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('More')}
                      </span>
                    </Link>
                  </div>
                  <p className={classes.amount}>
                    <CurrencyFormat
                      value={bet.amount}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${classes.spacing} ${
                bet.status === 'PENDING' ? classes.waiting : null
              }`}
              id={`betId${bet.bet}`}
              // ref={printRef2}
            >
              {/* single quote matches */}
              <p className={classes.compName}>
                {moment(bet.created).format('DD/MM')} {bet.comp} {bet.compType}
              </p>
              <p className={classes.teams}>
                <span className={bet.high === 1 ? classes.strong : null}>
                  {bet.home}
                </span>{' '}
                vs{' '}
                <span className={bet.high === 2 ? classes.strong : null}>
                  {bet.away}
                </span>
              </p>
              {bet.market === 'LIVE' ? (
                <p className={classes.running}>
                  (Running {bet.homeGoal}-{bet.awayGoal})
                </p>
              ) : null}
              <p className={classes.desc}>
                {bet.desc}
                {bet.handicap !== '' ? (
                  <span className={classes.handiCap}> ({bet.handicap})</span>
                ) : null}
                {bet.status === 'PENDING' ? (
                  <span className={classes.running}> ({bet.status})</span>
                ) : null}
              </p>
              <div className={classes.flex}>
                <div className={classes.printWrapper}>
                  <p className={classes.desc}>
                    @{' '}
                    <CurrencyFormat
                      value={bet.odds}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </p>

                  <Link
                    to="#"
                    className={classes.handiCap}
                    onClick={() => {
                      printRef2.current = document.querySelector(
                        `#betId${bet.bet}`
                      );
                      handlePrint2();
                    }}
                    style={{
                      display: bet.status === 'PENDING' ? 'none' : 'block',
                    }}
                  >
                    <span
                      className={`lang-${localStorage.getItem('language')}`}
                    >
                      {t('Print')}
                    </span>
                  </Link>
                </div>
                <p className={classes.amount}>
                  <CurrencyFormat
                    value={bet.amount}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}

      {/* modal */}
      {print !== null ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modalStyle}
        >
          <div className={classes.Modal}>
            <div ref={printRef}>
              {print.market === 'PARLAY' ? (
                <div className={classes.card}>
                  <div className={classes.cardUpper}>
                    <p className={classes.heading}>
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Mix Details')}
                      </span>
                    </p>
                  </div>
                  <div className={classes.cardLower}>
                    {print.parlays.map((match, index) => (
                      <React.Fragment key={index}>
                        <div className={classes.division}>
                          <p
                            className={`${classes.compName} ${
                              match.status === 'REJECTED' ||
                              match.status === 'CANCELLED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            {moment(print.created).format('DD/MM')} {match.comp}{' '}
                            {match.compType}
                          </p>
                          <p
                            className={`${classes.teams} ${
                              match.status === 'REJECTED' ||
                              match.status === 'CANCELLED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            <span
                              className={
                                match.high === 1 ? classes.strong : null
                              }
                            >
                              {match.home}
                            </span>{' '}
                            vs{' '}
                            <span
                              className={
                                match.high === 2 ? classes.strong : null
                              }
                            >
                              {match.away}
                            </span>
                          </p>
                          {match.market === 'LIVE' ? (
                            <p className={classes.running}>
                              (Running {match.homeGoal}-{match.awayGoal})
                            </p>
                          ) : null}
                          <p
                            className={`${classes.desc} ${
                              match.status === 'REJECTED' ||
                              match.status === 'CANCELLED'
                                ? classes.reject
                                : null
                            }`}
                          >
                            {match.desc}
                            {match.handicap !== '' ? (
                              <span className={classes.handiCap}>
                                {' '}
                                ({match.handicap})
                              </span>
                            ) : null}
                            {match.status === 'PENDING' ? (
                              <span className={classes.running}>
                                {' '}
                                ({match.status})
                              </span>
                            ) : null}
                          </p>
                          <div className={classes.flex}>
                            <div className={classes.printWrapper}>
                              <p
                                className={`${classes.desc} ${
                                  match.status === 'REJECTED' ||
                                  match.status === 'CANCELLED'
                                    ? classes.reject
                                    : null
                                }`}
                              >
                                @{' '}
                                <CurrencyFormat
                                  value={match.odds}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </p>
                            </div>
                            {match.status === 'REJECTED' ||
                            match.status === 'CANCELLED' ? (
                              <p className={classes.running}>
                                <span
                                  className={`lang-${localStorage.getItem(
                                    'language'
                                  )}`}
                                >
                                  {t('Reject')}
                                </span>{' '}
                                {match.rejectReason
                                  ? '(' + match.rejectReason + ')'
                                  : ''}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <div className={classes.printWrapper}>
                        <p className={classes.desc}>{t('Parlay_')}&nbsp;
                          {print.type} @{' '}
                          <CurrencyFormat
                            value={print.odds}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </p>
                      </div>
                      {/* <p className={classes.desc}>
                        {print.type} @{' '}
                        <CurrencyFormat
                          value={print.odds}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p> */}
                    </div>
                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <div className={classes.printWrapper}>
                        <p className={classes.desc}>{t('Total')}:&nbsp;
                          <CurrencyFormat
                            value={print.amount}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </p>
                      </div>
                      {/* <p className={classes.amount}>
                        <CurrencyFormat
                          value={print.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p> */}
                    </div>
                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <div className={classes.printWrapper}>
                        <p className={classes.desc}>{t('Ease Payout')}:&nbsp;
                          <CurrencyFormat
                          className={classes.desc}
                          value={print.amount * print.odds / parseInt(print.type.split(" ")[2])}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </p>
                      </div>
                      {/* <CurrencyFormat
                        className={classes.desc}
                        value={print.amount * print.odds / parseInt(print.type.split(" ")[2])}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      /> */}
                    </div>
                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <div className={classes.printWrapper}>
                        <p className={classes.desc}>{t('Bet no.')}&nbsp;{print.reference}</p>
                      </div>
                      {/* <p className={classes.desc}>{print.reference}</p> */}
                    </div>
                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <div className={classes.printWrapper}>
                        <p className={classes.desc}>{t('Account_')}&nbsp;{User.user.username}</p>
                      </div>
                      {/* <p className={classes.desc}>{User.user.username}</p> */}
                    </div>

                    <div
                      className={classes.flex}
                      style={{ margin: '0.35rem 0' }}
                    >
                      <Link
                        to="#"
                        className={classes.handiCap}
                        onClick={handlePrint}
                      >
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Print')}
                        </span>
                      </Link>
                      <p className={classes.desc}>
                        <span
                          className={`lang-${localStorage.getItem('language')}`}
                        >
                          {t('Date_')}
                        </span>
                        {moment(print.created).format(' DD MMM YYYY, HH:mm')}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default BetInfo;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Navbar2 from "../../components/Navbar2.js";
import ResultsRightSide from "../../components/ResultsRightSide.js";
import PublicLeftSide from "../../components/PublicLeftSide.js";
import "../../styles/Pages.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  pt0: {
    paddingTop: "0px !important"
  },
  paper: {
    padding: theme.spacing(9),
    textAlign: "center",
    backgroundColor: "#C4C4C4",
    color: theme.palette.text.secondary
  },
  cardheader: {
    textAlign: "center",
    backgroundColor: "#FFF",
    padding: "12px 16px 4px 16px"
  },
  cardtitle: {
    fontSize: "16px",
    margin: 0,
    color: "#86898C",
    fontWeight: "bold"
  },
  card: {
    marginBottom: "16px",
    boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.08) !important"
  },
  tabContent: {
    padding: "15px"
  },
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important"
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "90px auto auto !important",
    float: "none !important"
  },
  LeftPanel: {
    width: "25%"
  },
  RightPanel: {
    width: "75%",
    marginTop: "10px"
  }
}));

const ResultsPage = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <>
      <Navbar2 showLogin="yes" />
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <PublicLeftSide />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <ResultsRightSide />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ResultsPage;

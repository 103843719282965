import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axiosInstance, { publicAxiosInstance } from '../config';
import '../styles/RightSide.css';
import LeagueFilter from './LeagueFilter';
import TableInfo from './TableInfo';
import fileDownload from 'js-file-download';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {logout} from './Utils';
import Timer from './Timer';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#18334C',
    fontSize: '14px',
    marginLeft: '0.5rem',
  },
  stickyoptions: {
    position: 'sticky',
    top: '88px',
    backgroundColor: '#f5f5f5',
    padding: '5px 10px',
    margin: '0 -10px',
    zIndex: '2',
  },
  hide: {
    display: 'none',
  },
  ml5: {
    marginLeft: '4px',
  },
  mr5: {
    marginRight: '4px',
  },
  pl5: {
    paddingLeft: '0.5rem',
  },
  pr5: {
    paddingRight: '0.5rem',
  },
  hk: {
    border: '1px solid #18334C',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#fff',
    color: '#323030',
    padding: '0.25rem 0.1rem',
    textAlign: 'left',
    textAlignLast: 'left',
    cursor: 'pointer',
  },
  noFav: {
    color: '#FAB81D',
    border: '1px solid #FAB81D',
    background: '#fff',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    padding: '0.25rem 0.75rem',
  },
  onlyFav: {
    color: '#ffffff',
    border: '1px solid #FAB81D',
    background: '#FAB81D',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    padding: '0.25rem 0.75rem',
  },
  selectleague: {
    background: '#fff',
    color: '#323030',
    border: '1px solid #18334C',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginLeft: '1rem',
    padding: '0.25rem 0.75rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    cursor: 'pointer',
  },
  img: {
    height: '18px',
    width: '18px',
  },
  timer: {
    fontWeight: 'bold',
    color: '#DC252F',
    fontSize: '12px',
    paddingLeft: '4px',
  },
  font12: {
    fontSize: '12px',
  },
}));
const RightSide = ({
  User,
  setQuote,
  setParlayInfo,
  ParlayInfo,
  isBetParlay,
  setBetParlay,
  setbetSuccess,
  betSuccess,
  isParlayTable,
  setOddError,
  setOddUpdating,
  setAddParlay,
  showToday,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [matches, setMatches] = useState([]);
  const [line, setLine] = useState('Double');
  const [sortOrder, setSortOrder] = useState('');
  const [onlyFav, setOnlyFav] = useState(false);
  const [tmatches, setTMatches] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [todaySeconds, setTodaySeconds] = useState(0);
  const [openLeagueFilter, setOpenLeagueFilter] = useState(false);
  const [openTLeagueFilter, setOpenTLeagueFilter] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [minDate, setMinDate] = useState();
  const [refreshTimer, setRefreshTimer] = useState(new Date());
  const [refreshCallOddHdp, setRefreshCallOddHdp] = useState(new Date());
  const [todayRefreshTimer, setTodayRefreshTimer] = useState(new Date());
  const [refreshCallTodayOddHdp, setRefreshCallTodayOddHdp] = useState(new Date());

  useEffect(() => {
    if (openLeagueFilter) {
      window.scrollTo(0, 0);
    }
  }, [openLeagueFilter]);

  useEffect(() => {
    if (!openTLeagueFilter) {
      window.scrollTo(0, scrollPos);
    }
  }, [openTLeagueFilter]);

  const getTomorrow = () => {
    publicAxiosInstance
      .get('/public/reportDate')
      .then((res) => {
        let dt = new Date(res.data);
        dt.setDate(dt.getDate() + 1);
        setMinDate(dt);
      })
      .catch((err) => console.log(err));
  };

  const handleLineChange = (event) => {
    setLine(event.target.value);
  };

  const handleOrderChange = (event) => {
    setSortOrder(event.target.value);
    setRefreshTimer(new Date());
    setTodayRefreshTimer(new Date());
    //setSeconds(0);
    //setTodaySeconds(0);
  };

  const handleTypeChange = (event) => {
    let url = 'restricted/oddType/';
    axiosInstance
      .patch(url + event.target.value)
      .then(() => {
        setRefreshTimer(new Date());
        setTodayRefreshTimer(new Date());
        // setSeconds(0);
        // setTodaySeconds(0);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };

  useEffect(() => {
    window.addEventListener('hashchange', function (e) {
      // setSeconds(0);
      // setTodaySeconds(0);
      setRefreshTimer(new Date());
      setTodayRefreshTimer(new Date());
    });
    return () => {
      // unsubscribe event
      window.removeEventListener('hashchange', function (e) {
        
      });
    };
  }, []);


  const handleDisplayChange = (event) => {
    let url = 'restricted/display/';
    let singleMultiOddsOption = event.target.value;
    localStorage.setItem('singleMultiOddsOption', singleMultiOddsOption);
    axiosInstance
      .patch(url + singleMultiOddsOption)
      .then(() => {
        // setSeconds(0);
        // setTodaySeconds(0);
        setRefreshTimer(new Date());
        setTodayRefreshTimer(new Date());
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  };

  // window.addEventListener('hashchange', function (e) {
  //   // setSeconds(0);
  //   // setTodaySeconds(0);
  //   setRefreshTimer(new Date());
  //   setTodayRefreshTimer(new Date());
  // });

  const getMenu = (group) => {
    var menuStorage = localStorage.getItem('menu');
    if (menuStorage) {
      var res = JSON.parse(menuStorage)[group];
      return res;
    } else {
      return '';
    }
  };

  const getGameImage = () => {
    if (getMenu('Game') === 'Basketball') {
      return 'basketball-2.svg';
    }
    return 'soccer-1.svg';
  };

  const downloadOdd = (downloadDate) => {
    setLoading(true);
    let url =
      'restricted/oddHdp/download?status=' +
      (getMenu('Odds') === 'Early' ? 'E' : 'T');
    if (url.endsWith('E')) {
      url += '&date=' + moment(downloadDate).format('YYYYMMDD');
    }
    if (isParlayTable) {
      url += '&parlay=Y';
    }
    if (onlyFav) {
      url += '&fav=1';
    }
    if (sortOrder === 'Time') {
      url += '&ord=M';
    }
    if (getMenu('Game') === 'Basketball') {
      url += '&s=2';
    }
    if (getMenu('Game') === 'Virtual Sport') {
      url += '&s=3';
    }

    axiosInstance
      .get(`/${url}`, {
        responseType: 'blob',
      })
      .then((response) => {
        fileDownload(
          response.data,
          getMenu('Odds') === 'Early' ? 'a28iearly.xlsx' : 'a28itoday.xlsx'
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getTomorrow();
  }, []);

 

  useEffect(() => {
    if (
      document.querySelector('#oddType') != null &&
      document.querySelector('#displayType') != null &&
      document.querySelector('#typeVal').value === ''
    ) {
      document.querySelector('#oddType').value = User && User.user.oddType;
      document.querySelector('#typeVal').value = '1';
      document.querySelector('#displayType').value =
        User && User.user.displayType;
    }
  }, [User, history]);

 

  useEffect(() => {
    
    let callOddHdp = () => {
      let url = 'public/oddHdp?status=R';
      if (isParlayTable) {
        url += '&parlay=Y';
      }
      if (onlyFav) {
        url += '&fav=1';
      }
      if (sortOrder === 'Time') {
        url += '&ord=M';
      }
      if (getMenu('Game') === 'Basketball') {
        url += '&s=2';
      }
      if (getMenu('Game') === 'Virtual Sport') {
        url += '&s=3';
      }

      let axiosIns = User ? axiosInstance: publicAxiosInstance;
      return axiosIns
        .post(url)
        .then((res) => {
          setMatches((matches) => {
            return res.data.map((newMatch) => {
              let oldMatch = matches.find((m) => {
                return m.p === newMatch.p;
              });
              if (oldMatch) {
                newMatch['old'] = oldMatch;
              }
              return newMatch;
            });
          });
        })
        .then(() => {
          setRefreshTimer(new Date());
         // setSeconds(refreshSecond);
        })
        .catch((err) => {
          setRefreshTimer(new Date());
         // setSeconds(refreshSecond);
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    };
    callOddHdp();

  },[refreshCallOddHdp, User, isParlayTable, sortOrder, onlyFav, history])

  useEffect(() => {

    let callTodayOddHdp = () => {
      let url =
        'public/oddHdp?status=' + (getMenu('Odds') === 'Early' ? 'E' : 'T');
  
      if (showToday) {
        url = 'public/oddHdp?status=T';
      }
  
      if (onlyFav) {
        url += '&fav=1';
      }
      if (isParlayTable) {
        url += '&parlay=Y';
      }
      if (sortOrder === 'Time') {
        url += '&ord=M';
      }
      if (getMenu('Game') === 'Basketball') {
        url += '&s=2';
      }
      else if (getMenu('Game') === 'Virtual Sport') {
        url += '&s=3';
      }
      let axiosIns = User ? axiosInstance: publicAxiosInstance;
      return axiosIns
        .post(url)
        .then((res) => {
          setTMatches((matches) => {
            return res.data.map((newMatch) => {
              let oldMatch = matches.find((m) => {
                return m.p === newMatch.p;
              });
              if (oldMatch) {
                newMatch['old'] = oldMatch;
              }
              return newMatch;
            });
          });
        })
        .then(() => {
          setTodayRefreshTimer(new Date());
          //setTodaySeconds(todayRefreshSecond);
        })
        .catch((err) => {
          console.log(err);
          setTodayRefreshTimer(new Date());
          //setTodaySeconds(todayRefreshSecond);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    };

    callTodayOddHdp();
  },[refreshCallTodayOddHdp, sortOrder, onlyFav, history, User, isParlayTable]);

 
  return (
    <div className="RightSide">
      <div className="FirstTableGroup">
        {User ? (
          <div className={`${classes.flex} ${classes.stickyoptions}`}>
            <div className={classes.flex2}>
              <img
                src={require(`../img/Icons/${getGameImage()}`)}
                alt=""
                className={classes.img}
              />
              <span className={`${classes.header} ${classes.pr5}`}>
                {getMenu('Game')}{' '}
                {isParlayTable === true ? `Mix Parlay ` : null}- Live{' '}
              </span>
              <Timer timerCall = { () => setRefreshCallOddHdp(new Date()) } 
              refresh = {refreshTimer} 
              initialSeconds = {User? 15: 30}
              />
              <button
                onClick={() => setOpenLeagueFilter(true)}
                className={classes.selectleague}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25001C9.845 2.96042 8.83 4.62125 7.13625 5.1825C7.26 4.9525 7.38458 4.70542 7.50375 4.43959C8.71833 3.80584 9.18583 2.66875 9.36375 1.835H8.24375C8.26833 1.6475 8.28667 1.45084 8.30167 1.24959H10V1.25001ZM0 1.25001C0.155 2.96042 1.17 4.62125 2.86375 5.1825C2.74 4.9525 2.61542 4.70542 2.49625 4.43959C1.28167 3.80584 0.814167 2.66875 0.63625 1.835H1.75625C1.73208 1.64792 1.71375 1.45084 1.69875 1.25001H0ZM7.05042 1.25001C6.89708 3.1275 6.23667 4.2625 5.64542 5.27875C5.405 5.6925 5.17208 6.0925 5.0025 6.50792C4.8325 6.09209 4.59875 5.69167 4.3575 5.2775C3.765 4.26209 3.10292 3.12709 2.94958 1.25001H7.05042ZM7.91667 0.416672H2.08333C2.08333 4.50126 4.21042 5.85542 4.41833 7.5H5.58708C5.79417 5.85542 7.91667 4.50126 7.91667 0.416672ZM5.59917 7.91667H4.405C4.32083 8.41125 4.1425 9.00792 3.33375 9.00792V9.58334H6.66708V9.00792C5.83417 9.00792 5.67917 8.41125 5.59917 7.91667ZM4.08083 2.72L4.68917 2.63584L4.95708 2.08334L5.225 2.63584L5.83333 2.72L5.39042 3.14542L5.49875 3.75L4.95708 3.46042L4.41542 3.75L4.52375 3.14542L4.08083 2.72Z"
                    fill="#323030"
                  />
                </svg>
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('Select League')}
                </span>
              </button>
              <select
                value={sortOrder}
                onChange={handleOrderChange}
                className={`${classes.hk} ${
                  classes.ml5
                } lang-${localStorage.getItem(
                  'language'
                )} lang-${localStorage.getItem('language')}-fs-12`}
              >
                <optgroup
                  className={`${classes.ml5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  <option value="">{t('Default')}</option>
                  <option value="Time">{t('Time')}</option>
                </optgroup>
              </select>

              <select
                value={line}
                onChange={handleLineChange}
                className={`${classes.hk} ${
                  classes.ml5
                } lang-${localStorage.getItem('language')}
                ${
                  localStorage.getItem('language') === 'kh'
                    ? classes.font12
                    : null
                }`}
              >
                <optgroup
                  className={`${classes.ml5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  <option value="Double">{t('Double Line')}</option>
                  <option value="Single">{t('Single Line')}</option>
                </optgroup>
              </select>
            </div>
            <div className={classes.flex2}>
              <input type="hidden" id="typeVal" />
              {isParlayTable === false ? (
                <select
                  className={`${classes.hk} lang-${localStorage.getItem(
                    'language'
                  )}
                  ${
                    localStorage.getItem('language') === 'kh'
                      ? classes.font12
                      : null
                  }`}
                  onChange={handleTypeChange}
                  id="oddType"
                >
                  <option value="HK">{t('HK')}</option>
                  <option value="MY">{t('MY')}</option>
                </select>
              ) : null}
              &nbsp;
              <select value={localStorage.getItem('singleMultiOddsOption') || 'MU'}
                className={`${classes.hk} lang-${localStorage.getItem(
                  'language'
                )}
                ${
                  localStorage.getItem('language') === 'kh'
                    ? classes.font12
                    : null
                }`}
                onChange={handleDisplayChange}
                id="displayType"
              >
                <option value="MU">{t('Multi Odds')}</option>
                <option value="SG">{t('Single Odd')}</option>
              </select>
              <button
                onClick={() => {
                  setOnlyFav((f) => !f);
                  setRefreshTimer(new Date());
                  setTodayRefreshTimer(new Date());
                  // setSeconds(0);
                  // setTodaySeconds(0);
                }}
                className={onlyFav ? classes.onlyFav : classes.noFav}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 0.119995L6.18042 3.7525H10L6.90958 5.99791L8.09 9.63041L5 7.38541L1.90958 9.63041L3.09 5.99791L0 3.7525H3.81958L5 0.119995Z"
                    fill={onlyFav ? 'white' : '#FAB81D'}
                  />
                </svg>
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('Favorite')}
                </span>
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        {/* table starts */}

        <div className="firstTable">
          <TableInfo
            User={User}
            line={line}
            matches={matches}
            setQuote={setQuote}
            ParlayInfo={ParlayInfo}
            setParlayInfo={setParlayInfo}
            isBetParlay={isBetParlay}
            setBetParlay={setBetParlay}
            setbetSuccess={setbetSuccess}
            betSuccess={betSuccess}
            setOddError={setOddError}
            setOddUpdating={setOddUpdating}
            isParlayTable={isParlayTable}
            setAddParlay={setAddParlay}
            getMenu={getMenu}
          />
        </div>
        {User ? (
          <LeagueFilter
            market="R"
            game={getMenu('Game')}
            trigger={() => {
              //setSeconds(0);
              console.log('--- refresh timer')
              setRefreshTimer(new Date());
            }}
            openLeagueFilter={openLeagueFilter}
            setOpenLeagueFilter={setOpenLeagueFilter}
          />
        ) : null}

        {/* table ends */}
      </div>

      <div className="SecondTableGroup">
        {getMenu('Odds') !== 'Live' || showToday ? (
          <>
            {User ? (
              <div className={`${classes.flex} ${classes.stickyoptions}`}>
                <div className={classes.flex2}>
                  <>
                    <img
                      src={require(`../img/Icons/${getGameImage()}`)}
                      alt=""
                      className={classes.img}
                    />
                    <span className={`${classes.header} ${classes.pr5}`}>
                      {getMenu('Game')}{' '}
                      {isParlayTable === true ? `Mix Parlay ` : null} -{' '}
                      {getMenu('Odds') === 'Early' ? 'Early' : 'Today'}
                    </span>
                    <Timer timerCall = { () => setRefreshCallTodayOddHdp(new Date()) } 
                      refresh = {todayRefreshTimer}
                      initialSeconds = {User? 30: 60} 
                      />
              
                    <button
                      onClick={() => {
                        setScrollPos(window.scrollY);
                        setOpenTLeagueFilter(true);
                      }}
                      className={classes.selectleague}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 1.25001C9.845 2.96042 8.83 4.62125 7.13625 5.1825C7.26 4.9525 7.38458 4.70542 7.50375 4.43959C8.71833 3.80584 9.18583 2.66875 9.36375 1.835H8.24375C8.26833 1.6475 8.28667 1.45084 8.30167 1.24959H10V1.25001ZM0 1.25001C0.155 2.96042 1.17 4.62125 2.86375 5.1825C2.74 4.9525 2.61542 4.70542 2.49625 4.43959C1.28167 3.80584 0.814167 2.66875 0.63625 1.835H1.75625C1.73208 1.64792 1.71375 1.45084 1.69875 1.25001H0ZM7.05042 1.25001C6.89708 3.1275 6.23667 4.2625 5.64542 5.27875C5.405 5.6925 5.17208 6.0925 5.0025 6.50792C4.8325 6.09209 4.59875 5.69167 4.3575 5.2775C3.765 4.26209 3.10292 3.12709 2.94958 1.25001H7.05042ZM7.91667 0.416672H2.08333C2.08333 4.50126 4.21042 5.85542 4.41833 7.5H5.58708C5.79417 5.85542 7.91667 4.50126 7.91667 0.416672ZM5.59917 7.91667H4.405C4.32083 8.41125 4.1425 9.00792 3.33375 9.00792V9.58334H6.66708V9.00792C5.83417 9.00792 5.67917 8.41125 5.59917 7.91667ZM4.08083 2.72L4.68917 2.63584L4.95708 2.08334L5.225 2.63584L5.83333 2.72L5.39042 3.14542L5.49875 3.75L4.95708 3.46042L4.41542 3.75L4.52375 3.14542L4.08083 2.72Z"
                          fill="#18334C"
                        />
                      </svg>
                      <span
                        className={`${classes.pl5} lang-${localStorage.getItem(
                          'language'
                        )} lang-${localStorage.getItem('language')}-fs-12`}
                      >
                        {t('Select League')}
                      </span>
                    </button>
                    {getMenu('Odds') === 'Today' ? (
                      <button
                        onClick={() => {
                          downloadOdd();
                        }}
                        className={classes.selectleague}
                        disabled={loading}
                      >
                        <img
                          onClick={() => setTodayRefreshTimer(new Date())}
                          src={require('../img/Icons/xlsx.svg')}
                          alt=""
                          width="16px"
                          height="16px"
                          className={`${classes.link}`}
                        />
                        <span
                          className={`${
                            classes.pl5
                          } lang-${localStorage.getItem(
                            'language'
                          )} lang-${localStorage.getItem('language')}-fs-12`}
                        >
                          {loading ? 'Please Wait...' : 'Download'}
                        </span>
                      </button>
                    ) : getMenu('Odds') === 'Early' ? (
                      <>
                        <DatePicker
                          className={classes.datePicker}
                          closeOnScroll={true}
                          onChange={(date) => {
                            downloadOdd(date);
                          }}
                          minDate={minDate}
                          customInput={
                            <button
                              className={classes.selectleague}
                              disabled={loading}
                            >
                              <img
                                onClick={() => setTodayRefreshTimer(new Date())}
                                src={require('../img/Icons/xlsx.svg')}
                                alt=""
                                width="16px"
                                height="16px"
                                className={`${classes.link}`}
                              />
                              <span
                                className={`${
                                  classes.pl5
                                } lang-${localStorage.getItem(
                                  'language'
                                )} lang-${localStorage.getItem(
                                  'language'
                                )}-fs-12`}
                              >
                                {loading ? 'Please Wait...' : 'Download'}
                              </span>
                            </button>
                          }
                        />
                      </>
                    ) : null}
                  </>
                </div>
              </div>
            ) : null}

            <div className={`secondTable`}>
              <TableInfo
                User={User}
                line={line}
                matches={tmatches}
                setQuote={setQuote}
                ParlayInfo={ParlayInfo}
                setParlayInfo={setParlayInfo}
                isBetParlay={isBetParlay}
                setBetParlay={setBetParlay}
                setbetSuccess={setbetSuccess}
                betSuccess={betSuccess}
                setOddError={setOddError}
                setOddUpdating={setOddUpdating}
                isParlayTable={isParlayTable}
                setAddParlay={setAddParlay}
                getMenu={getMenu}
              />
            </div>
            {User ? (
              <LeagueFilter
                market={getMenu('Odds') === 'Early' ? 'E' : 'T'}
                game={getMenu('Game')}
                trigger={() => {
                  setTodayRefreshTimer(new Date());
                  //setTodaySeconds(0);
                }}
                openLeagueFilter={openTLeagueFilter}
                setOpenLeagueFilter={setOpenTLeagueFilter}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default RightSide;

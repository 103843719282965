import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axiosInstance from "../config";
import {logout} from './Utils';
import "../styles/Results.css";
const useStyles = makeStyles((theme) => ({
  ml5: {
    marginLeft: "5px",
  },
  compName: {
    lineHeight: "16px",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#323030",
    backgroundColor: "#DEDEDE",
    borderBottom: "1px solid #86898C",
    borderTop: "1px solid #86898C",
  },
  date: {
    color: "#18334C",
  },
  fh: {
    textAlign: "center",
    color: "#18334C",
  },
  ft: {
    textAlign: "center",
    color: "#18334C",
  },
  more: {
    textAlign: "center",
    color: "#18334C",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  form: {
    marginBottom: "1rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    marginRight: "1rem",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    maxHeight: "18px",
  },
  flex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: "18px",
    width: "18px",
  },
  mr5: {
    marginRight: "0.5rem",
  },
  btn: {
    background: "#fff",
    color: "#323030",
    border: "1px solid #18334C",
    fontSize: "14px",
    boxSizing: "border-box",
    textAlign: "center",
    fontWeight: "bold",
    borderRadius: "4px",
    marginRight: "4px",
    padding: "0.25rem 0.75rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  dd: {
    border: "1px solid #18334C",
    boxSizing: "border-box",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "bold",
    background: "#fff",
    color: "#323030",
    padding: "0.25rem 0.75rem",
    textAlign: "left",
    textAlignLast: "left",
    cursor: "pointer",
    height: "1.6rem",
  },
  datePicker: {
    maxWidth: "100px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "bold",
  },
  submit: {
    marginLeft: "1rem",
    cursor: "pointer",
  },
}));

const ResultsRightSide = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // result table data
  const [result, setResult] = useState(null);
  // waiting for results to load up
  const [isResult, setIsResult] = useState(false);
  // date picker
  const [startDate, setStartDate] = useState();
  const [game, setGame] = useState();
  const [gameList, setGameList] = useState([]);
  const history = useHistory();

  const handleGameChange = (event) => {
    //console.log(event.target.value);
    setGame(event.target.value);
  };

  const minusMonth = (d, month) => {
    d.setMonth(d.getMonth() - month);
    return d;
  };

  useEffect(() => {
    axiosInstance
      .get("public/selectSport")
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setGameList(res.data);
          setGame(res.data[0].key);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [history]);

  //handle more
  const handleMore = (e, index) => {
    let i = 0;
    while (i < 999) {
      const more = document.getElementById(index+'_'+i);
      if (more){
        more.classList.toggle("none");
        more.classList.toggle("show");
      }
      else {
        i = 999;
      }
      i++;
    }
  };
  // handle date
  const handleDate = (e, date) => {
    setStartDate(moment(date, moment.defaultFormat).toDate());
    e.preventDefault();
  };

  useEffect(() => {
    if (game) {
      var params = {
        searchSport: game,
      };

      if (startDate) {
        params.reportDate = moment(startDate).format("D-M-YYYY");
      }

      axiosInstance
        .get("public/results", {
          params: params,
        })
        .then((res) => {
          setResult(res.data);
          setIsResult(true);
          if (!startDate) {
            setStartDate(moment(res.data.today, moment.defaultFormat).toDate());
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.status === 401) {
            logout(history);
          }
        });
    }
  }, [history, game, startDate]);
  return (
    <>
      <div className="Results">
        {isResult !== null ? (
          <>
            {/* table for result */}
            {result !== null ? (
              <>
                <form action="" onSubmit={handleDate} id="form">
                  <div className={classes.flex}>
                    <div className={classes.flex2}>
                      {/* form  */}

                      <img
                        src={require("../img/Icons/chart-21.svg")}
                        alt=""
                        className={`${classes.img} ${classes.mr5}`}
                      />
                      <p className={classes.header}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Results")}</span>
                      </p>
                      <button
                        value={moment(result.today).format("D-M-YYYY")}
                        onClick={(e) => {
                          handleDate(e, result.today);
                        }}
                        className={classes.btn}
                      >
                        <span className={`lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`}>{t("Today")}</span>
                      </button>
                      <button
                        value={moment(result.yesterday).format("D-M-YYYY")}
                        onClick={(e) => {
                          handleDate(e, result.yesterday);
                        }}
                        className={classes.btn}
                      >
                        <span className={`lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`}>{t("Yesterday")}</span>
                      </button>
                      <select
                        value={game}
                        onChange={handleGameChange}
                        className={`${classes.dd} lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`}
                      >
                        <optgroup className={`${classes.ml5} lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`}>
                        {gameList.map((g, index) => (
                          <React.Fragment key={index}>
                            <option value={g.key}>{t(g.val)}</option>
                          </React.Fragment>
                        ))}
                        </optgroup>
                      </select>
                    </div>
                    <div className={`${classes.flex2} float-right`}>
                      <DatePicker
                        placeholderText="Select date"
                        className={classes.datePicker}
                        closeOnScroll={true}
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        dateFormat="d MMM yyyy"
                        maxDate={new Date()}
                        minDate={minusMonth(new Date(), 1)}
                      />
                    </div>
                  </div>
                </form>
                <table>
                  <thead>
                    <tr>
                      <th style={{ borderRadius: "4px 0 0 0" }}>
                      <span className={`lang-${localStorage.getItem('language')}`}>{t("Date & Time")}</span>
                      </th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Event")}</span></th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("First Half Score")}</span></th>
                      <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Full Time Score")}</span></th>
                      <th style={{ borderRadius: "0 4px  0 0" }}>
                      <span className={`lang-${localStorage.getItem('language')}`}>{t("More")}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.results.map((info, index) => (
                      <React.Fragment key={index}>
                        {info.competitionName !== undefined ? (
                          <tr>
                            <td colSpan="5" className={classes.compName}>
                              {info.competitionName}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td className={classes.date}>
                            <p>
                              {moment(info.matchTime).format("D MMM h:mm a")}
                            </p>
                          </td>
                          <td>
                            <p>
                              {info.homeName} vs {info.awayName}
                            </p>
                          </td>
                          {info.ft && info.fh !== undefined ? (
                            <>
                              <td className={classes.fh}>
                                <p>
                                  {info.fh.length === 2 ? (
                                    <span>
                                      {info.fh[0]}:{info.fh[1]}
                                    </span>
                                  ) : (
                                    <span>{info.fh[0]}</span>
                                  )}
                                </p>
                              </td>
                              <td className={classes.ft}>
                                <p>
                                  {info.ft !== undefined &&
                                  info.ft.length === 2 ? (
                                    <span>
                                      {info.ft[0]}:{info.ft[1]}
                                    </span>
                                  ) : info.ft !== undefined ? (
                                    <span>{info.ft[0]}</span>
                                  ) : null}
                                </p>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className={classes.fh}>-:-</td>
                              <td className={classes.ft}>-:-</td>
                            </>
                          )}

                          <td className={classes.more}>
                            {info.more !== undefined ? (
                              <button
                                onClick={(e) => {
                                  handleMore(e, index);
                                }}
                              >
                                +
                              </button>
                            ) : null}
                          </td>
                        </tr>

                        {info.more !== undefined ? (
                          <>
                            {info.more.map((more, i) => (
                              <tr key={i} id={index+'_'+i} className="none">
                                <td />
                                <td>{more.hdpType === 'CORNER' ? 'Total Corners' : more.hdpType === 'YELLOWCARD' ? 'Total Bookings' : more.hdpType === 'YELLOWCARDS' ? 'Yellow Cards' : more.hdpType}</td>
                                {more.ft && more.fh !== undefined ? (
                                  <>
                                    <td className={classes.fh}>
                                      <p>
                                        {more.fh.length === 2 ? (
                                          <span>
                                            {more.fh[0]}:{more.fh[1]}
                                          </span>
                                        ) : (
                                          <span>{more.fh[0]}</span>
                                        )}
                                      </p>
                                    </td>
                                    <td className={classes.ft}>
                                      <p>
                                        {more.ft !== undefined &&
                                        more.ft.length === 2 ? (
                                          <span>
                                            {more.ft[0]}:{more.ft[1]}
                                          </span>
                                        ) : more.ft !== undefined ? (
                                          <span>{more.ft[0]}</span>
                                        ) : null}
                                      </p>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className={classes.fh}>-:-</td>
                                    <td className={classes.ft}>-:-</td>
                                  </>
                                )}
                                <td />
                              </tr>
                            ))}
                          </>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ResultsRightSide;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../config';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Navbar2.css';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';
import {logout} from './Utils';
// import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  upper: {
    backgroundColor: '#DC252F',
  },
  nomr: {
    marginRight: '0px !important',
  },
  lower: {
    backgroundColor: '#0E2336',
    height: '40px',
  },
  wrapper: {
    maxWidth: '1180px',
    margin: '0 auto !important',
    float: 'none !important',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '25vw',
    position: 'absolute',
    right: '-1.75rem',
  },
  links: {
    textDecoration: 'none',
    color: '#fff',
    fontSize: 'calc(11px + 0.25vw)',
    fontWeight: '500',
  },
  logout: {
    backgroundColor: 'transparent',
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '6px',
    fontSize: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoutImg: {
    marginRight: '0.25rem',
  },
}));

const Navbar2 = ({ showLogin }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('deviceType', navigator.platform);
    localStorage.removeItem('parlayQuote');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const login = document.querySelector('#login-form');
    const username = login['username'].value;
    const password = login['password'].value;
    const data = {
      username: username,
      password: password,
    };
    axiosInstance
      .post('authenticate', data)
      .then((res) => {
        localStorage.setItem('token', res.data.jwttoken);
        localStorage.removeItem('parlayQuote');
        history.push('/odds');
      })
      .catch((err) => {
        if (err.response && err.response.data.message === 'AUTH005') {
          toast.dark('Your account is locked. Please contact your agent.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (err.response && err.response.status === 401) {
          toast.dark('Invalid credentials !', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          logout(history);
          
        }
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <nav className="Navbar2">
      <ToastContainer />

      <div className={classes.upper}>
        <div className={classes.wrapper}>
          {/* absolutely positioned logo */}
          <img
            src={require('../img/Icons/logo2.png')}
            alt=""
            className="logo"
          />
          <div className="navbar2-upper">
            {/* login Form */}
            {showLogin && showLogin === 'yes' ? (
              <form onSubmit={handleSubmit} id="login-form">
                <input type="text" placeholder="Username" id="username" />
                <input type="password" placeholder="Password" id="password" />
                <input type="submit" value="Sign In" placeholder="Sign In" />
              </form>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.lower}>
        <div className={classes.wrapper}>
          <div className="menu-wrapper">
            <nav id="primary_nav_wrap">
              <ul>
                <li
                  className={window.location.pathname === '/' ? 'active' : ''}
                >
                  <a href="/">
                    <span
                      className={`lang-${localStorage.getItem(
                        'language'
                      )} lang-${localStorage.getItem('language')}-fs-10`}
                    >
                      {t('Home_')}
                    </span>
                  </a>
                </li>
                <li
                  className={
                    window.location.pathname === '/oddspage' ? 'active' : ''
                  }
                >
                  <a href="/oddspage">
                    <span
                      className={`lang-${localStorage.getItem(
                        'language'
                      )} lang-${localStorage.getItem('language')}-fs-10`}
                    >
                      {t('Odds')}
                    </span>
                  </a>
                </li>
                <li
                  className={
                    window.location.pathname === '/resultspage' ? 'active' : ''
                  }
                >
                  <a href="/resultspage">
                    <span
                      className={`lang-${localStorage.getItem(
                        'language'
                      )} lang-${localStorage.getItem('language')}-fs-10`}
                    >
                      {t('Results')}
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          {/* navBtn area */}
          <SelectLanguage style={{ marginRight: 8 }} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar2;

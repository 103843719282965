import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import '../styles/Navbar.css';
import axiosInstance from '../config';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';
import { logout } from './Utils';

const useStyles = makeStyles((theme) => ({
  upper: {
    backgroundColor: '#DC252F',
  },
  nomr: {
    marginRight: '0px !important',
  },
  lower: {
    backgroundColor: '#0E2336',
    height: '50px',
  },
  wrapper: {
    maxWidth: '1180px',
    margin: '0 auto !important',
    float: 'none !important',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    right: '-1.75rem',
  },
  navBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    margin: '0 0.25rem',
    padding: '0.25rem 0.5rem',
    color: 'rgba(255,255,255,0.7)',
    backgroundColor: 'transparent',
    border: '1px solid rgba(255,255,255,0.7)',
  },
  activeBtn: {
    backgroundColor: 'rgba(255,255,255,0.4)',
    color: '#fff',
    margin: '0 0.25rem',
  },
  yellow: {
    backgroundColor: '#FAB81D',
  },
  blue: {
    backgroundColor: '#2A10FF',
  },
  purple: {
    backgroundColor: '#DD01E9',
  },
  pl5: {
    paddingLeft: '0.25rem',
  },
}));

const Navbar = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const handleLogout = () => {
    axiosInstance
      .post('token/invalidate')
      .then((res) => {
        var lang = localStorage.getItem('language') || 'en';
        localStorage.clear();
        localStorage.setItem('language', lang);
      })
      .catch((err) => console.log(err));
  };

  const getSelectedMenu = (group) => {
    var menuStorage = localStorage.getItem('menu');
    if (menuStorage) {
      return JSON.parse(menuStorage)[group];
    } else {
      return '';
    }
  };

  const getMenu = () => {
    var menuStorage = localStorage.getItem('menu');
    if (!menuStorage) {
      var defaultMenu = {
        Game: 'Football',
        Parlay: 'Single Bet',
        Odds: 'Today',
      };
      localStorage.setItem('menu', JSON.stringify(defaultMenu));
      return defaultMenu;
    }
    return JSON.parse(menuStorage);
  };

  const [navMenu, setNavMenu] = useState(getMenu());
  const [navState, setNavState] = useState('hide');

  const setMenu = (group, menu, link) => {
    if (group === 'Game' && getSelectedMenu('Game') !== menu) {
      localStorage.removeItem('parlayQuote');
      if (getSelectedMenu('Parlay') === 'Single Bet') {
        link = '/odds';
      } else {
        link = '/parley';
      }
    }

    if (group === 'Parlay' && getSelectedMenu('Parlay') !== menu) {
      localStorage.removeItem('parlayQuote');
    }

    var menuStorage = JSON.parse(localStorage.getItem('menu'));
    menuStorage[group] = menu;
    localStorage.setItem('menu', JSON.stringify(menuStorage));

    if (link) {
      if (link.startsWith('#')) {
        var urlpath = window.location.pathname.split('/');
        urlpath = urlpath && urlpath.length > 1 && urlpath[1];
        if (urlpath === 'odds' || urlpath === 'parley') {
          window.location = (window.location + '').split('#')[0] + link;
        } else {
          if (getSelectedMenu('Parlay') === 'Single Bet') {
            window.location = '/odds';
          } else {
            window.location = '/parley';
          }
        }
      } else {
        window.location = link;
      }
    }
  };

  return (
    <nav className="Navbar">
      <div className={classes.upper}>
        <div className={classes.wrapper}>
          {/* absolutely positioned logo */}
          <img
            onClick={() => {
              history.push('/odds');
            }}
            src={require('../img/Icons/logo2.png')}
            alt=""
            className="logo"
          />
          <ul className="navbar-upper">
            <li>
              <Link to="/results" className={classes.yellow}>
                <img src={require('../img/Icons/chart-21.svg')} alt="" />
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('Result')}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/wager" className={classes.blue}>
                <img src={require('../img/Icons/coin-9.svg')} alt="" />
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('Wager')}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/history" className={classes.purple}>
                <img src={require('../img/Icons/time-17.svg')} alt="" />
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('History')}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/cash" className={classes.yellow}>
                <img src={require('../img/Icons/banknote-18.svg')} alt="" />{' '}
                <span
                  className={`${classes.pl5} lang-${localStorage.getItem(
                    'language'
                  )} lang-${localStorage.getItem('language')}-fs-12`}
                >
                  {t('Cash')}
                </span>
              </Link>
            </li>
            {/* <li>
              <Link to="/withdraw" className={classes.blue}>
                <img src={require('../img/Icons/banknote-15.svg')} alt="" />
                <span className={`${classes.pl5} lang-${localStorage.getItem('language')} lang-${localStorage.getItem('language')}-fs-12`}>{t('Withdraw')}</span>
              </Link>
            </li> */}
          </ul>
          <div className={classes.helper}>
            <ul className="navbar-upper">
            {/* <li>
                <Link to="/slots" className={classes.blue}>
                  <img src={require('../img/Icons/key-1.svg')} alt="" />
                  <span
                    className={`${classes.pl5} lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-12`}
                  >
                    {t('Slots')}
                  </span>
                </Link>
              </li> */}
              <li>
                <Link to="/password" className={classes.purple}>
                  <img src={require('../img/Icons/key-1.svg')} alt="" />
                  <span
                    className={`${classes.pl5} lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-12`}
                  >
                    {t('Password')}
                  </span>
                </Link>
              </li>
              <li className={classes.nomr}>
                <Link to="/" onClick={handleLogout}>
                  <img src={require('../img/Icons/log-out-3.svg')} alt="" />
                  <span
                    className={`${classes.pl5} lang-${localStorage.getItem(
                      'language'
                    )} lang-${localStorage.getItem('language')}-fs-12`}
                  >
                    {t('Logout')}
                  </span>
                </Link>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      {/* bottom blueish area */}
      <div className={classes.lower}>
        <div className={classes.wrapper}>
          <div className="menu-wrapper">
            <ul className="firstMenu">
              <li
                className={`${
                  ((getSelectedMenu('Game') === 'Football' &&
                    getSelectedMenu('Odds') === 'Today') ||
                    (getSelectedMenu('Game') === 'Football' &&
                      getSelectedMenu('Odds') === 'Live')) &&
                  getSelectedMenu('Menu1') !== 'Early' &&
                  (window.location.pathname === '/odds' ||
                    window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Game', 'Football', '#Football');
                    //setMenu('Parlay', 'Single Bet', '/odds');
                    setMenu('Odds', 'Today', '#Today');
                    setMenu('Menu1', 'Today', null);
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Football (Today)')}
                  </span>
                </a>
              </li>

              <li
                className={`${
                  getSelectedMenu('Game') === 'Football' &&
                  getSelectedMenu('Menu1') === 'Early' &&
                  (window.location.pathname === '/odds' ||
                    window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Menu1', 'Early', null);
                    //setMenu('Parlay', 'Single Bet', '/odds');
                    setMenu('Game', 'Football', '#Football');
                    setMenu('Odds', 'Early', '#Early');
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Football (Early)')}
                  </span>
                </a>
              </li>

              <li
                className={`${
                  ((getSelectedMenu('Game') === 'Basketball' &&
                    getSelectedMenu('Odds') === 'Today') ||
                    (getSelectedMenu('Game') === 'Basketball' &&
                      getSelectedMenu('Odds') === 'Live')) &&
                  getSelectedMenu('Menu1') !== 'Early' &&
                  (window.location.pathname === '/odds' ||
                    window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Menu1', 'Today', null);
                    //setMenu('Parlay', 'Single Bet', '/odds');
                    setMenu('Game', 'Basketball', '#Basketball');
                    setMenu('Odds', 'Today', '#Today');
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Basketball (Today)')}
                  </span>
                </a>
              </li>

              <li
                className={`${
                  getSelectedMenu('Game') === 'Basketball' &&
                  getSelectedMenu('Menu1') === 'Early' &&
                  (window.location.pathname === '/odds' ||
                    window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Menu1', 'Early', null);
                    setMenu('Game', 'Basketball', '#Basketball');
                    setMenu('Odds', 'Early', '#Early');
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Basketball (Early)')}
                  </span>
                </a>
              </li>

              
              <li
                className={`${
                  getSelectedMenu('Game') === 'Virtual Sport' &&
                  (window.location.pathname === '/odds' || window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Menu1', 'Today', null);
                    setMenu('Game', 'Virtual Sport', '#VirtualSport');
                    setMenu('Odds', 'Early', '#Early');
                    setMenu('Parlay', 'Single Bet', '/parley');
                    setMenu('Odds', 'Today', '/odds');
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Virtual Sport ')}<sup><font color="white">New!</font></sup>
                  </span>
                </a>
              </li>
              
            </ul>

            <ul className="secondMenu">
              <li
                className={`${
                  getSelectedMenu('Parlay') === 'Single Bet' &&
                  getSelectedMenu('Odds') !== 'Live' &&
                  (window.location.pathname === '/odds' ||
                    window.location.pathname === '/parley')
                    ? 'active'
                    : ''
                }`}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setMenu('Parlay', 'Single Bet', '/odds');
                    setMenu('Odds', 'Today', '/odds');
                  }}
                  href="/odds"
                >
                  <span className={`lang-${localStorage.getItem('language')}`}>
                    {t('Handicap')}
                  </span>
                </a>
              </li>
              {getSelectedMenu('Game') !== 'Virtual Sport' && (
                <>
                  <li
                    className={`${
                      getSelectedMenu('Parlay') === 'Parlay' &&
                      getSelectedMenu('Odds') !== 'Live' &&
                      (window.location.pathname === '/odds' ||
                        window.location.pathname === '/parley')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu('Parlay', 'Parlay', '/parley');
                        setMenu(
                          'Odds',
                          getSelectedMenu('Menu1')
                            ? getSelectedMenu('Menu1')
                            : 'Today',
                          '/parley'
                        );
                      }}
                      href="/parley"
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Parlay')}
                      </span>
                    </a>
                  </li>

                  <li
                    className={`${
                      getSelectedMenu('Odds') === 'Live' &&
                      (window.location.pathname === '/odds' ||
                        window.location.pathname === '/parley')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu('Odds', 'Live', '#Live');
                      }}
                      href="/parley"
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Live')}
                      </span>
                    </a>
                  </li>
                </>
              )}

              {getSelectedMenu('Game') === 'Virtual Sport' && (
                <>
                  <li
                    className={`${
                      getSelectedMenu('Parlay') === 'Parlay' &&
                      getSelectedMenu('Odds') !== 'Live' &&
                      (window.location.pathname === '/odds' ||
                        window.location.pathname === '/parley')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu('Parlay', 'Parlay', '/parley');
                        setMenu(
                          'Odds',
                          getSelectedMenu('Menu1')
                            ? getSelectedMenu('Menu1')
                            : 'Today',
                          '/parley'
                        );
                      }}
                      href="/parley"
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Parlay')}
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      getSelectedMenu('Odds') === 'Live' &&
                      (window.location.pathname === '/odds' ||
                        window.location.pathname === '/parley')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setMenu('Odds', 'Live', '#Live');
                        setMenu('Parlay', 'Single Bet', '/odds');
                      }}
                      href="/parley"
                    >
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Live')}
                      </span>
                    </a>
                  </li>
                </>
              )}

              {getSelectedMenu('Game') === 'Football' ? (
                <>
                  <li
                    className={`${
                      window.location.pathname === '/oe-tg' ? 'active' : ''
                    }`}
                  >
                    <a href="/oe-tg">
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('OE/TG')}
                      </span>
                    </a>
                  </li>
                  <li
                    className={`${
                      window.location.pathname === '/correctscore'
                        ? 'active'
                        : ''
                    }`}
                  >
                    <a href="/correctscore">
                      <span
                        className={`lang-${localStorage.getItem('language')}`}
                      >
                        {t('Correct score')}
                      </span>
                    </a>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
          <SelectLanguage style={{ marginRight: 24 }} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import "../styles/ErrorToast.css";

const ErrorToast = ({ message, onAction }) => {

  return (
    <div className="modal-popup">
      <div className="center">
        <img className="img" src={require('../img/err.png')}/>
      </div>
      <div className="popup-message">
        {message}.
      </div>
      <button className="popup-button" onClick={onAction}>OK</button>
    </div>
  );
};

export default ErrorToast;

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/History.css";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
    maxHeight: "18px",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "13px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: '18px',
    width: '18px',
    marginRight: '0.5rem',
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  moneyNeg: {
    fontSize: "13px",
    color: "#E91F1F",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
    float: "right",
  },
  table: {
    width: "100%",
  },
}));

const Transaction = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  // history
  const [transaction, setTransaction] = useState(null);
  // set date
  // const [date, setDate] = useState(null);
  // ref for printing
  const printRef = useRef();
  const history = useHistory();

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    axiosInstance
      .get("portal/cash")
      .then((res) => {
        setTransaction(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [history]);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="History">
              {transaction !== null ? (
                <>
                  <div className={classes.flex}>
                    <div className={classes.flex2}>
                      <img
                        src={require("../img/Icons/banknote-18.svg")}
                        alt=""
                        className={classes.img}
                      />
                      <span className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Cash")}</span></span>
                    </div>
                    <div className={classes.flex}>
                      <Link
                        to="#"
                        className={classes.handiCap}
                        onClick={handlePrint}
                      >
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Print")}</span>
                      </Link>
                    </div>
                  </div>
                  <table ref={printRef} className={classes.table}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: "4px 0 0 0" }} width="20%">
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Date")}</span>
                        </th>
                        <th width="20%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Count")}</span></th>
                        <th width="20%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Beginning")}</span></th>
                        <th width="20%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Amount")}</span></th>
                        {/* <th width="15%"><span className={`lang-${localStorage.getItem('language')}`}>{t("Debit")}</span></th> */}
                        <th style={{ borderRadius: " 0 4px 0 0" }} width="20%">
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("End Balance")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transaction.content.map((history, index) => (
                        <tr key={index} className={classes.content}>
                          <td align="center">
                            <Link
                              to={`/cash/${history.date}`}
                              style={{
                                color: "#323030",
                              }}
                            >
                              {history.date.replace("-", "/").replace("-", "/")}
                            </Link>
                          </td>
                          <td className={classes.money}>
                            <p>{history.ct}</p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {history.beginning != null ? (
                                <CurrencyFormat
                                  value={history.beginning}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : null}
                            </p>
                          </td>
                          <td
                            align="right"
                            className={
                              history.amount < 0
                                ? classes.moneyNeg
                                : classes.money
                            }
                          >
                            <p>
                              {" "}
                              <CurrencyFormat
                                value={history.amount}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          {/* <td align="right" className={classes.moneyNeg}>
                            <p>
                              {" "}
                              <CurrencyFormat
                                value={history.debit}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td> */}
                          <td className={classes.money}>
                            <p>
                              {history.end != null ? (
                                <CurrencyFormat
                                  value={history.end}
                                  decimalScale={2}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              ) : null}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Transaction;

import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Navbar3 from "../components/Navbar3";
import axiosInstance from "../config";
import "../styles/Pages.css";

const useStyles = makeStyles((theme) => ({
  style1: {
    width: "100%",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "12px",
    fontWeight: "none",
    color: "#333333",
  },
  agreebutton: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    backgroundColor: "rgb(78, 173, 38)",
    "&:hover": {
      background: "rgb(78, 173, 38, 0.6)",
    },
  },
  disagreebutton: {
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "6px",
    backgroundColor: "rgb(237, 146, 151)",
    "&:hover": {
      background: "rgb(237, 146, 151, 0.6)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  center: {
    textAlign: "center",
  },
  para: {
    padding: "1px 0px",
  },
  mtterms: {
    marginTop: "10px",
  },
  paper: {
    padding: theme.spacing(9),
    textAlign: "center",
    backgroundColor: "#C4C4C4",
    color: theme.palette.text.secondary,
  },
  cardheader: {
    textAlign: "center",
    backgroundColor: "#86898C",
    padding: "10px",
  },
  cardtitle: {
    fontSize: "13px",
    margin: 0,
    color: "#fff",
    fontWeight: "bold",
  },
  card: {
    marginBottom: "15px",
    boxShadow: "0px 2px 14px 0px rgba(0, 0, 0, 0.08) !important",
  },
  tabContent: {
    padding: "15px",
  },
  MainArea: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "110px auto auto !important",
    float: "none !important",
  },
  wrapper: {
    display: "flex",
    margin: "1rem 0 0.5rem 0",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: "flex",

    alignItems: "center",
  },
  img: {
    height: "14px",
    width: "14px",
    margin: "0 0.35rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "25%",
  },
  RightPanel: {
    width: "75%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  table: {
    width: "100%",
  },
}));

const Terms = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();

  const disagree = () => {
    axiosInstance
      .post("token/invalidate")
      .then((res) => {
        var lang = localStorage.getItem('language') || 'en';
        localStorage.clear();
        localStorage.setItem('language',lang);
        history.push("/");
      })
      .catch((err) => console.log(err));
  };

  const agree = () => {
    history.push("/odds");
  };

  const terms = `
To Dear Members

After placing a bet, please wait for the "Bet Successful" alert to pop up before proceeding to next bet.

In order to avoid unnecessary disputes, please verify your bet in your "WAGER CONDITION". If there are any incorrect information, please contact your agent immediately. If there is a dispute please inform us before the game kicks off to allow us time for investigation.

The Company reserves the right to amend and proceed the result based on the actual market for any mistake on odds which caused by typo or human errors or technical problems.

Apart from the 'Running Ball' bets, other bets will be considered forfeited after matches started

In the event of unforeseen circumstances, (e.g.: attack by hackers) causing technical problems like network failure, data missing or corruption, The Company will take the last backed up data as the correct sets of bets accepted. We will only accept complaints with proof of details (hard or soft copy). To protect our member's interest, we strongly recommend our members to print out betting statement after each bet.

Danger Ball
----------------

The Company has introduced Danger Ball function in order to allow members to bet during running ball.
If the Company defines the current situation as Danger Ball, all the following stakes placed by members will be under pending mode. Please refer to the definition of Danger Ball:

What is Danger Ball:
---------------------------

1. Corner Kick

2. Penalty Kick

3. Free Kick in opposing team near penalty box or vice versa

4. Throw in opposing team near penalty box or vice versa

5. Team A attacking Team B, near penalty box or vice versa

Under the condition/s specified below, all stakes placed under the Danger Ball will be cancelled and the stakes will be returned to your account.

1. Red card

2. Goal scored

Under the condition/s specified below, Danger Ball will be released, all stakes would be confirmed at the odds and handicap given earlier during that time under Danger Ball condition.

1. Goal kick

2. Ball has been transferred into middle of the field

3. Player is controlling the ball at its own area

4. Free kick from the team at its own area

----------------------------------------------------------

1. The Management came to discover some member wagered by devious ways, the contents of wagers were abnormal and transferred several wagers with the same contents. We reserve the rights to cancel these tickets.

2. We reserve the right to void any transactions where individuals are suspected of acting in conspiracy or concert; for example organizations, clubs or employees/agents of other bookmakers.

3. We reserve the right to refuse the whole or part of any bet for any reason.

4. We reserve the right to change, alter or add any of the published rules at any time without prior notification;we will endeavour to post the notice on our news ticker.
`;

  return (
    <>
      <Navbar3 />
      <div className={classes.MainArea}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className={`${classes.card} ${classes.mtterms}`}>
              <CardHeader
                className={`${classes.cardheader}`}
                title={
                  <Typography
                    className={classes.cardtitle}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    <span className={`lang-${localStorage.getItem('language')}`}>{t(
                      "A28i International Online Betting Rules and Regulation"
                    )}</span>
                  </Typography>
                }
                titleTypographyProps={{ variant: "h6" }}
              />
              <CardContent>
                <div>
                  <textarea
                    className={`lang-${localStorage.getItem('language')} ${classes.style1}`}
                    rows="30"
                    defaultValue={terms}
                    readOnly={true}
                  />
                  <br />
                  {t("From A28i management")} <br />
                  <br />
                  <b>{t("From A28i managemen desc")}</b>
                  <br />
                  <br />
                  <div className={classes.center}>
                    <Button
                      onClick={() => agree()}
                      size="small"
                      className={`${classes.margin} ${classes.agreebutton}`}
                      disableElevation
                      variant="contained"
                      color="primary"
                    >
                      {t("Agree")}
                    </Button>
                    <Button
                      onClick={() => disagree()}
                      size="small"
                      className={`${classes.margin} ${classes.disagreebutton}`}
                      disableElevation
                      variant="contained"
                      color="secondary"
                    >
                      {t("Disagree")}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Terms;

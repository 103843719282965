import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance2 from "../config2";

const useStyles = makeStyles((theme) => ({
  img: {
    height: "100vh",
    width: "100vw",
    zIndex: "-1",
    position: "absolute",
    top: "0",
    left: "0",
    opacity: "0.9",
  },
  message: {
    color: '#fff',
    padding: '10px'
  },
  line: {
    margin: '10px 30px',
    border:'1px solid #fff',
    borderRadius: '50%',
    color: '#fff'
  },
  warning: {
    marginTop: "20px",
    width: "30px",
  },
  heading: {
    zIndex: "1",
    color: "#fff",
    fontWeight: "400",
    fontFamily: "Lato",
    fontSize: "24px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  subHeading: {
    zIndex: "1",
    color: "#fff",
    fontWeight: "400",
    fontFamily: "Lato",
    fontSize: "24px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  arrange: {
    textAlign: "center",
    backgroundColor: "#DC252F",
    width: "600px",
    borderRadius: "8px", 
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12%",
  },
}));

const NotFound = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [message, setMessage] = useState('');


  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.arrange}>
        <img
          src={require("../img/warning.svg")}
          alt=""
          className={classes.warning}
        />
          <h1 className={classes.heading}>
          <span className={`lang-${localStorage.getItem('language')}`}>{t("PAGE NOT FOUND")}</span>
          </h1>
          <hr className={classes.line} />
          {
            message && message.split('<<BR>>').map((m, index) => (
              <div className={classes.message} key={index}>{m}</div>
            ))
          }
          <img
            src={require("../img/maintenance.jpg")}
            alt=""
            className={classes.img}
          />
        </div>
      
      </div>
    </>
  );
};

export default NotFound;

import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  ml5: {
    marginLeft: '5px',
  },
  hk: {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bold',
    height: '20px',
    background: '#0E2435',
    color: '#FFFFFF',
  },
}));
const SelectLanguage = (props) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [lang, setlang] = useState(localStorage.getItem('language', 'en'));
  const handleLangChange = (event) => {
    localStorage.setItem('language', event.target.value);
    if (event.target.value === 'kh') {
      let elements = document.querySelectorAll('*');
      elements.forEach((element) => {
        element.style.fontFamily = 'Hanuman, sans-serif';
      });
    } else {
      let elements = document.querySelectorAll('*');
      elements.forEach((element) => {
        element.style.fontFamily = 'Lato, sans-serif';
      });
    }
    setlang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div className={classes.container} style={props.style}>
      <select
        value={lang || 'en'}
        onChange={handleLangChange}
        className={`${classes.hk} ${classes.ml5}`}
      >
        <option value="en">English</option>
        <option value="cn">中文(简体)</option>
        <option value="kh">កម្ពុជា</option>
        <option value="th">ประเทศไทย</option>
        <option value="vn">Việt Nam</option>
      </select>
    </div>
    // <></>
  );
};

export default SelectLanguage;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { publicAxiosInstance } from '../config';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../styles/Pages.css';
import { useTranslation } from 'react-i18next';
import Help from '../pdf/A2888-HELP_FAQ-17122020.pdf';
import Rules from '../pdf/A2888-rules-and-regulation-17122020.pdf';
import Tutorial from '../pdf/A2888-Tutorial.pdf';
import Guide from '../pdf/A28i-Euro2020-guide.pdf';

const useStyles = makeStyles((theme) => ({
  pt0: {
    paddingTop: '0px !important',
  },
  pointer: {
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(9),
    textAlign: 'center',
    backgroundColor: '#C4C4C4',
    color: theme.palette.text.secondary,
  },
  cardheader: {
    textAlign: 'center',
    backgroundColor: '#FFF',
    padding: '12px 16px 0px 16px',
  },
  cardtitle: {
    fontSize: '16px',
    margin: 0,
    color: '#86898C',
    fontWeight: 'bold',
  },
  card: {
    marginBottom: '16px',
    boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.03) !important',
  },
  tabContent: {
    padding: '16px',
    borderRadius: '0px 0px 4px 4px',
  },
  MainArea: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '86px auto auto !important',
    float: 'none !important',
  },
  MainArea2: {
    width: '80%',
    display: 'grid',
    placeItems: 'center',
    maxWidth: '1180px',
    margin: '90px auto auto !important',
    float: 'none !important',
  },
  LeftPanel: {
    width: '25%',
  },
  RightPanel: {
    width: '25%',
  },
  CenterPanel: {
    width: '50%',
  },
  links: {
    textDecoration: 'none',
    color: 'black',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& img': {
      maxWidth: '30px',
      maxHeight: '30px',
    },
    marginBottom: '10px',
  },
  inputStyle: {
    margin: '0.5rem 0',
    width: '100%',
    border: '1px solid #86898C',
    borderRadius: '4px',
    height: '36px',
    '&::placeholder ': {
      color: '#C4C4C4',
      fontWeight: 100,
    },
    fontSize: '12px',
    padding: '4px',
  },
  submitStyle: {
    margin: '1.25rem 0',
    backgroundColor: '#32A352',
    width: '100%',
    color: '#fff',
    borderRadius: '8px',
    border: 'none',
    height: '36px',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  form: {
    borderBottom: '1px solid #86898C',
  },
  header: {
    fontSize: '16px',
    margin: '1.25rem 0',
    color: '#86898C',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper className={classes.tabContent}>{children}</Paper>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PublicLeftSide = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState([]);
  const [sports, setSports] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    //DESKTOP_PUBLIC_CAROUSEL -  DESKTOP_LEFT
    publicAxiosInstance
      .get('public/cms?mediaType=DESKTOP_LEFT')
      .then((res) => {
        if (res.data && res.data.content && res.data.content.length > 0) {
          setBanner(res.data.content[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let isSubscribed = true;
    publicAxiosInstance
      .get('public/oddHdp/status')
      .then((res) => {
        if (isSubscribed) {
          setStatus(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        //setLoading(false);
      });

    publicAxiosInstance
      .get('public/oddHdp/sports')
      .then((res) => {
        if (isSubscribed) {
          setSports(res.data);
        }
        //setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => (isSubscribed = false);
  }, []);

  return (
    <>
      <div className="tab-section">
        <Paper className={classes.root}>
          <Tabs
            value={value}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label={t('Sports')} id="simple-tab-0" />
            <Tab label={t('Market')} id="simple-tab-1" />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          {/* <ul className="list">
            {sports &&
              sports.map((sport, index) => (
                <li key={index}>
                  {sport.search}
                  <span className="count">{sport.count}</span>
                </li>
              ))}
            <li>
              Virtual Sport
              <span className="count">0</span>
            </li>
          </ul> */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ul className="list">
            {status &&
              status.map((s, index) => (
                <li key={index}>
                  {s.search}
                  <span className="count">{s.count}</span>
                </li>
              ))}
          </ul>
        </TabPanel>
      </div>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardheader}
          title={
            <Typography
              className={classes.cardtitle}
              gutterBottom
              variant="h5"
              component="h2"
            >
              <span className={`lang-${localStorage.getItem('language')}`}>
                {t('Services')}
              </span>
            </Typography>
          }
        />
        <CardContent>
          <ul className="list">
            <li>
              <a href={Rules} rel="noopener noreferrer" target="_blank">
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Betting Rules and Regulations')}
                </span>
              </a>
            </li>
            <li>
              <a href={Help} rel="noopener noreferrer" target="_blank">
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Help')}
                </span>
              </a>
            </li>
            <li>
              <a href={Tutorial} rel="noopener noreferrer" target="_blank">
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Tutorials')}
                </span>
              </a>
            </li>
            <li>
              <a href="mailto:support@a28i.com">
                <span className={`lang-${localStorage.getItem('language')}`}>
                  {t('Contact Us')}
                </span>
              </a>
            </li>
          </ul>
        </CardContent>
      </Card>
      <div className={classes.card}>
        {banner ? (
          <img
            className={`centerImage ${
              banner.hyperlinkUrl ? classes.pointer : ''
            }`}
            width="100%"
            alt={banner.name}
            src={banner.pictureUrl}
            onClick={() => {
              if (banner.hyperlinkUrl) {
                window.open(banner.hyperlinkUrl, '_blank');
              }
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default PublicLeftSide;

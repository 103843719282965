import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/History.css";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
    maxHeight: "18px",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    height: "18px",
    width: "18px",
    marginRight: "0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  table: {
    width: "100%",
  },
  negative: {
    color: "#E91F1F",
  },
}));

const LegacyHistory = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  // history
  const [history, setHistory] = useState(null);
  // set date
  // const [date, setDate] = useState(null);
  // ref for printing
  const printRef = useRef();
  // history replacement
  const navigate = useHistory();

  // handle print
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    axiosInstance
      .get("portal/legacy/history")
      .then((res) => {
        setHistory(res.data);
        //console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [navigate]);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="History">
              {history !== null ? (
                <>
                  <div className={classes.wrapper}>
                    <div className={classes.flex}>
                      <img
                        src={require("../img/Icons/time-17.svg")}
                        alt=""
                        className={classes.img}
                      />
                      <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Legacy History")}</span></p>
                      <Link
                        to="#"
                        className={classes.handiCap}
                        onClick={handlePrint}
                      >
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Print")}</span>
                      </Link>
                    </div>
                    <div>
                      <button
                        className={classes.legacyBtn}
                        onClick={() => {
                          navigate.goBack();
                        }}
                      >
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("History")}</span>
                      </button>
                    </div>
                  </div>
                  <table ref={printRef} className={classes.table}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: "4px 0 0 0" }}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Date")}</span>
                        </th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Stake")}</span></th>
                        <th><span className={`lang-${localStorage.getItem('language')}`}>{t("Amount")}</span></th>
                        <th style={{ borderRadius: " 0 4px 0 0" }}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Winning")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.content.map((history, index) => (
                        <tr key={index} className={classes.content}>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Link
                              to={`/legacy/history/${history.date}`}
                              style={{
                                color: "#323030",
                              }}
                            >
                              {history.date.replace("-", "/").replace("-", "/")}
                            </Link>
                          </td>
                          <td className={classes.money}>
                            <p>{history.ct}</p>
                          </td>
                          <td className={classes.money}>
                            <p>
                              {" "}
                              <CurrencyFormat
                                value={history.amount}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              className={`${classes.money} ${
                                history.winning < 0 ? classes.negative : null
                              }`}
                            >
                              {" "}
                              <CurrencyFormat
                                value={history.winning}
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LegacyHistory;

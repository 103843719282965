import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LeftSide from "../components/LeftSide";
import Navbar from "../components/Navbar";
import TickerNews from "../components/TickerNews";
import axiosInstance from "../config";
import {logout} from '../components/Utils';
import "../styles/Messages.css";

const useStyles = makeStyles((theme) => ({
  MainArea: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "86px auto auto !important",
    float: "none !important",
  },
  MainArea2: {
    width: "80%",
    display: "grid",
    placeItems: "center",
    maxWidth: "1180px",
    margin: "10px auto auto !important",
    float: "none !important",
  },
  button: {
    margin: "0 0.5rem",
  },
  content: {
    color: "#323030",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    maxHeight: '18px',
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  img: {
    height: "18px",
    width: "18px",
    marginRight: "0.5rem",
  },
  header: {
    color: "#18334C",
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0",
  },
  LeftPanel: {
    width: "20%",
  },
  RightPanel: {
    width: "80%",
    marginTop: "10px"
  },
  money: {
    fontSize: "13px",
    color: "#1452CC",
    textAlign: "right",
    fontWeight: "bold",
  },
  handiCap: {
    color: "#1452CC ",
    fontSize: "13px",
    fontWeight: "bold",
    margin: "0.15rem 0.35rem !important",
  },
  table: {
    width: "100%",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.3rem 0",
  },
}));

const Messages = ({ User }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //   single quote
  const [quote, setQuote] = useState(null);
  // messages
  const [messages, setMessages] = useState(null);
  const history = useHistory();
  const [totalPage, setTotalPage] = useState(null);

  const handleChange = (event, value) => {
    //console.log(value);
    axiosInstance
      .get("portal/messages", {
        params: {
          pageSize: 10,
          pageNo: value - 1,
        },
      })
      .then((res) => {
        //console.log(res);
        setMessages(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          logout(history);
        }
      });
  };

  useEffect(() => {
    axiosInstance
      .get("portal/messages", {
        params: {
          pageSize: 10,
        },
      })
      .then((res) => {
        //console.log(res);
        setMessages(res.data.content);
        setTotalPage(res.data.totalPages);
        //console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.status === 401) {
          logout(history);
        }
      });
  }, [history]);
  return (
    <>
      <Navbar User={User.user} />
      <div className={classes.MainArea}>
        <TickerNews />
      </div>
      <div className={classes.MainArea2}>
        <Grid container spacing={2}>
          <Grid item className={classes.LeftPanel}>
            <LeftSide User={User} quote={quote} setQuote={setQuote} />
          </Grid>
          <Grid item className={classes.RightPanel}>
            <div className="Messages">
              <div className={classes.flex}>
                <div className={classes.flex2}>
                  <img
                    src={require("../img/Icons/messages.svg")}
                    alt=""
                    className={classes.img}
                  />
                  <p className={classes.header}><span className={`lang-${localStorage.getItem('language')}`}>{t("Messages")}</span></p>
                </div>
              </div>
              {messages !== null ? (
                <>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th style={{ borderRadius: "4px 0 0 0" }}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("No.")}</span>
                        </th>
                        <th width="100px"><span className={`lang-${localStorage.getItem('language')}`}>{t("Date")}</span> </th>
                        <th style={{ borderRadius: " 0 4px 0 0" }}>
                        <span className={`lang-${localStorage.getItem('language')}`}>{t("Content")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map((info, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ minWidth: "75px" }}>
                            <p>
                              {moment(info.startDate).format("D MMM h:mm a")}
                            </p>
                          </td>
                          <td>{info.content}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    count={totalPage}
                    onChange={handleChange}
                    className={classes.pagination}
                  />
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Messages;


const logout = (history) => {
  localStorage.removeItem('token');
  localStorage.removeItem('parlayQuote');
  
  if(history) {
    history.push('/');
  } else {
    window.location.href = '/';
  }
}

export { 
  logout 
}